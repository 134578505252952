export const getInitialPagination = <T extends { limit?: number }>(
  state: Nullable<T>
): Nullable<Cursor> => {
  if (state?.limit) {
    return {
      skip: 0,
      limit: state.limit,
    };
  }

  return null;
};
