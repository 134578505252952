import React from 'react';
import { components } from 'react-select';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import useStyles from './style';

const MultiValueRemove: typeof components.MultiValueRemove = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <div {...rest.innerProps} className={classes.multiValueRemove}>
      <Icon icon={IconmoonFont['closeCircleFilled-16']} />
    </div>
  );
};

export default MultiValueRemove;
