import React, { FC } from 'react';
import classNames from 'classnames';

import useStyles from './style';

interface SectionProps {
  content: SectionContent;
  isSelected: boolean;
  isDragging: boolean;
  onClick: () => void;
  classes?: SectionClasses;
}

const Section: FC<SectionProps> = ({
  content,
  isSelected,
  isDragging,
  onClick,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        'section',
        classes.section,
        {
          [classes.isDragging]: !isSelected && isDragging,
          [`selected ${classes.selected} ${props.classes?.selected ?? ''}`]: isSelected,
          [classNames(props.classes?.unselected)]: !isSelected,
        },
        props.classes?.root
      )}
      onClick={onClick}
    >
      <div className={classNames('sectionTop', classes.sectionTop, props.classes?.top)}>
        {content.top}
      </div>
      {!!content.middle ? (
        <div className={classNames('sectionMiddle', classes.middle, props.classes?.middle)}>
          {content.middle}
        </div>
      ) : null}
      {!!content.bottom ? (
        <div className={classNames('sectionBottom', classes.bottom, props.classes?.bottom)}>
          {content.bottom}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default Section;
