import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CreateButton from '../CreateButton';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { ButtonProps } from 'vibo-ui/Button';

const JoinEventButton: FC<ButtonProps> = ({ className }) => {
  const { t } = useTranslation();

  const { openModal } = useModal();

  const openJoinEventModal = useCallback(
    () =>
      openModal({
        key: Modals.joinEvent,
      }),
    []
  );

  return <CreateButton onClick={openJoinEventModal} text={t('joinEvent')} className={className} />;
};

export default JoinEventButton;
