import React, { FC } from 'react';

import VirtualizedList from 'components/common/VirtualizedList';

import { DEFAULT_PAGINATION } from 'services/constants';

import { TemplatesVirtualizedProps } from './interfaces';

const TemplatesVirtualized: FC<TemplatesVirtualizedProps> = ({
  rowRenderer,
  rowHeights,
  templatesData,
  loading,
  fetchMore,
  filter,
  queryKey,
  scrollId = 'templates-scroll',
}) => {
  const loadMore = () => {
    !loading &&
      templatesData.next &&
      fetchMore({
        variables: {
          filter,
          pagination: templatesData.next,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;

          const next = fetchMoreResult[queryKey].next
            ? { ...fetchMoreResult[queryKey].next, limit: DEFAULT_PAGINATION.limit }
            : null;

          return {
            [queryKey]: {
              ...prev[queryKey],
              templates: [...prev[queryKey].templates, ...fetchMoreResult[queryKey].templates],
              next,
            },
          };
        },
      });
  };

  return (
    <VirtualizedList
      rowRenderer={rowRenderer}
      loadMore={loadMore}
      data={templatesData.templates}
      scrollId={scrollId}
      rowHeights={rowHeights}
      totalRowsCount={templatesData.totalCount}
    />
  );
};

export default TemplatesVirtualized;
