import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoSongsIcon } from '../../../resources/img/svg/emptyStateNoSongs.svg';

const NoIdeasYet: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <EmptyState size="lg" icon={EmptyStateNoSongsIcon}>
      {t('noPlaylistsYet')}
      {children}
    </EmptyState>
  );
};

export default NoIdeasYet;
