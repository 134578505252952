import React, { FC } from 'react';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';
import ConfirmActionButton from '../../../../buttons/ConfirmActionButton';

import { ActionProps } from './interfaces';

const Action: FC<ActionProps> = ({
  text,
  confirmText,
  icon,
  className,
  confirmButtonClassName,
  onClick,
  withConfirm = false,
  ...rest
}) => {
  const Content = () => (
    <div
      onClick={withConfirm ? () => null : onClick}
      className={classNames('action', className)}
      {...rest}
    >
      {icon ? <Icon icon={icon} /> : null}
      {text ? <div className="text">{text}</div> : null}
    </div>
  );

  return withConfirm ? (
    <ConfirmActionButton
      action={() => onClick?.()}
      className={confirmButtonClassName}
      confirmText={confirmText}
    >
      <Content />
    </ConfirmActionButton>
  ) : (
    <Content />
  );
};

export default Action;
