import React from 'react';
import { components } from 'react-select';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

const DropdownIndicator: typeof components.DropdownIndicator = ({ ...rest }) => (
  <components.DropdownIndicator {...rest}>
    <Icon icon={rest.isFocused ? IconmoonFont['caretUp-16'] : IconmoonFont['caretDown-16']} />
  </components.DropdownIndicator>
);
export default DropdownIndicator;
