import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  fileDetails: {
    background: theme.palette.background.paper,
    width: '100%',
    borderRadius: 'inherit',
  },

  detail: {
    display: 'flex',

    '& label': {
      color: theme.palette.button.active,
      fontSize: '14px',
    },

    '& span': {
      wordBreak: 'break-all',
      color: theme.palette.text.primary,
      fontSize: '14px',
      paddingLeft: '5px',
    },
  },
}));

export default useStyles;
