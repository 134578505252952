import React, { FC, createContext, memo } from 'react';

import { TPolicyContext, TPolicyContextProvider } from './interfaces';

export const PolicyContext = createContext<TPolicyContext>(undefined!);

export const PolicyContextProvider: FC<TPolicyContextProvider> = memo(({ policy, children }) => {
  const isFieldEditable = (field: string) => policy.editableFields.includes(field);

  return (
    <PolicyContext.Provider value={{ policy, isFieldEditable }}>{children}</PolicyContext.Provider>
  );
});
