import { ACCOUNT_PAGE_KEYS, SONG_IDEAS_KEYS, TEMPLATES_PAGE_KEYS } from '../constants';

export const getSelectedMainMenuKeys = (location: string): string[] => {
  const baseRoute = location.split('/')[1];

  if (baseRoute === 'events' || baseRoute === 'event') {
    return ['/events/upcoming'];
  }

  if (baseRoute === 'health-check') {
    return ['/health-check'];
  }

  if (baseRoute === 'calendar') {
    return ['/calendar'];
  }

  if (baseRoute === 'account' || baseRoute === 'profile' || location === '/me') {
    return ['/me'];
  }

  if (baseRoute === 'songIdeas') {
    return [`/songIdeas/${SONG_IDEAS_KEYS.me}`];
  }

  if (baseRoute === 'templates' || baseRoute === 'template' || baseRoute === 'favorite-sections') {
    return ['/templates'];
  }

  if (baseRoute === 'scanner') {
    return ['/scanner'];
  }

  if (baseRoute === 'academy' || baseRoute === 'lesson') {
    return ['/academy'];
  }

  if (baseRoute === 'settings') {
    return ['/settings'];
  }

  if (baseRoute === 'admin') {
    return ['/admin'];
  }

  if (baseRoute === 'marketing-assets') {
    return ['/marketing-assets'];
  }

  return [];
};

export const getSelectedEventMenuKeys = (location: string): string[] => {
  const splittedRoute = location.split('/').filter(Boolean);

  if (splittedRoute[2] === 'general') {
    return [`/event/${splittedRoute[1]}/general`];
  }

  if (splittedRoute[2] === 'pdf') {
    return [`/event/${splittedRoute[1]}/pdf`];
  }

  if (splittedRoute[2] === 'users') {
    return [`/event/${splittedRoute[1]}/users`];
  }

  if (splittedRoute[2] === 'timeline') {
    return [`/event/${splittedRoute[1]}/timeline`];
  }

  if (splittedRoute[2] === 'prepMode') {
    return [`/event/${splittedRoute[1]}/prepMode`];
  }

  return [];
};

export const getSelectedTemplateMenuKeys = (location: string): string[] => {
  const splittedRoute = location.split('/').filter(Boolean);

  if (splittedRoute[2] === 'general') {
    return [`/template/${splittedRoute[1]}/general`];
  }

  if (splittedRoute[2] === 'users') {
    return [`/template/${splittedRoute[1]}/users`];
  }

  if (splittedRoute[2] === 'timeline') {
    return [`/template/${splittedRoute[1]}/timeline`];
  }

  return [];
};

export const getSelectedEventsMenuKeys = (location: string): string[] => {
  const splittedRoute = location.split('/').filter(Boolean);

  if (splittedRoute[1] === 'upcoming') {
    return ['/events/upcoming'];
  }

  if (splittedRoute[1] === 'history') {
    return ['/events/history'];
  }

  return [];
};

export const getSelectedSongIdeasMenuKeys = (location: string): string[] => {
  const splittedRoute = location.split('/').filter(Boolean);

  if (splittedRoute[1] === 'vibo') {
    return [SONG_IDEAS_KEYS.vibo];
  }

  if (splittedRoute[1] === 'company') {
    return [SONG_IDEAS_KEYS.company];
  }

  if (splittedRoute[1] === 'me') {
    return [SONG_IDEAS_KEYS.me];
  }

  return [];
};

export const getSelectedAccountMenuKeys = (location: string): string[] => {
  const splittedRoute = location.split('/').filter(Boolean);

  if (splittedRoute[0] === ACCOUNT_PAGE_KEYS.account) {
    return [ACCOUNT_PAGE_KEYS.account];
  }

  if (splittedRoute[0] === ACCOUNT_PAGE_KEYS.me) {
    return [ACCOUNT_PAGE_KEYS.me];
  }

  return [];
};

export const getSelectedTemplatesMenuKeys = (location: string): string[] => {
  const splittedRoute = location.split('/').filter(Boolean);

  if (splittedRoute[1] === TEMPLATES_PAGE_KEYS.user) {
    return [TEMPLATES_PAGE_KEYS.user];
  }

  if (splittedRoute[1] === TEMPLATES_PAGE_KEYS.vibo) {
    return [TEMPLATES_PAGE_KEYS.vibo];
  }

  if (splittedRoute[1] === TEMPLATES_PAGE_KEYS.company) {
    return [TEMPLATES_PAGE_KEYS.company];
  }

  if (splittedRoute[0] === 'favorite-sections') {
    return ['/favorite-sections'];
  }

  return [];
};
