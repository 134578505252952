import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboCheckbox: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',

    '&.disabled': {
      color: theme.palette.text.hint,
      cursor: 'not-allowed',
    },
  },

  indeterminate: {
    color: theme.palette.text.hint,
  },
}));

export default useStyles;
