import React, { FC, PropsWithChildren } from 'react';
import { Item } from 'rc-menu';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';

import { MenuItemProps } from './interfaces';

import useStyles from './style';

const MenuItem: FC<PropsWithChildren<MenuItemProps>> = ({
  className,
  icon,
  children,
  disableAutoFontSize,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Item className={classNames('menuItem', classes.menuItem, className)} {...rest}>
      <div className={classNames('menuItemContent', classes.menuItemContent)}>
        {icon ? <Icon icon={icon} disableAutoFontSize={disableAutoFontSize} /> : null} {children}
      </div>
    </Item>
  );
};

export default MenuItem;
