import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Menu from 'vibo-ui/Menu';
import Header from 'components/common/Header';
import GoBack from 'components/buttons/GoBack';
import Container from 'components/common/Container';
import { EventContext } from 'components/context/EventContext';

import { EVENT_PAGE_MENU_ITEMS } from 'services/constants';
import { getSelectedEventMenuKeys } from 'services/common/routesHelper';

import { EventUserType } from 'types/enums';

import useHeaderStyles from 'components/common/Header/style';

const EventPageHeader: FC = () => {
  const headerClasses = useHeaderStyles();
  const { t } = useTranslation();

  const { event } = useContext(EventContext);

  const { push } = useHistory();
  const match = useRouteMatch<EventPageRouteParams>();

  return (
    <Header>
      <GoBack className={headerClasses.goBack} to="/events/upcoming" />
      <Container>
        <Menu
          getSelectedSidebarMenuKeys={getSelectedEventMenuKeys}
          styleModificator="siderTop"
          mode="horizontal"
        >
          {EVENT_PAGE_MENU_ITEMS.filter(({ roles }) =>
            roles.includes(event?.role as EventUserType)
          ).map(({ name, route }) => {
            const link = `/event/${match.params?.id}/${route}`;

            return (
              <Menu.Item onClick={() => push(link)} key={link}>
                {t(name)}
              </Menu.Item>
            );
          })}
        </Menu>
      </Container>
    </Header>
  );
};

export default EventPageHeader;
