import React, { useCallback } from 'react';

import Icon from 'vibo-ui/Icon';

import { ReactComponent as USA } from '../../../resources/img/svg/Dashboard/SettingsIcons/USA.svg';
import { ReactComponent as Danish } from '../../../resources/img/svg/Dashboard/SettingsIcons/danish.svg';
import { ReactComponent as Hebrew } from '../../../resources/img/svg/Dashboard/SettingsIcons/hebrew.svg';
import { ReactComponent as German } from '../../../resources/img/svg/Dashboard/SettingsIcons/german.svg';
import { ReactComponent as Canada } from '../../../resources/img/svg/Dashboard/SettingsIcons/canada.svg';
import { ReactComponent as Spanish } from '../../../resources/img/svg/Dashboard/SettingsIcons/spanish.svg';

import { useTranslation } from 'react-i18next';

const UserLanguage = () => {
  const { i18n } = useTranslation();

  const lang = i18n.language;

  const getLangFlag = useCallback(() => {
    switch (lang) {
      case 'en':
        return <USA />;
      case 'da':
        return <Danish />;
      case 'fr':
        return <Canada />;
      case 'de':
        return <German />;
      case 'es':
        return <Spanish />;
      case 'iw':
        return <Hebrew />;

      default:
        return <USA />;
    }
  }, [lang]);

  return <Icon icon={getLangFlag} />;
};

export default UserLanguage;
