import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  questionsBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    userSelect: 'none',
  },
}));

export default useStyles;
