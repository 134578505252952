import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { AuthRequiredInline } from '../../auth/AuthRequiredInline';

const InlineAuth: React.FC<RouteProps> = ({ component: Component, ...rest }: RouteProps) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthRequiredInline>{Component && <Component {...matchProps} />}</AuthRequiredInline>
      )}
    />
  );
};

export default InlineAuth;
