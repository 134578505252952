import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  text: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '23.43px',
    width: '630px',
  },

  paymentFailed: {
    '& .text': {
      maxWidth: '540px',
    },
  },

  button: {
    margin: '0 0 0 11px',
  },
}));

export default useStyles;
