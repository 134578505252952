import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songItemActionsGroup: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  kebabWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    margin: '0 4px',
    color: theme.palette.primary.main,

    '& > .viboIcon': {
      transform: 'rotate(90deg)',
    },
  },

  kebabCircle: {
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },

  like: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > svg': {
      transform: 'translateY(-6px)',
      width: '33.1px',
      height: '36px',
      marginRight: '2px',
    },

    '& > .count': {
      minWidth: 'auto',
    },
  },

  creatorImage: {
    margin: '0 4px',
    width: '24px!important',
    height: '24px!important',
  },
}));

export default useStyles;
