import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import InfoPanel from '../../layouts/InfoPanel';

import { useMe } from 'graphql/hooks/user';

import usestyles from './style';

const ChildEditingStatusBar: FC = () => {
  const classes = usestyles();
  const { t } = useTranslation();

  const [isStatusVisible, setIsStatusVisible] = useState<boolean>(false);

  const { user, isChildDj } = useMe();

  useEffect(() => {
    if (isChildDj && !user?.djSettings?.allowUpdateProfileForChild) {
      setIsStatusVisible(true);
    }
  }, [isChildDj]);

  return isStatusVisible ? (
    <InfoPanel
      className="warning"
      textClass={classes.statusText}
      text={t('childDjProfileEditingStatus')}
    >
      <Button
        onClick={() => setIsStatusVisible(false)}
        displayType="primary"
        shape="round"
        size="lg"
      >
        {t('ok')}
      </Button>
    </InfoPanel>
  ) : null;
};

export default ChildEditingStatusBar;
