import React, { FC, useEffect, useState } from 'react';

import { secToTime } from 'services/common/timeHelper';

import { SeekTimeProps } from './interfaces';

const SeekTime: FC<SeekTimeProps> = ({ player }) => {
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    player.on('audioprocess', time => {
      setPlayed(time);
    });

    setDuration(player.getDuration());
  }, []);

  return (
    <span className="seek">{`${secToTime(+played.toFixed(0))} / ${secToTime(duration)}`}</span>
  );
};

export default SeekTime;
