import React, { FC, useCallback, useContext, useEffect, memo, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';

import Sections from 'components/sections/Sections';
import TimelineSection from 'components/sections/TimelineSection';
import CreateSectionButton from 'components/buttons/CreateSectionButton';
import ColorfulSectionWrapper from 'components/events/ColorfulSectionWrapper';
import TimelineSectionsHeader from 'components/sections/TimelineSectionsHeader';
import CollapseExpandSectionsButton from 'components/buttons/CollapseExpandSectionsButton';
import { EventContext } from 'components/context/EventContext';
import { SectionsFilterableContext } from '../SectionsFilterContext';

import { useModal } from 'vibo-ui/Modal';
import { getFilteredSectionsByQ, hasSectionsFilterValue } from 'services/sections/helpers';

import { SECTIONS, SECTIONS_NO_IMAGES_ALLOWED } from 'graphql/queries/events';
import { DELETE_EVENT_SECTION, REORDER_EVENT_SECTIONS } from 'graphql/mutations/events';

import { Modals } from 'types/enums';
import { DeleteSectionModalProps } from 'components/modals/DeleteSectionModal';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { EventSectionsProps } from './interfaces';

const EventSections: FC<EventSectionsProps> = ({
  event,
  sections,
  selectedSection,
  refetch,
  isSectionsLoading,
  updateSection,
  onSectionClick,
  onEditClick,
}) => {
  const { t } = useTranslation();

  const eventId: string = event._id;

  const [, setSectionsQ] = useQueryParam('sectionsQ', StringParam);

  const { filter, setFilter } = useContext(SectionsFilterableContext);

  const { isCoverPhotoAllowed, canAddSection = false } = useContext(EventContext);

  const { openModal } = useModal();

  const filteredSections = useMemo(() => getFilteredSectionsByQ(sections, filter?.q), [
    sections,
    filter,
  ]);

  const openDeleteSectonModal = useCallback(
    section =>
      openModal<DeleteSectionModalProps>({
        key: Modals.deleteSection,
        props: {
          delteSectionMutation: DELETE_EVENT_SECTION,
          refetchQueries: ['sections'],
          variables: {
            eventId,
            sectionId: section._id,
          },
        },
      }),
    [eventId]
  );
  const openConfirmReorderingModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('clearSearch'),
          children: t('clearSearchReorderSections'),
          submit: {
            text: t('clear'),
            onClick: handleConfirmReordering,
          },
        },
      }),
    []
  );

  const [reorderSections] = useMutation<boolean, ReorderSectionsVariables>(REORDER_EVENT_SECTIONS, {
    refetchQueries: ['sections'],
  });

  const handleConfirmReordering = useCallback(() => {
    setSectionsQ(undefined);
    setFilter(null);
    refetch({ eventId, filter });
  }, [eventId, filter?.q]);

  useEffect(() => {
    if (
      !selectedSection ||
      (!filteredSections.includes(selectedSection) && filteredSections.length)
    ) {
      onSectionClick(filteredSections[0]);
    }
  }, [sections, selectedSection, onSectionClick]);

  return (
    <Sections<EventSection, EventSectionsVariables>
      sections={filteredSections}
      reorderProps={{
        // @ts-ignore
        onReorder: reorderSections,
        variables: { eventId },
        listQuery: isCoverPhotoAllowed ? SECTIONS : SECTIONS_NO_IMAGES_ALLOWED,
        listCacheKey: 'sections',
        onBeforeCapture: () => {
          if (hasSectionsFilterValue(filter)) {
            return openConfirmReorderingModal();
          }
        },
      }}
      renderSection={({ section, isDragging }): JSX.Element => (
        <ColorfulSectionWrapper headlineColors={section.headlineColors}>
          <TimelineSection
            section={section}
            isSelected={section._id === selectedSection?._id}
            isDragging={isDragging}
            onClick={onSectionClick}
            onEditClick={onEditClick}
            onDeleteClick={openDeleteSectonModal}
            onTimeChange={time =>
              updateSection({
                variables: {
                  eventId,
                  sectionId: section._id,
                  payload: {
                    time,
                  },
                },
              })
            }
            onNameChange={debounce(
              name =>
                updateSection({
                  variables: {
                    eventId,
                    sectionId: section._id,
                    payload: { name },
                  },
                }),
              1000
            )}
          />
        </ColorfulSectionWrapper>
      )}
      renderHeader={() => (
        <TimelineSectionsHeader
          numSections={filteredSections.length}
          onSearch={q => {
            setFilter(prev => ({ ...prev, q }));
          }}
          buttons={[
            canAddSection ? (
              <CreateSectionButton
                eventSettings={event.settings as EventSettings}
                tooltipProps={{
                  overlay: t('addSection'),
                  fillOverlay: true,
                  placement: 'top',
                }}
                shape="round"
                size="lg"
                key="add-section-btn"
              />
            ) : null,
            <CollapseExpandSectionsButton
              disabled={!filteredSections.length}
              key="expand-collapse-sections-btn"
            />,
          ]}
        />
      )}
      isSectionsLoading={isSectionsLoading}
    />
  );
};

const shouldUpdate: (prevProps: EventSectionsProps, nextProps: EventSectionsProps) => boolean = (
  prevProps: EventSectionsProps,
  nextProps: EventSectionsProps
) => {
  return (
    prevProps.event.note === nextProps.event.note &&
    prevProps.selectedSection === nextProps.selectedSection &&
    prevProps.sections === nextProps.sections &&
    prevProps.isSectionsLoading === nextProps.isSectionsLoading
  );
};

export default memo(EventSections, shouldUpdate);
