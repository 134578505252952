import React from 'react';
import classNames from 'classnames';

import useStyles from './style';

interface ImageDefaultPlaceholderProps {
  icon?: JSX.Element;
  text?: string;
  className?: string;
}

const ImageDefaultPlaceholder: React.FC<ImageDefaultPlaceholderProps> = ({
  icon,
  text,
  className,
}) => {
  const classes = useStyles();

  return icon || text ? (
    <div
      className={classNames('imageDefaultPlaceholder', classes.imageDefaultPlaceholder, className)}
    >
      {icon}
      <span>{text}</span>
    </div>
  ) : null;
};

export default ImageDefaultPlaceholder;
