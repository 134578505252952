import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songBlockHeader: {
    padding: '4px 6px 0 6px',
  },

  songBlockBody: {
    padding: '0 6px 8px 6px',

    '& .songWrapper, & .fileRight': {
      '& .viboIcon': {
        color: theme.palette.primary.main,
        borderRadius: '2px',
      },
    },

    '& .songWrapper': {
      pointerEvents: 'none',

      '& .viboCheckbox, & .fileRight, & .copyButton, & .progressBar, & .viboIcon': {
        pointerEvents: 'all',
      },
    },
  },

  songBlockFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.primary.light}`,

    '& .viboButton': {
      width: '50%',

      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.primary.light}`,
      },
    },
  },
}));

export default useStyles;
