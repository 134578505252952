import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  filePreview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    fontSize: '13px',
    borderRadius: '4px',
    padding: '6px 0',
    gap: '6px',

    '& > .viboButton.smSize': {
      height: 'auto',
      padding: 0,
    },

    '& .fileName': {
      ...theme.mixins.longText,
      maxWidth: '350px',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '500px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      padding: '8px 0',
    },
  },
}));

export default useStyles;
