import React, { FC } from 'react';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoSongsIcon } from '../../../resources/img/svg/emptyStateNoSongs.svg';

const NoSongs: FC = ({ children }) => (
  <EmptyState className="noSongs" size="default" icon={EmptyStateNoSongsIcon}>
    {children}
  </EmptyState>
);

export default NoSongs;
