import React, { useCallback, useContext, useMemo } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import Select, { SimpleOption } from 'vibo-ui/Select';
import { EventsFilterableContext } from '../../../EventsListContext';

import { getEventStatusByFilter } from '../../../../../../services/events/helpers';

import useStyles from '../../style';

const StatusColumn: React.FC<ColumnProps> = ({ columnKey, data = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { filter, setFilter } = useContext(EventsFilterableContext);

  const statusId = useMemo(() => getEventStatusByFilter(filter), [filter.past, filter.isDeleted]);

  const options = useMemo(
    () =>
      data.map(option => ({
        label: t(option.title),
        ...option,
      })),
    [data]
  );

  const handleChange = useCallback(option => {
    const { value } = option as SimpleOption;

    setFilter((prev: EventsFilter) => ({
      ...prev,
      past: value === 'past',
      isDeleted: value === 'isDeleted',
    }));
  }, []);

  return (
    <div className="statusColumn">
      <span className="label">{t('status')}</span>
      <Select
        value={statusId}
        onChange={handleChange}
        components={{
          Option: props => {
            const data = get(props, 'data') as { color: string };

            return (
              <Select.Option {...props}>
                <div className={classes.dot} style={{ backgroundColor: data.color }} />
                {props.label}
              </Select.Option>
            );
          },
        }}
        options={options}
        styleModificator="asLink"
        hideDropdownIndicator
        menuShouldBlockScroll
      />
    </div>
  );
};

export default StatusColumn;
