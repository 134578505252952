import React from 'react';
import { components } from 'react-select';

import useStyles from './style';

const MultiValueLabel: typeof components.MultiValueLabel = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.multiValueLabel}>
      <components.MultiValueLabel {...rest}>{rest.children}</components.MultiValueLabel>
    </div>
  );
};

export default MultiValueLabel;
