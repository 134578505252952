import React, { FC } from 'react';
import classNames from 'classnames';

import CellName from '../../common/CellName';

import { useMe } from '../../../graphql/hooks/user';
import { renderColumnHeader } from '../../events/common/table';

import { TemplatesHeaderProps } from './interfaces';

import useEventsStyles from '../../events/EventsList/views/style';
import useStyles from './style';

const TemplatesHeader: FC<TemplatesHeaderProps> = ({ columns }) => {
  const eventsClasses = useEventsStyles();
  const classes = useStyles();

  const { user } = useMe();

  return (
    <div
      className={classNames(
        'eventsHeaderDj',
        eventsClasses.eventsHeaderDj,
        classes.templatesHeader
      )}
    >
      {columns.map(column => (
        <CellName name={column} key={`cell-name-${column}`}>
          {renderColumnHeader(column as EventsTableColumn, user)}
        </CellName>
      ))}
    </div>
  );
};

export default TemplatesHeader;
