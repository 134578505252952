import React, { FC, useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import Textarea from 'vibo-ui/Textarea';
import { UserPolicyFields } from 'components/context/PolicyContext';

import { useFieldPolicy } from 'graphql/hooks/policy';

import { AboutMeProps } from './interfaces';

const AboutMe: FC<AboutMeProps> = ({ handleUpdateProfile, user }) => {
  const { t } = useTranslation();

  const [about, setAbout] = useState<string>(user?.about || '');

  const { isEditable } = useFieldPolicy(UserPolicyFields.about);

  const updateDebounced = useRef(
    debounce((value: string) => {
      handleUpdateProfile({ about: value });
    }, 1000)
  ).current;

  const updateAboutMe = (value: string) => {
    setAbout(value);
    updateDebounced(value);
  };

  useEffect(() => {
    user?.about && setAbout(user.about);
  }, [user?.about]);

  return (
    <Textarea
      onChange={e => updateAboutMe(e.target.value)}
      defaultValue={about}
      disabled={!isEditable}
      placeholder={t('aboutMePlaceholder')}
      maxLength={200}
      withUnderline
      showMaxLength
    />
  );
};

export default AboutMe;
