import React from 'react';
import { useTranslation } from 'react-i18next';

import CellName from '../../../../components/common/CellName';
import SelectUsersFilter from './SelectUsersFilter';

import useStyles from '../style';

const UsersHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.usersHeader}>
      <CellName name="photo" />
      <CellName name="name">{t('name')}</CellName>
      <CellName name="role">
        <SelectUsersFilter filterKey="role" />
      </CellName>
      <CellName name="email">{t('email')}</CellName>
      <CellName name="finance-status">
        <SelectUsersFilter filterKey="financeStatus" />
      </CellName>
    </div>
  );
};

export default UsersHeader;
