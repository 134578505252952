import React from 'react';

const Mobile: React.FC = () => {
  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return <div style={style}>Please open 'Vibo DJ' with a computer</div>;
};

export default Mobile;
