import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  authSpotify: {
    margin: '25px 0',
    padding: '16px 25px',
    border: `2px solid ${theme.palette.error.light}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',

    '& .viboIcon': {
      color: theme.palette.error.light,
      margin: '0 14px 0 0',
      fontSize: '30px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '34px',
      },
    },

    '& .viboButton': {
      margin: '15px 0 0 0',
    },
  },
}));

export default useStyles;
