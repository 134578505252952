import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import CustomLabel from '../CustomLabel';

import { CustomLabelProps } from '../CustomLabel/interfaces';

import useSltyes from './style';

const HeadlineLabel: FC<CustomLabelProps> = ({ ...rest }) => {
  const classes = useSltyes();
  const { t } = useTranslation();

  return (
    <CustomLabel
      {...rest}
      className={classnames('headlineLabel', classes.headlineLabel, rest.className)}
    >
      {t('headline')}
    </CustomLabel>
  );
};

export default HeadlineLabel;
