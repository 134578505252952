import React, {
  forwardRef,
  useRef,
  useContext,
  ChangeEventHandler,
  PropsWithChildren,
} from 'react';
import classNames from 'classnames';
import RadioGroupContext from './context';
import { composeRef } from 'rc-util/lib/ref';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { RadioProps, RadioChangeEvent } from './interfaces';

import useInputStyles from 'resources/styles/inputs/style';
import useStyles from './style';

const Radio = forwardRef<HTMLInputElement, PropsWithChildren<RadioProps>>(
  (
    {
      style,
      className,
      children,
      onMouseEnter,
      onMouseLeave,
      onChange,
      checkedIcon = IconmoonFont['radioButtonOn-16'],
      uncheckedIcon = IconmoonFont['radioButtonOff-16'],
      ...rest
    },
    ref
  ) => {
    const inputClasses = useInputStyles();
    const classes = useStyles();

    const context = useContext(RadioGroupContext);

    const innerRef = useRef<Nullable<HTMLInputElement>>(null);
    const mergedRef = composeRef(ref, innerRef);

    const isChecked = rest.value === context?.value;

    const currentStateIcon = isChecked ? checkedIcon : uncheckedIcon;

    if (context) {
      rest.name = context.name;
      rest.checked = isChecked;
      rest.disabled = rest.disabled || context.disabled;
    }

    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
      context?.onChange((e as unknown) as RadioChangeEvent);
      onChange?.((e as unknown) as RadioChangeEvent);
    };

    return (
      <label
        className={classNames(
          'radio',
          {
            selected: isChecked,
            [`disabled ${inputClasses.disabled}`]: rest.disabled,
          },
          classes.radio,
          inputClasses.withOverlayCheckbox,
          className
        )}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Icon
          icon={currentStateIcon}
          className={classNames({
            [`checked ${inputClasses.checked}`]: isChecked,
            [`unchecked ${inputClasses.unchecked}`]: !isChecked,
          })}
        />
        <input className="hidden" type="radio" onChange={handleChange} ref={mergedRef} {...rest} />
        {children ? (
          <span className={classNames('content', inputClasses.content)}>{children}</span>
        ) : null}
      </label>
    );
  }
);

export default Radio;
