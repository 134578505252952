import { createContext } from 'react';

type TFilterableContext = {
  filter: EventsFilter;
  setFilter: Function;
};

type TSortableContext = {
  sort: Nullable<TSort>;
  setSort: Function;
};

export const EventsFilterableContext = createContext<TFilterableContext>(undefined!);

export const EventsSortableContext = createContext<TSortableContext>(undefined!);
