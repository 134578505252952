import React, { FC, useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import Input from 'vibo-ui/Input';
import { UserPolicyFields } from '../../../../context/PolicyContext';

import { MAX_PROFILE_NAMES_LENGTH } from 'services/constants';
import { useFieldPolicy } from '../../../../../graphql/hooks/policy';

import { LastNameProps } from './interfaces';

const LastName: FC<LastNameProps> = ({ handleUpdateProfile, user }) => {
  const { t } = useTranslation();

  const [lastName, setLastName] = useState(user?.lastName);
  const [error, setError] = useState(false);

  const { isEditable } = useFieldPolicy(UserPolicyFields.lastName);

  const updateDebounced = useRef(
    debounce((value: string) => {
      handleUpdateProfile({ lastName: value })
        .then(() => setError(false))
        .catch(() => setError(true));
    }, 1000)
  ).current;

  const updateLastName = (value: string) => {
    setLastName(value);
    updateDebounced(value);
  };

  useEffect(() => {
    user?.lastName && setLastName(user.lastName);
  }, [user?.lastName]);

  return (
    <Input
      onChange={(_, value) => updateLastName(value)}
      defaultValue={lastName}
      disabled={!isEditable}
      errors={[error ? t('nameCouldNotBeEmpty') : '']}
      maxLength={MAX_PROFILE_NAMES_LENGTH}
      size="xlg"
      withUnderline
      showMaxLength
    />
  );
};

export default LastName;
