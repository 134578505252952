import React, { FC } from 'react';
import classNames from 'classnames';

import { SectionBlockFooterProps } from './interfaces';

import useStyles from './style';

const SectionBlockFooter: FC<SectionBlockFooterProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames('sectionBlockFooter', classes.sectionBlockFooter, className)}>
      {children}
    </div>
  );
};

export default SectionBlockFooter;
