import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SettingsItem from './SettingsItem';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { getUserRoleLabel } from 'services/common/getTransaltedLabel';

import { DjType } from 'types/enums';
import { SettingsItemWithUserProps } from './interfaces';

import useStyles from './style';

const RoleInfo: FC<SettingsItemWithUserProps> = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const role = useMemo(
    () => (!!user?.djSettings?.type ? getUserRoleLabel(user.djSettings.type as DjType) : '-'),
    [user?.djSettings?.type]
  );

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={IconmoonFont['user-24']} className={classes.generalIcon} />
        <div className={classes.greyText}>{t('role')}</div>
        <div className={classNames(classes.role, classes.boldText)}>{t(role)}</div>
      </div>
    </SettingsItem>
  );
};

export default RoleInfo;
