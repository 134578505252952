import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import EventTypeIcon from 'components/events/common/EventTypeIcon';

import { EventType } from 'types/enums';

import useStyles from './style';

const TypeCell: FC<CellProps<EventListItem>> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classNames('typeCell', classes.typeCell)}>
      <EventTypeIcon type={data?.type as EventType} />
      {t(data.type)}
    </div>
  );
};

export default TypeCell;
