import get from 'lodash/get';

import { FILE_DETAIL_FIELDS } from '../constants';

import { AssigneType } from 'components/buttons/AssignPlaylistButton/interfaces';
import { ButtonIconType, DjType, QuestionType, SongsSortOptionKeyEnum } from 'types/enums';

export const SONGS_SORT_OPTIONS_LABEL = {
  [SongsSortOptionKeyEnum.topVoted]: 'topVoted',
  [SongsSortOptionKeyEnum.oldest]: 'oldestOnTop',
  [SongsSortOptionKeyEnum.newest]: 'newestOnTop',
  [SongsSortOptionKeyEnum.custom]: 'customOrder',
};

export const SONG_FILE_OPTIONS_LABEL = {
  [FILE_DETAIL_FIELDS[0]]: 'file',
  [FILE_DETAIL_FIELDS[1]]: 'location',
  [FILE_DETAIL_FIELDS[2]]: 'title',
  [FILE_DETAIL_FIELDS[3]]: 'artist',
  [FILE_DETAIL_FIELDS[4]]: 'fileSize',
  [FILE_DETAIL_FIELDS[5]]: 'length',
  [FILE_DETAIL_FIELDS[6]]: 'kbps',
  [FILE_DETAIL_FIELDS[7]]: 'album',
  [FILE_DETAIL_FIELDS[8]]: 'genre',
  [FILE_DETAIL_FIELDS[9]]: 'comment',
};

export const QUESTION_TYPES_LABEL = {
  [QuestionType.text]: 'textAnswer',
  [QuestionType.checkbox]: 'checkboxes',
  [QuestionType.radio]: 'multipleChoice',
  [QuestionType.header]: 'description',
};

export const SONGS_FILTER_OPTIONS_LABEL = {
  [ButtonIconType.totalCount]: 'allAmount',
  [ButtonIconType.mustPlayCount]: 'mustPlayAmount',
  [ButtonIconType.hostLikedCount]: 'hostLikedAmount',
  [ButtonIconType.withCommentsCount]: 'withCommentsAmount',
  [ButtonIconType.flaggedCount]: 'flaggedAmount',
  [ButtonIconType.mark]: 'selected',
  [ButtonIconType.unchecked]: 'unselected',
  [ButtonIconType.close]: 'notMatched',
  [ButtonIconType.flag]: 'flagged',
};

export const getSongsSortOptionsLabel = (type: SongsSortOptionKey): string =>
  get(SONGS_SORT_OPTIONS_LABEL, type, '');

export const getSongsFilterOptionsLabel: (
  type: keyof SectionSongsStats,
  withBrackets?: boolean
) => string = (type, withBrackets = false) => {
  const label: string = get(SONGS_FILTER_OPTIONS_LABEL, type, '');

  return label.concat(withBrackets ? 'InBrackets' : '').trim();
};

export const getQuestionTypeLabel = (type: QuestionType) => get(QUESTION_TYPES_LABEL, type);

export const getSongFileOptionsLabel = (type: keyof FileDetails) =>
  get(SONG_FILE_OPTIONS_LABEL, type);

export const USER_ROLE_LABEL = {
  [DjType.child]: 'djInMultiOp',
  [DjType.company]: 'company',
  [DjType.single]: 'single',
};

export const getUserRoleLabel: (type: DjType) => string = type => get(USER_ROLE_LABEL, type);

export const PLAYLIST_ASSIGNE_LABEL = {
  [AssigneType.event]: 'event',
  [AssigneType.template]: 'template',
  [AssigneType.favoriteSections]: 'favoriteSections_plural',
};

export const getPlaylistAssigneLabel: (type: AssigneType) => string = type =>
  get(PLAYLIST_ASSIGNE_LABEL, type);
