import moment, { RelativeTimeSpec } from 'moment';

import { ReactComponent as LockIcon } from 'resources/img/svg/common/lock.svg';
import { ReactComponent as ArrowLeftIcon } from 'resources/img/svg/common/arrowLeft-16.svg';
import { ReactComponent as ArrowRightIcon } from 'resources/img/svg/common/arrowRight.svg';
import { ReactComponent as InfoFilledIcon } from 'resources/img/svg/common/infoFilled.svg';
import { ReactComponent as LockoutDateCalendarIcon } from 'resources/img/svg/common/lockoutDateCalendar.svg';

import { NotificationType } from './interface';

export const momentShort = moment;

export const notificationRedirectsByType = {
  [NotificationType.accountStatusChangedToActivated]: () => '/settings',
  [NotificationType.accountStatusChangedToChargeFailed]: () => '/settings',
  [NotificationType.accountStatusChangedToLimited]: () => '/settings',
  [NotificationType.eventLocked]: (id: string) => `/event/${id}/general`,
  [NotificationType.lockoutDate]: (id: string) => `/event/${id}/general`,
  [NotificationType.hostGuestJoinedEvent]: (id: string) => `/event/${id}/users`,
  [NotificationType.hostGuestLeftEvent]: (id: string) => `/event/${id}/users`,
};

export const notificationIconByType = {
  [NotificationType.accountStatusChangedToActivated]: InfoFilledIcon,
  [NotificationType.accountStatusChangedToChargeFailed]: InfoFilledIcon,
  [NotificationType.accountStatusChangedToLimited]: InfoFilledIcon,
  [NotificationType.eventLocked]: LockIcon,
  [NotificationType.lockoutDate]: LockoutDateCalendarIcon,
  [NotificationType.hostGuestJoinedEvent]: ArrowRightIcon,
  [NotificationType.hostGuestLeftEvent]: ArrowLeftIcon,
};

export const NOTIFICATIONS_PAGINATION = {
  skip: 0,
  limit: 20,
};

export const MAX_NOTIFICATIONS_CROP_COUNT = 99;

momentShort.locale('en', {
  relativeTime: ({
    s: '1s',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: (days: number) => {
      const weeks = Math.floor(days / 7);

      return days >= 7 ? `${weeks}w` : `${days}d`;
    },
    w: 'w',
    ww: '%d w',
    M: '4w',
    MM: (months: number) => {
      const weeks = Math.floor(months * 4);

      return `${weeks}w`;
    },
    y: 'a year',
    yy: '%d years',
  } as unknown) as RelativeTimeSpec,
});

export const TOOLTIP_ALIGN = { offset: [2, 16] };
