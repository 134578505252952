import React, { FC } from 'react';
import classNames from 'classnames';

import { SongIndexProps } from './interfaces';

import useStyles from './style';

const SongIndex: FC<SongIndexProps> = ({ index, className }) => {
  const classes = useStyles();

  return <div className={classNames('songIndex', classes.songIndex, className)}>{index}</div>;
};

export default SongIndex;
