import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  progressBar: {
    height: '16px',
    width: '30px',
    backgroundColor: colors.gray,
    position: 'relative',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,

    '& span': {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
    },
  },

  fill: {
    height: '100%',
    borderRadius: 'inherit',
    position: 'absolute',
  },

  progress: {
    margin: 'auto',
    fontWeight: 'bold',
  },
}));

export default useStyles;
