import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';
import Image from 'vibo-ui/Image';
import Video from 'components/modals/VideoPreviewModal/Video';

import { ReactComponent as YoutubeIcon } from 'resources/img/svg/youtube.svg';

import { useModal } from 'vibo-ui/Modal';
import { isLoomLink } from 'services/common/youtubeHelpers';
import { getLoomIdFromWatchUrl, getYouTubeIdFromWatchUrl } from 'services/songs/helpers';

import { Modals } from 'types/enums';
import { VideoPreviewModalProps } from 'components/modals/VideoPreviewModal';
import { VideoPreviewProps } from './interfaces';

import useStyles from './style';

const VideoPreview: FC<VideoPreviewProps> = ({ link, options, isInlinePlay = false, children }) => {
  const classes = useStyles();

  const { openModal } = useModal();

  const isLoom = useMemo(() => isLoomLink(link), [link]);
  const videoId = useMemo(
    () =>
      !!link ? (isLoom ? getLoomIdFromWatchUrl(link) : getYouTubeIdFromWatchUrl(link)) : undefined,
    [link]
  );
  const videoThumbnail = useMemo(
    () =>
      isLoom
        ? `https://cdn.loom.com/sessions/thumbnails/${videoId}-with-play.gif`
        : `https://img.youtube.com/vi/${videoId}/0.jpg`,
    [videoId]
  );

  const openVideoPreviewModal = () => {
    !!videoId &&
      openModal<VideoPreviewModalProps>({
        key: Modals.videoPreiew,
        props: isLoom
          ? { loomVideoLink: link }
          : {
              youtubeVideoId: videoId,
              options,
            },
      });
  };

  const videoProps = useMemo(
    () =>
      isLoom
        ? { loomVideoLink: link }
        : {
            youtubeVideoId: videoId,
            options,
          },
    [isLoom, link, videoId, options]
  );

  return !!videoId ? (
    <div className={classNames('videoPreview', classes.videoPreview)}>
      {isInlinePlay ? (
        <Video {...videoProps} />
      ) : (
        <Image onClick={openVideoPreviewModal} src={videoThumbnail} />
      )}
      {isLoom || isInlinePlay ? null : <Icon icon={YoutubeIcon} />}
      {children}
    </div>
  ) : null;
};

export default VideoPreview;
