import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ActiveDj from 'components/user/ActiveDj';
import CreateButton from '../CreateButton';

import { ModalType, useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { ButtonProps } from 'vibo-ui/Button';
import {
  CreateNewEventModalProps,
  CreateNewEventModalBase,
} from 'components/modals/CreateNewEventModal';

const CreateEventButton: FC<ButtonProps & CreateNewEventModalBase> = ({
  className,
  date,
  withRedirectToEvent,
  refetchQueries,
  ...rest
}) => {
  const { t } = useTranslation();

  const { openModal } = useModal();

  return (
    <ActiveDj>
      <CreateButton
        onClick={() =>
          openModal<CreateNewEventModalProps>({
            key: Modals.createEvent,
            type: ModalType.permanent,
            props: {
              date,
              withRedirectToEvent,
              refetchQueries,
            },
          })
        }
        text={t('createEvent')}
        className={className}
        {...rest}
      />
    </ActiveDj>
  );
};

export default CreateEventButton;
