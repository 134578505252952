import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  confirmActionButton: {
    '& button': {
      width: '100%',
      textAlign: 'left',
    },
  },
}));

export default useStyles;
