import pick from 'lodash/pick';
import reduce from 'lodash/reduce';

import COLORS from '../colors';
import { STATUS, EVENT_STATUSES } from '../constants';

import { ChangeEventImageStep, GenerateEventPlaylistsSectionsFilter } from '../../types/enums';
import { FirstEventData } from '../../components/context/IntercomContext/interfaces';

export const getStatusColor = (status: string): string => {
  switch (status) {
    case STATUS.active:
      return COLORS.statusGreen;
    case STATUS.pending:
      return COLORS.statusYellow;
    case STATUS.locked:
      return COLORS.statusRed;
    case STATUS.deleted:
    case STATUS.delete:
      return COLORS.statusGrey;
    case STATUS.past:
      return COLORS.statusBlack;
    default:
      return 'transparent';
  }
};

export const getEventStatusByFilter = (filter: EventsFilter): string | undefined => {
  return filter.isDeleted ? 'isDeleted' : filter.past ? 'past' : undefined;
};

export const getEventStatusLabel = (event: EventItem): string => {
  if (event.isDeleted) {
    return 'deleted';
  }

  if (event.isPast) {
    return 'past';
  }

  return event.status;
};

export const getUpcomingStatus = (event: EventItem): number => EVENT_STATUSES[event.status];

export const getChangeUserType = (userType: string) => (userType === 'host' ? 'guest' : 'host');

export const getUserType = (userType: string) => (userType === 'Guests' ? 'guest' : 'host');

const isUpcomingEventsResponse = (v: any): v is UpcomingEventsResponse => {
  return v && v.upcomingEvents !== undefined;
};

const isUpcomingEventsWhereDjResponse = (v: any): v is UpcomingEventsWhereDjResponse => {
  return v && v.upcomingEventsWhereDj !== undefined;
};

const isHistoryEventsResponse = (v: any): v is HistoryEventsResponse => {
  return v && v.historyEvents !== undefined;
};

export const getEventsData = (data: any): EventsListData => {
  if (isUpcomingEventsResponse(data)) {
    return data.upcomingEvents;
  }

  if (isUpcomingEventsWhereDjResponse(data)) {
    return data.upcomingEventsWhereDj;
  }

  if (isHistoryEventsResponse(data)) {
    return data.historyEvents;
  }

  return {
    events: [],
    totalCount: 0,
    next: null,
  };
};

export const getEventStatus = (event: EventListItem): string => {
  return event.isDeleted ? 'deleted' : event.isPast ? 'past' : event.status;
};

export const getInitialChangeEventImageStep = (
  image: Nullable<EventImage>
): ChangeEventImageStep => {
  if (image?.squareUrl) {
    return ChangeEventImageStep.view;
  }

  return ChangeEventImageStep.select;
};

export const getInitialEventImageSource = (image: Nullable<EventImage>): UpdateEventImageInput => ({
  squareUrl: image?.squareUrl,
});

export const getTotalSongsExported = (sections: ExportToSpotifyData[]) => {
  return reduce(
    sections.map(item => item.exportedCount),
    (total: number, exportedCount: number) => total + exportedCount
  );
};

export const getSongsToFlag = (failedToExportSongs: FailedToExportSong[][]) => {
  return reduce(
    failedToExportSongs,
    (failedSongIds: SectionAndViboSongId[], songs: FailedToExportSong[]) => {
      return [...failedSongIds, ...songs.map(song => pick(song, ['sectionId', 'viboSongId']))];
    },
    []
  );
};

export const getPreparedSongsFilter = (filter: string, sectionIds: string[]) => {
  return sectionIds.length === 1
    ? filter === GenerateEventPlaylistsSectionsFilter.all
      ? undefined
      : { isFlagged: true }
    : undefined;
};

export const getInitialPagination = (state: Nullable<EventsLocationState>): Nullable<Cursor> => {
  if (state?.limit) {
    return {
      skip: 0,
      limit: state.limit,
    };
  }

  return null;
};

export const mergePdfSectionOptions = (
  sectionOptions: PrintSectionOptions[],
  values: PrintSectionOptionsRow[]
): PrintSectionOptions[] => {
  const updatedSectionOptions: PrintSectionOptions[] = [];

  for (const sectionOptionsRow of values) {
    const oldSectionOptions = sectionOptions.find(s => s._id === sectionOptionsRow._id);

    if (oldSectionOptions) {
      updatedSectionOptions.push({
        ...oldSectionOptions,
        ...pick(sectionOptionsRow, ['showSongs', 'showNotes', 'showQuestions', 'selected']),
      });
    }
  }

  return updatedSectionOptions;
};

export const getEventContactPhone = (contact: EventContact) =>
  !!contact.phoneNumber ? `+${contact.phoneCode || ''} ${contact.phoneNumber}` : '';

export const getEventInfoForIntercom = (event: EventListItem): FirstEventData => {
  return {
    eventId: event._id,
    eventTitle: event.title,
    djsIds: event.djs.map(({ _id }) => _id).join(','),
    djsFullNames: event.djs.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(','),
    eventRole: event.role,
  };
};
