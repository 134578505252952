import React, { FC, useContext } from 'react';
import round from 'lodash/round';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import EventProgress from './EventProgress';
import ContentArea from '../../../components/common/ContentArea';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { EventContext } from '../../../components/context/EventContext';

import useStyles from './style';

const ProgressInfoBlock: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { event } = useContext(EventContext);

  const sectionsWithSongsProgress = (event?.sectionsWithSongsProgress ?? 0) * 100;
  const questionsProgress = (event?.progress ?? 0) * 100;

  return (
    <ContentArea className={classes.progressInfoBlock} shadowed>
      <label className={classNames('progressRow', classes.progressRow)}>
        <Icon className={classes.fieldIcon} icon={IconmoonFont['musicNoteSingleOutlined-24']} />
        <EventProgress
          label={t('songSectionsProgress', {
            currentCount: event?.sectionsWithSongs,
            allCount: event?.sectionsWithSongsTotal,
          })}
          info={`${round(sectionsWithSongsProgress)}%`}
          progress={sectionsWithSongsProgress}
        />
      </label>
      <label className={classNames('progressRow', classes.progressRow)}>
        <Icon className={classes.fieldIcon} icon={IconmoonFont['questionOutlined-24']} />
        <EventProgress
          label={t('questionsProgress', {
            currentCount: event?.answeredQuestionsCount,
            allCount: event?.questionsCount,
          })}
          info={`${round(questionsProgress)}%`}
          progress={questionsProgress}
        />
      </label>
    </ContentArea>
  );
};

export default ProgressInfoBlock;
