import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import AuthLayout from '../../AuthLayout';
import ViboLogo from 'components/common/ViboLogo';

const RestorePasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout className="reset-password-success">
      <ViboLogo />
      <span className="text">{t('passwordChangedSuccessfully')}</span>
      <Button to="/login" displayType="primary" shape="round" size="lg">
        {t('ok')}
      </Button>
    </AuthLayout>
  );
};

export default RestorePasswordSuccess;
