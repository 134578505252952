import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Location from 'components/common/Location';
import { UserPolicyFields } from 'components/context/PolicyContext';

import { useFieldPolicy } from 'graphql/hooks/policy';

type ProfileLocationProps = {
  handleUpdateProfile: (data: Partial<User>) => Promise<any>;
  user: Maybe<User>;
};

const ProfileLocation: FC<ProfileLocationProps> = ({ handleUpdateProfile, user }) => {
  const { t } = useTranslation();

  const { isEditable } = useFieldPolicy(UserPolicyFields.site);

  return (
    <Location
      onLocationChange={location => {
        handleUpdateProfile({
          location,
        });
      }}
      locationValue={user?.location}
      placeholder={t('myLocation')}
      disabled={!isEditable}
      withUnderline
    />
  );
};

export default ProfileLocation;
