import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const DefaultColumn = <TField extends any>({
  columnKey,
  className,
}: ColumnProps<TField>): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return <div className={classNames(classes.defaultColumn, className)}>{t(columnKey)}</div>;
};

export default DefaultColumn;
