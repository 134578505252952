import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  title: {
    fontWeight: 500,
    fontSize: '19px',
    lineHeight: '26.92px',
    color: theme.palette.text.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: '0 0 18px 0',

    '@media screen and (min-width: 1441px)': {
      fontSize: '24px',
      lineHeight: '34px',
    },
  },
}));

export default useStyles;
