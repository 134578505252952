export type TPolicyContext = {
  policy: Policy;
  isFieldEditable: (field: string) => boolean;
};

export type TPolicyContextProvider = {
  policy: Policy;
};

export interface UserPolicyContextContent {
  user: Maybe<User>;
}

export enum UserPolicyFields {
  'firstName' = 'firstName',
  'lastName' = 'lastName',
  'nick' = 'nick',
  'showName' = 'showName',
  'phone' = 'phone',
  'country' = 'country',
  'imageUrl' = 'imageUrl',
  'logoUrl' = 'logoUrl',
  'site' = 'site',
  'about' = 'about',
  'reviews' = 'reviews',
  'lang' = 'lang',
  'djSettings' = 'djSettings',
  'location' = 'location',
  'subscriptions' = 'subscriptions',
  'socialLinks' = 'socialLinks',
}
