import React, { FC, useContext } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import EventInfoRow from './EventInfoRow';
import EventInfoTime from './EventInfoTime';
import ContentBlockHeader from './ContentBlockHeader';
import DjsPreview from 'components/common/DjsPreview';
import ContentArea from '../../../../components/common/ContentArea';
import WithEventRole from '../../../../components/user/WithEventRole';
import EventTypeIcon from '../../../../components/events/common/EventTypeIcon';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { EventContext } from '../../../../components/context/EventContext';

import { PDF_OPTIONS_TEXT } from '../../../../services/constants';

import { EventType, EventUserType, PdfSettingOptions } from '../../../../types/enums';

import useStyles from '../style';

const EventInfoContent: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { event } = useContext(EventContext);

  return (
    <div className={classes.contentBlock} data-spy-id={PdfSettingOptions.eventInfo}>
      <ContentBlockHeader
        name={PDF_OPTIONS_TEXT[PdfSettingOptions.eventInfo]}
        inputName="withEventDetails"
      />
      <ContentArea className={classNames('eventInfoContent', classes.eventInfoContent)} shadowed>
        {!!event?.djs ? (
          <EventInfoRow icon={<Icon icon={IconmoonFont['headphones-24']} />}>
            <DjsPreview djs={event.djs} className={classes.pdfDjs} />
          </EventInfoRow>
        ) : null}
        <EventInfoRow icon={<Icon icon={IconmoonFont['calendar-24']} />}>
          <strong>{!!event?.date ? moment(event?.date).format('ddd, MMM Do YYYY') : 'TBD'}</strong>
        </EventInfoRow>
        <WithEventRole roles={[EventUserType.dj]} extraCondition={!!event?.arrivalTime}>
          <EventInfoRow icon={<Icon icon={IconmoonFont['djArrivalTime-24']} />}>
            <EventInfoTime name={t('djArrivalTime')}>{event?.arrivalTime}</EventInfoTime>
          </EventInfoRow>
        </WithEventRole>
        {!!event?.startTime ? (
          <EventInfoRow icon={<Icon icon={IconmoonFont['sandClockStart-24']} />}>
            <EventInfoTime name={t('startTime')}>{event?.startTime}</EventInfoTime>
          </EventInfoRow>
        ) : null}
        {!!event?.endTime ? (
          <EventInfoRow icon={<Icon icon={IconmoonFont['sandClockEnd-24']} />}>
            <EventInfoTime name={t('endTime')}>{event?.endTime}</EventInfoTime>
          </EventInfoRow>
        ) : null}
        {event?.location?.name ? (
          <EventInfoRow icon={<Icon icon={IconmoonFont['location-24']} />}>
            <strong>{event.location.name}</strong>
          </EventInfoRow>
        ) : null}
        <EventInfoRow icon={<EventTypeIcon type={event?.type as EventType} />}>
          <strong>{event?.type}</strong>
        </EventInfoRow>
      </ContentArea>
    </div>
  );
};

export default EventInfoContent;
