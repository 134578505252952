import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  deepLinkWizard: {
    height: '100%',

    '& > div, & > div > div, & .step': {
      height: '100%',
    },
  },
}));

export default useStyles;
