import React, { createContext, FC, useEffect, useState } from 'react';
import useKeyPress from 'react-use/lib/useKeyPress';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';

interface SelectListContextProps {
  ids: string[];
  selectedIds: string[];
  allSelected: boolean;
  lastTouchedId: Nullable<string>;
  setIds: (id: string[]) => void;
  setSelectedIds: (id: string[]) => void;
  toggleSelectId: (id: string) => void;
  toggleSelectAll: () => void;
  setLastTouchedId: (id: Nullable<string>) => void;
  setDisabledIds: (id: string[]) => void;
}

interface SelectListProviderProps {
  initialIds?: string[];
}

export const SelectListContext = createContext<SelectListContextProps>(undefined!);

export const SelectListProvider: FC<SelectListProviderProps> = ({ initialIds, children }) => {
  const [ids, setIds] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [disabledIds, setDisabledIds] = useState<string[]>([]);
  const [lastTouchedId, setLastTouchedId] = useState<Nullable<string>>(null);

  const shiftHeld = useKeyPress('Shift')[0];

  const allSelected = selectedIds.length === ids.length - disabledIds.length;
  const toggleSelectAll = () => {
    setSelectedIds(allSelected ? [] : filterDisabledIds(ids));
  };

  const filterDisabledIds = (idsOption: string[]) => {
    return idsOption.filter(id => !disabledIds?.includes(id));
  };

  const toggleSelectId = (id: string) => {
    if (shiftHeld) {
      const interval = getIdsInterval(id);
      const eachChecked = interval.every(intervalId => selectedIds.includes(intervalId));

      const updatedIds = eachChecked
        ? selectedIds.filter(selectedId => !interval.includes(selectedId))
        : uniq([...selectedIds, ...interval]);

      setSelectedIds(filterDisabledIds(updatedIds));
    } else {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter(idOption => idOption !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    }

    setLastTouchedId(id);
  };

  const getIdsInterval = (endId: string) => {
    const startIdx = lastTouchedId ? ids.indexOf(lastTouchedId) : 0;
    const lastIdx = ids.indexOf(endId);
    const asc = startIdx < lastIdx;

    const interval = asc
      ? [...ids].slice(startIdx, lastIdx + 1)
      : [...ids].slice(lastIdx, startIdx + 1);

    return interval;
  };

  useEffect(() => {
    if (!!initialIds && !isEqual(initialIds, ids)) {
      setIds(initialIds);
    }
  }, [initialIds]);

  useEffect(() => {
    if (selectedIds.some(selectedOption => !ids.includes(selectedOption))) {
      setSelectedIds(selectedIds.filter(selectedOption => ids.includes(selectedOption)));
    }
  }, [ids]);

  const handleUpdateDisabledIds = (idsOption: string[]) => {
    if (idsOption !== disabledIds) {
      setDisabledIds(idsOption);
    }
  };

  return (
    <SelectListContext.Provider
      value={{
        selectedIds,
        allSelected,
        lastTouchedId,
        ids,
        setIds,
        setSelectedIds,
        toggleSelectId,
        toggleSelectAll,
        setLastTouchedId,
        setDisabledIds: handleUpdateDisabledIds,
      }}
    >
      {children}
    </SelectListContext.Provider>
  );
};
