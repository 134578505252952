import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import TimelineSong from '../../TimelineSong';
import VirtualizedList from 'components/common/VirtualizedList';
import { TimelineSongsContext } from '../../TimelineSongsContext';

import { TIMELINE_SONG_ROW_LG, TIMELINE_SONG_ROW_SM } from '../constants';

import { SongsViewProps } from '../interfaces';

import useStyles from '../style';

const SongsGuestView: FC<SongsViewProps> = ({ className, handleHighlightSong }) => {
  const classes = useStyles();

  const { songsOptions } = useContext(TimelineSongsContext);

  return (
    <VirtualizedList<SectionSong>
      rowRenderer={(song, index) => (
        <TimelineSong
          onClick={({ viboSongId }) => handleHighlightSong(viboSongId)}
          className="timelineSong"
          song={song}
          index={index}
          key={`guest-song-${song._id}`}
        />
      )}
      className={classNames('songsList', className, classes.songsGuestView)}
      data={songsOptions.songsList}
      rowHeights={[TIMELINE_SONG_ROW_SM, TIMELINE_SONG_ROW_LG]}
      gaps={[0, 0]}
      scrollId="timeline-blocks"
      totalRowsCount={songsOptions.totalCount || 0}
    />
  );
};

export default SongsGuestView;
