import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Menu from 'vibo-ui/Menu';
import Header from 'components/common/Header';
import GoBack from 'components/buttons/GoBack';
import Container from 'components/common/Container';

import { TEMPLATE_PAGE_MENU_ITEMS } from 'services/constants';
import { getSelectedTemplateMenuKeys } from 'services/common/routesHelper';

import { TemplatePageHeaderProps } from './interfaces';

import useHeaderStyles from 'components/common/Header/style';

const TemplatePageHeader: FC<TemplatePageHeaderProps> = () => {
  const headerClasses = useHeaderStyles();
  const { t } = useTranslation();

  const { push } = useHistory();
  const match = useRouteMatch<{ id: string }>();

  return (
    <Header>
      <GoBack to="/templates/user" className={headerClasses.goBack} />
      <Container>
        <Menu
          getSelectedSidebarMenuKeys={getSelectedTemplateMenuKeys}
          styleModificator="siderTop"
          mode="horizontal"
        >
          {TEMPLATE_PAGE_MENU_ITEMS.map(({ name, route }) => {
            const link = `/template/${match.params.id}/${route}`;

            return (
              <Menu.Item onClick={() => push(link)} key={link}>
                {t(name)}
              </Menu.Item>
            );
          })}
        </Menu>
      </Container>
    </Header>
  );
};

export default TemplatePageHeader;
