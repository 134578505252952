import { useEffect, useState } from 'react';

import {
  COL_WIDTH,
  ROWS_GAP_LG,
  ROWS_GAP_SM,
  ROW_LG,
  ROW_SM,
} from '../../components/common/VirtualizedList/constants';
import { getScrollParent } from '../../services/common/domHelpers';
import { useScreen } from './common';

import { VirtualListBase } from '../../components/common/VirtualizedList/interfaces';

export const usePrapareData = <T extends {}>({
  scrollId,
  rowRenderer,
  data = [],
  rowHeights = [],
  columnWidths = [],
  gaps = [],
  columnsCount = [],
}: VirtualListBase<T>) => {
  const [scrollElement, setScrollElement] = useState<Maybe<Element>>();

  const { isLg } = useScreen();

  const heightSm = rowHeights[0] ?? ROW_SM;
  const heightLg = rowHeights[1] ?? ROW_LG;
  const colWidthSm = columnWidths[0] ?? COL_WIDTH;
  const colWidthLg = columnWidths[1] ?? COL_WIDTH;
  const gapSm = gaps[0] ?? ROWS_GAP_SM;
  const gapLg = gaps[1] ?? ROWS_GAP_LG;
  const gap = isLg && gapLg ? gapLg : gapSm;
  const colWidth = (isLg && colWidthLg ? colWidthLg : colWidthSm) + gap;
  const rowHeight = (isLg && heightLg ? heightLg : heightSm) + gap;
  const rowCount = data.length;
  const colsCount = isLg ? columnsCount[1] : columnsCount[0];

  const renderInnerItem = (index: number) => {
    if (!rowRenderer) return null;

    return rowRenderer(data[index], index);
  };

  const isRowLoaded = ({ index }: { index: number }) => !!data[index];

  const preparedContent = data.map((_, index: number) => renderInnerItem(index));

  useEffect(() => {
    if (!!scrollId) {
      const element = getScrollParent(scrollId);

      !scrollElement && setScrollElement(element as Element);
    }
  }, []);

  return {
    rowHeight,
    colWidth,
    scrollElement,
    preparedContent,
    rowCount,
    colsCount,
    isRowLoaded,
  };
};
