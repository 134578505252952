import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';

import Input from 'vibo-ui/Input';
import { UserPolicyFields } from 'components/context/PolicyContext';

import { useFieldPolicy } from 'graphql/hooks/policy';

type WebsiteProps = {
  handleUpdateProfile: (data: Partial<User>) => Promise<any>;
  user: Maybe<User>;
};

const urlValidator = Yup.string()
  .trim()
  .url('Please enter a valid URL');

const Website: React.FC<WebsiteProps> = ({ handleUpdateProfile, user }) => {
  const [site, setSite] = useState(user?.site);
  const [error, setError] = useState<string>('');

  const { isEditable } = useFieldPolicy(UserPolicyFields.site);

  const updateDebounced = useRef(
    debounce((url: string) => {
      handleUpdateProfile({ site: url });
    }, 1000)
  ).current;

  const updateWebsite = (url: string) => {
    urlValidator
      .validate(url)
      .then(() => {
        setError('');
        updateDebounced(url);
      })
      .catch((e: any) => setError(e.message));
    setSite(url);
  };

  return (
    <Input
      defaultValue={site}
      onChange={(_, value) => updateWebsite(value || '')}
      disabled={!isEditable}
      placeholder={'https://www.your_dj_website.com'}
      errors={[error]}
      withUnderline
    />
  );
};

export default Website;
