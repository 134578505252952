import React, { FC, useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { useFormikContext } from 'formik';

import Option from './Option';
import Radio, { RadioChangeEvent } from 'vibo-ui/Radio';
import { EditableContext } from '../../../common/EditableContext';
import { QuestionContext } from '../../../events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import useStyles from '../../common/style';

const RadioComponent: FC = () => {
  const classes = useStyles();

  const formik = useFormikContext<UpdateQuestionForm>();
  const values: UpdateQuestionForm = formik.values;

  const { canEdit } = useContext(EditableContext);
  const { question } = useContext(QuestionContext);

  const handleChange = ({ target }: RadioChangeEvent): void => {
    if (!isEqual(values.answer?.selectedOptions, [target.value])) {
      formik.setFieldValue('answer.selectedOptions', [target.value]);
    }
  };

  return (
    <>
      <Radio.Group
        value={values.answer?.selectedOptions?.[0]}
        onChange={handleChange}
        disabled={!canEdit}
        className={classes.multiplyGroup}
        vertical
      >
        {question.question.options?.map((option, idx) => (
          <Option index={idx} value={option} key={`question-radio-option-${idx}-${question._id}`} />
        ))}
      </Radio.Group>
    </>
  );
};

export default RadioComponent;
