import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  flag: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  unflagged: {
    color: theme.palette.text.disabled,
  },

  flagged: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;
