import React, { FC, useRef, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CopyButton from 'components/buttons/CopyButton';
import WithEventRole from 'components/user/WithEventRole';
import SongArtistNames from 'components/songs/SongArtistNames';
import SongItemActionsGroup from 'components/events/Timeline/TimelineSongsBlock/SongActionsGroup';
import CommentInput from 'components/events/Timeline/TimelineSongsBlock/SongActions/CommentInput';
import Flag from 'components/events/Timeline/TimelineSongsBlock/TimelineSongsHeader/Flag';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { TimelineSongsContext } from 'components/events/Timeline/TimelineSongsBlock/TimelineSongsContext';

import { getFormattedSongArtistAndTitle } from 'services/songs/helpers';

import { EventUserType } from 'types/enums';
import { SongModalHeaderProps } from './interfaces';

import useStyles from './style';

const SongModalHeader: FC<SongModalHeaderProps> = ({ song, variables }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const commentInputRef = useRef<HTMLInputElement>(null);

  const songsContext = useContext(TimelineSongsContext);

  return (
    <div className={classNames('timelineSongModalHeader', classes.timelineSongModalHeader)}>
      <div className={classes.firstRow}>
        <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
          <Flag song={song} className={classes.flagIcon} songIds={[song._id]} text="" />
        </WithEventRole>
        <SongArtistNames song={song} className={classes.songTitle} />
        <CopyButton text={getFormattedSongArtistAndTitle(song)} tooltipTitle={t('copyTitle')} />
        {!!songsContext?.sections ? (
          <SongItemActionsGroup
            song={song}
            sections={songsContext.sections as EventSection[]}
            variables={variables}
          />
        ) : null}
      </div>
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
        <div className={classes.secondRow}>
          <Icon icon={IconmoonFont['comment-16']} />
          <CommentInput
            inputRef={commentInputRef}
            song={song}
            key={`song-modalcomment-${song.viboSongId}-${song._id}`}
          />
        </div>
      </WithEventRole>
    </div>
  );
};

export default SongModalHeader;
