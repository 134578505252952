import * as Yup from 'yup';

import i18n from 'i18n';
import { MAX_TITLE_LENGTH } from 'services/constants';

export const SONG_HEIGHT = 56;

export const SONGS_GAP = 0;

export const updateSongIdeasValidationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(2, i18n.t('enterTitleWithLeastTwoCharacters'))
    .max(MAX_TITLE_LENGTH)
    .required('titleRequired'),
});
