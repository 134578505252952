import React, { FC } from 'react';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';

import { EmptyStateProps, DefaultEmptyStateProps } from './interfaces';

import useStyles from './style';

const EmptyState: FC<EmptyStateProps & DefaultEmptyStateProps> = ({
  icon,
  className,
  children,
  size = 'sm',
}) => {
  const classes = useStyles();

  return (
    <div className={classNames('emptyState', classes.emptyState, size, className)}>
      <Icon className="stateIcon" icon={icon} disableAutoFontSize />
      <div className="description">{children}</div>
    </div>
  );
};

export default EmptyState;
