import React, { FC, forwardRef, Ref } from 'react';
import classNames from 'classnames';

import { ContentAreaProps } from './interfaces';

import useStyles from './style';

const ContentArea: FC<ContentAreaProps> = forwardRef(
  ({ className, children, shadowed, ...rest }, ref: Ref<HTMLDivElement>) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        className={classNames('contentArea', classes.contentArea, className, {
          [`${classes.shadowed}`]: shadowed,
        })}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default ContentArea;
