import React, { FC, useContext } from 'react';

import NavSidebar from './NavSidebar';
import EventTitle from 'components/events/Timeline/EventTitle';
import PageContentHeader from 'components/common/PageContentHeader';
import { EventContext } from 'components/context/EventContext';

import useStyles from './style';

const PDFSettings: FC = () => {
  const classes = useStyles();

  const { event } = useContext(EventContext);

  return (
    <div className={classes.pdfSettings}>
      <PageContentHeader>
        <EventTitle name={event?.title} />
      </PageContentHeader>
      <NavSidebar />
    </div>
  );
};

export default PDFSettings;
