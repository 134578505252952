import React, { FC } from 'react';

import MyInfo from '../../../components/user/Profile/MyInfo';
import ProfileLayout from '../ProfileLayout';
import ChildEditingStatusBar from '../../../components/common/ChildEditingStatusBar';
import { UserContext } from '../UserContext';

import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_LOGO,
} from '../../../graphql/mutations/user';
import { GET_ME } from '../../../graphql/queries/user';

import { useMe } from '../../../graphql/hooks/user';

import { Page } from '../../../types/enums';

const Me: FC = () => {
  const { user } = useMe();

  return (
    <UserContext.Provider value={{ user }}>
      <ProfileLayout page={Page.profileInfo}>
        <MyInfo
          user={user}
          userQueryKey={'me'}
          userQuery={GET_ME}
          mutations={{
            updateProfile: { mutation: UPDATE_PROFILE, key: 'updateProfile' },
            updateProfileImage: {
              mutation: UPDATE_PROFILE_IMAGE,
              key: 'updateProfile',
            },
            updateProfileLogo: {
              mutation: UPDATE_PROFILE_LOGO,
              key: 'updateProfile',
            },
          }}
        />
      </ProfileLayout>
      <ChildEditingStatusBar />
    </UserContext.Provider>
  );
};

export default Me;
