import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import BlockInfo from './BlockInfo';
import { TimelineSectionContext } from '../TimelineSectionContext';

import { strippString } from '../../../../services/common/stringHelpers';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SectionBlockName } from '../../../../types/enums';

const NotesBlockInfo: FC = () => {
  const { t } = useTranslation();

  const { section } = useContext(TimelineSectionContext);

  const notesContent = useMemo(() => !!strippString(section.note), [section.note]);

  return (
    <BlockInfo
      className={SectionBlockName.notes}
      icon={IconmoonFont[!!strippString(section.note) ? 'notes-16' : 'emptyNotes-16']}
      description={t(notesContent ? 'sectionWithNotes' : 'noNotesYet')}
    />
  );
};

export default NotesBlockInfo;
