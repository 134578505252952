import { PREP_MODE_ACCORDION, SCANNER_PROTOCOL } from '../constants';

import { PrepModeSongExpansion, ScannerFileAction } from '../../types/enums';

export const getM3UDataFromSectionPlaylistResponse = (playlist: SectionPlaylistResponse): Blob => {
  const m3uFileContent = playlist.files.reduce((result, file) => `${result}\r\n${file}`);

  return new Blob([m3uFileContent], { type: 'application/mpegurl' });
};

export const getScannerFileActionProtocol = (filePath: string, action: ScannerFileAction): string =>
  `${SCANNER_PROTOCOL}://#file_path=${filePath}&action=${action}`;

export const getOpenScannerProtocol = () => `${SCANNER_PROTOCOL}://`;

export const getPrepModeSongAccordionHeight = (
  numVisibleFiles: number,
  cardExpansion: PrepModeSongExpansion
): number => {
  const songFilesHeight: number = getPrepModeSongFilesHeight(numVisibleFiles);

  if (cardExpansion === PrepModeSongExpansion.less) {
    if (numVisibleFiles === 1) {
      return PREP_MODE_ACCORDION.FILE_ITEM_HEIGHT + PREP_MODE_ACCORDION.FILE_ITEM_MARGIN_TOP;
    }
    return songFilesHeight;
  }

  return songFilesHeight + PREP_MODE_ACCORDION.FOOTER_HEIGHT;
};

const getPrepModeSongFilesHeight = (numVisibleFiles: number): number =>
  !!numVisibleFiles
    ? (PREP_MODE_ACCORDION.FILE_ITEM_HEIGHT + PREP_MODE_ACCORDION.FILE_ITEM_MARGIN_TOP) *
      numVisibleFiles
    : PREP_MODE_ACCORDION.NO_MATCHES_TEXT_HEIGHT + PREP_MODE_ACCORDION.NO_MATCHES_TEXT_MARGIN_TOP;

export const hasPlayCount = (file: SongFile): boolean =>
  !!(
    file.seratoPlayCount ||
    file.djayProPlayCount ||
    file.traktorPlayCount ||
    file.virtualDJPlayCount ||
    file.rekordboxPlayCount ||
    file.enginePrimePlayCount
  );

export const getTotalSelectedFiles = (sectionIds: string[], sections: PrepModeSection[]): number =>
  sections.reduce((totalSelectedFiles: number, section: PrepModeSection) => {
    return sectionIds.includes(section._id)
      ? totalSelectedFiles + section.selectedFilesCount
      : totalSelectedFiles;
  }, 0);
