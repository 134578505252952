import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  preview: {
    padding: '12px',
    color: theme.palette.text.primary,

    '& p, & h1, & h2': {
      margin: '0',
    },

    '& .ql-editor': {
      padding: '0',
      fontFamily: 'Roboto, sans-serif',

      '& a': {
        color: theme.palette.primary.main,
      },

      '& li': {
        padding: '0!important',
      },
    },

    '&.placeholder': {
      color: theme.palette.text.hint,
    },
  },
}));

export default useStyles;
