import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon';

import { FILE_PLAY_STATS } from 'services/constants';

import { FilePlayCountListProps } from './interfaces';

import useStyles from './style';

const FilePlayStats: FC<FilePlayCountListProps> = ({ file }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.filePlayStats}>
      <span className={classes.header}>{t('played')}:</span>
      {FILE_PLAY_STATS.map(playStat => {
        const count = file?.[playStat.field];

        return !!count ? (
          <div className={classes.stat} key={playStat.field}>
            <Icon icon={playStat.Icon} />
            <span>
              {count} {t('time', { count })}
            </span>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default FilePlayStats;
