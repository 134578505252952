import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';

import Tooltip from 'vibo-ui/Tooltip';
import FileDetails from './FileDetails';
import Checkbox from 'vibo-ui/Checkbox';
import FilePlayStats from './FilePlayStats';
import ProgressBar from 'components/common/ProgressBar';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { useModal } from 'vibo-ui/Modal';
import { SCREEN_SIZE } from 'services/constants';
import { getScannerFileActionProtocol, hasPlayCount } from 'services/prepMode/helpers';

import { Modals, ScannerFileAction } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { PrepModeSongFileProps } from './interfaces';

import useIconButtonStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const PrepModeSongFile: FC<PrepModeSongFileProps> = ({ file, onToggleSelect, onRemoveFile }) => {
  const iconButtonClasses = useIconButtonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const { width } = useWindowSize();

  const { openModal } = useModal();

  const openConfirmRemoveFileModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('markWrongMatch'),
          children: t('thisMatchNotShowForThisSong'),
          submit: {
            text: t('confirm'),
            onClick: handleRemoveFile,
          },
        },
      }),
    []
  );

  const handleFileActionWithScanner = (filePath: string, action: ScannerFileAction) => {
    window.open(getScannerFileActionProtocol(filePath, action));
  };

  const handleRemoveFile = useCallback(() => {
    onRemoveFile(file.fileId);
  }, [file.fileId]);

  return (
    <div className="prepModeFile" key={file.fileId}>
      <div className={classes.fileLeft}>
        <div
          className={classNames('songWrapper', classes.songWrapper)}
          onClick={e => e.stopPropagation()}
        >
          <Checkbox
            onChange={e => {
              onToggleSelect(file.fileId, e);
            }}
            checked={file.isSelected}
          />
          <Tooltip
            overlay={t('matchingScoreAmount', { score: file?.score })}
            align={{ offset: [-26, 0] }}
            placement="left"
            type="primary"
          >
            <div onClick={e => e.preventDefault()}>
              <ProgressBar completed={file?.score} />
            </div>
          </Tooltip>
          <Tooltip
            overlay={<FileDetails file={file} />}
            align={{ offset: [width > SCREEN_SIZE.lg ? -70 : -56, 0] }}
            placement="left"
            type="primary"
          >
            <Icon
              className={iconButtonClasses.highlighted}
              icon={IconmoonFont['infoRotatedOutlined-16']}
            />
          </Tooltip>
          {!!file.selectedCount ? (
            <Tooltip
              overlay={t('selectedAmountTime', {
                count: file.selectedCount,
              })}
              align={{ offset: [width > SCREEN_SIZE.lg ? -92 : -78, 0] }}
              placement="left"
              type="primary"
            >
              <Icon
                className={iconButtonClasses.highlighted}
                onClick={e => e.preventDefault()}
                icon={IconmoonFont['checkedCalendar-16']}
              />
            </Tooltip>
          ) : null}
          <span className={classNames('fileName', classes.fileName)}>{file.revisedFileName}</span>
        </div>
      </div>
      <div
        onClick={e => e.stopPropagation()}
        className={classNames('fileRight', classes.fileRight)}
      >
        {hasPlayCount(file) ? (
          <Tooltip
            overlay={<FilePlayStats file={file} />}
            align={{ offset: [width > SCREEN_SIZE.lg ? -700 : -446, 0] }}
            placement="left"
            type="primary"
          >
            <Icon
              className={iconButtonClasses.highlighted}
              onClick={e => e.preventDefault()}
              icon={IconmoonFont['m3u-16']}
            />
          </Tooltip>
        ) : null}
        <Tooltip
          overlay={t('playSong')}
          align={{ offset: [width > SCREEN_SIZE.lg ? -722 : -468, 0] }}
          placement="left"
          type="primary"
          fillOverlay
        >
          <Icon
            className={iconButtonClasses.highlighted}
            onClick={e => {
              e.stopPropagation();
              handleFileActionWithScanner(file.filePath, ScannerFileAction.play);
            }}
            icon={IconmoonFont['play-16']}
          />
        </Tooltip>
        <Tooltip
          overlay={t('openFile')}
          align={{ offset: [width > SCREEN_SIZE.lg ? -745 : -491, 0] }}
          placement="left"
          type="primary"
          fillOverlay
        >
          <Icon
            className={iconButtonClasses.highlighted}
            onClick={e => {
              e.stopPropagation();
              handleFileActionWithScanner(file.filePath, ScannerFileAction.locate);
            }}
            icon={IconmoonFont['folder-16']}
          />
        </Tooltip>
        <Tooltip
          overlay={t('markWrongMatch')}
          align={{ offset: [width > SCREEN_SIZE.lg ? -770 : -512, 0] }}
          placement="left"
          type="primary"
          fillOverlay
        >
          <Icon
            className={iconButtonClasses.highlighted}
            onClick={e => {
              e.stopPropagation();
              openConfirmRemoveFileModal();
            }}
            icon={IconmoonFont['close-16']}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default PrepModeSongFile;
