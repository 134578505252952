import get from 'lodash/get';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { GraphQLError } from 'graphql';

import { TGaSendCommonError, TGaSendLoginData, TGaSendPageView } from './interfaces';

export const gaSendGqlError = (error?: GraphQLError) => {
  if (!!ReactGA && !!error) {
    const code = get(error, 'code');
    const description = get(error, 'message');

    gaSendCommonError({ code, description });
  }
};

export const gaSendCommonError = ({ code, description }: TGaSendCommonError) => {
  ReactGA.set({ customError: description });
  ReactGA.event('error_', {
    description: `${code} - ${window?.location.pathname}`,
    customError: description,
  });
};

export const gaSendPageView = ({ pageName, pagePath }: TGaSendPageView) => {
  ReactGA.set({ customPage: pageName });
  ReactGA.send({
    hitType: 'pageview',
    fullPath: pagePath,
    customPage: pageName,
  });
};

export const gaSendLoginData = ({ user, source }: TGaSendLoginData) => {
  ReactGA.set({ role: user.role });
  ReactGA.set({ source });
  ReactGA.event('login_', {
    email: user.email,
    role: user.role,
    source,
  });
};

export const gaSetUserId = (userId: string) =>
  ReactGA.set({
    userId,
  });

export const gaInit = (gaId: string) => {
  ReactGA.initialize(gaId);
};

export const gtmInit = (gtmId: string) => {
  TagManager.initialize({
    gtmId,
  });
};

export const gtmTriggerClick: (gtmClickTarget: string, event?: string) => void = (
  gtmClickTarget,
  event = 'cta_click'
) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      gtmClickTarget,
    },
  });
};
