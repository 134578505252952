import React, { useState, useCallback } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import update from 'immutability-helper';
import { Container, Draggable, DropResult, DragEndCallback } from 'react-smooth-dnd';

import Spinner from 'vibo-ui/Spinner';

import { ListProps, ListLayout } from './interfaces';

import useStyles from './style';

const List = <T extends {}>({
  renderItem,
  onDropCallback,
  onDragStartCallback,
  children,
  loading,
  className,
  lockAxis,
  draggableItemClassName,
  dragHandleSelector,
  dragClass,
  dropPlaceholder,
  enableDragCursor = true,
  draggable = false,
  layout = ListLayout.vertical,
  data = [],
  ...rest
}: ListProps<T>) => {
  const classes = useStyles();

  const [activeElementIdx, setActiveElementIdx] = useState<number | undefined>();

  const onDrop: (dropResult: DropResult) => void = useCallback(
    ({ removedIndex, addedIndex }) => {
      if (isNumber(removedIndex) && isNumber(addedIndex)) {
        const draggedOption = data[removedIndex];
        const newData = update(data, {
          $splice: [
            [removedIndex, 1],
            [addedIndex, 0, draggedOption],
          ],
        });

        onDropCallback?.(newData, { removedIndex, addedIndex });
      }
    },
    [data]
  );

  const handleDragEnd: DragEndCallback = useCallback(() => {
    setActiveElementIdx(undefined);
  }, []);

  const renderInnerItem = (item: T, index: number) => {
    if (!renderItem) return null;

    const itemToRender = renderItem(item, index, {
      idx: activeElementIdx,
      element: activeElementIdx ? data[activeElementIdx] : undefined,
      isDragging: activeElementIdx === index,
    });

    return itemToRender;
  };

  const preparedContent = data.map((item: T, index: number) => renderInnerItem(item, index));

  return (
    <div
      className={classNames(
        'viboList',
        { [`${layout}Layout`]: layout },
        classes.viboList,
        className
      )}
      {...rest}
    >
      {draggable ? (
        <Container
          getChildPayload={activeIndex => {
            setActiveElementIdx(activeIndex);
            return data[activeIndex];
          }}
          onDragEnd={handleDragEnd}
          onDragStart={onDragStartCallback}
          onDrop={onDrop}
          orientation={layout}
          lockAxis={lockAxis}
          dragHandleSelector={dragHandleSelector}
          dropPlaceholder={dropPlaceholder}
          dragClass={dragClass}
        >
          {data.map((option, idx) => {
            const itemId = get(option, '_id', '');

            return (
              <Draggable
                className={classNames('draggableItemClassName', draggableItemClassName, {
                  draggableItem: enableDragCursor && !dragHandleSelector,
                })}
                key={`dndListItem_${idx}_${itemId}`}
              >
                {preparedContent[idx]}
              </Draggable>
            );
          })}
        </Container>
      ) : (
        preparedContent
      )}
      {loading ? <Spinner className={classes.spinner} /> : null}
    </div>
  );
};

export default List;
