import { createContext } from 'react';
import {
  SectionSongIdeasListQueries,
  SectionSongIdeasMutations,
} from '../../../../../types/graphql';
import { ControlProps } from '../../../../sections/blocks/SectionBlock/interfaces';

type TTimelineSongsContext = {
  songsOptions: UseSongsReturn;
  variables: SectionSongsVariables;
  listQueries: SectionSongIdeasListQueries;
  mutations: SectionSongIdeasMutations;
  refetchSongs: Function;
  section: TimelineSection;
  sections: TimelineSection[];
  controlRef?: Nullable<ControlProps>;
};

export const TimelineSongsContext = createContext<TTimelineSongsContext>(undefined!);
