import React, { FC, useContext } from 'react';

import Switch from 'vibo-ui/Switch';
import { SpotifyAuthContext } from '../../../context/SpotifyAuthContext';

const SpotifySwitcher: FC = () => {
  const { isSpotifyConnected, spotifyLoading, connectSpotify, disconnectSpotify } = useContext(
    SpotifyAuthContext
  );

  return (
    <Switch
      onChange={() => (isSpotifyConnected ? disconnectSpotify() : connectSpotify())}
      checked={isSpotifyConnected}
      disabled={spotifyLoading}
    />
  );
};

export default SpotifySwitcher;
