import React, { FC, useState, useEffect } from 'react';
import { useQueryParam } from 'use-query-params';

import { SortContextProvider } from '../../../components/context/SortContext';
import Container from '../../../components/common/Container';
import { EventsFilterableContext } from '../../../components/events/common/EventsListContext';
import EventsPageHeader from '../../../components/headers/EventsPageHeader';

import { useMe } from '../../../graphql/hooks/user';

import useStyles from './style';

const EventsLayout: FC = ({ children }) => {
  const classes = useStyles();

  const { isDj, isAdmin } = useMe();

  const [q = '', setQ] = useQueryParam<string | undefined>('q');

  const [filter, setFilter] = useState<EventsFilter>({
    q,
  });

  useEffect(() => {
    setFilter(prev => ({ ...prev, q }));
  }, [q]);

  useEffect(() => {
    setQ(undefined);

    return () => setQ(undefined);
  }, []);

  useEffect(() => {
    setFilter({
      q,
    });
  }, [isDj, isAdmin]);

  return (
    <div className={classes.eventsLayout}>
      <EventsFilterableContext.Provider value={{ filter, setFilter }}>
        <SortContextProvider>
          <EventsPageHeader />
          <Container className={classes.container}>{children}</Container>
        </SortContextProvider>
      </EventsFilterableContext.Provider>
    </div>
  );
};

export default EventsLayout;
