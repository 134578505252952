import React, { FC } from 'react';
import classNames from 'classnames';
import linkifyHtml from 'linkifyjs/html';

import { strippString } from '../../../../services/common/stringHelpers';
import { options } from '../../Linkify/constants';

import { QlPreviewProps } from './interfaces';

import useStyles from './style';

const QlPreview: FC<QlPreviewProps> = ({ onClick, className, text = '', placeholder = '' }) => {
  const classes = useStyles();

  const isQlEmpty = !strippString(text);

  const handleClick = () => onClick?.();

  return (
    <div
      className={classNames('preview ql-snow ', classes.preview, className, {
        placeholder: isQlEmpty,
      })}
      onClick={handleClick}
    >
      <span
        className="ql-container ql-editor"
        dangerouslySetInnerHTML={{
          __html: `${!isQlEmpty && text ? linkifyHtml(text, options) : placeholder}`,
        }}
      />
    </div>
  );
};

export default QlPreview;
