import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { isNull } from 'lodash';

const Portal: React.FC = ({ children }) => {
  const portalRoot: Nullable<HTMLElement> = document.getElementById('portal');
  const el: HTMLDivElement = document.createElement('div');

  useEffect(() => {
    if (!isNull(portalRoot)) {
      portalRoot.appendChild(el);

      return () => {
        portalRoot.removeChild(el);
      };
    }
  }, [el, portalRoot]);

  return createPortal(children, el);
};

export default Portal;
