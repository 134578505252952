import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import AddPeopleButton from '../../../components/events/buttons/AddPeopleButton';
import NoUsers from '../../../components/emptyStates/NoUsers';

const NoUsersContent: FC = () => {
  const { t } = useTranslation();

  return (
    <NoUsers>
      {t('noPeopleYet')}
      <AddPeopleButton />
    </NoUsers>
  );
};
export default memo(NoUsersContent);
