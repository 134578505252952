import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getDateAsTimeFromNow } from 'services/common/dateHelpers';

import { ComputerDetailsProps } from './interfaces';

import useStyles from './style';

const Device: FC<ComputerDetailsProps> = ({ computer }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const lastScanned = useMemo(() => getDateAsTimeFromNow(computer.lastScanDate), [
    computer.lastScanDate,
  ]);

  return (
    <div className={classNames('device')}>
      <div className={classes.deviceName}>{computer.name}</div>
      <div className={classes.deviceDetails}>
        {t('lastScanVersion', {
          lastScanned,
          version: computer.version,
        })}
      </div>
    </div>
  );
};

export default Device;
