import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  headlineLabel: {
    height: '20px',
    backgroundColor: theme.palette.background.paper,

    '@media screen and (min-width: 1441px)': {
      height: '24px',
    },
  },
}));

export default useStyles;
