import React, { FC } from 'react';

import Container from 'components/common/Container';
import ProfileHeader from 'components/headers/ProfileHeader';

import { ProfileLayoutProps } from './interfaces';

import useStyles from './style';

const ProfileLayout: FC<ProfileLayoutProps> = ({ page, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.accountLayout}>
      <ProfileHeader page={page} />
      <Container className={classes.container}>{children}</Container>
    </div>
  );
};

export default ProfileLayout;
