import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  createButton: {
    position: 'relative',
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
