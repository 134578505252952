import React, { FC, useContext } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon';
import ContentArea from 'components/common/ContentArea';
import { ScrollSpyContext } from 'components/context/ScrollSpyContext';

import { PDF_OPTIONS_TEXT } from 'services/constants';
import { scrollToElSmooth } from 'services/common/domHelpers';

import { SettingOptionProps } from './interfaces';

import useStyles from './style';

const SettingOption: FC<SettingOptionProps> = ({ optionKey, inputName, icon }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { activeId } = useContext(ScrollSpyContext);

  const { values } = useFormikContext<CreateEventFileWebForm>();

  const handleClick = () => {
    scrollToElSmooth('pdf-blocks-scroll', optionKey);
  };

  const isSelected = !!get(values, inputName);

  return (
    <ContentArea
      onClick={handleClick}
      className={classNames('settingsOption', classes.settingsOption, {
        active: activeId === optionKey,
      })}
      shadowed
    >
      <Icon icon={icon} />
      {`${t(PDF_OPTIONS_TEXT[optionKey])} ${t(isSelected ? 'on' : 'off')}`}
    </ContentArea>
  );
};

export default SettingOption;
