import React, { FC } from 'react';
import classNames from 'classnames';

import { EventInfoTimeProps } from '../interfaces';

import useStyles from '../style';

const EventInfoTime: FC<EventInfoTimeProps> = ({ name, children }) => {
  const classes = useStyles();

  return (
    <div className={classNames('eventInfoTime', classes.eventInfoTime)}>
      <span className="name">{name}:</span>
      <strong>{children}</strong>
    </div>
  );
};

export default EventInfoTime;
