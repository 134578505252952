import React from 'react';
import classNames from 'classnames';

import useStyles from './style';

interface EventTitleProps {
  className?: string;
  name?: string;
}

const EventTitle: React.FC<EventTitleProps> = ({ className, name = '' }) => {
  const classes = useStyles();

  return <h3 className={classNames('eventTitle', classes.title, className)}>{name}</h3>;
};

export default EventTitle;
