import React, { FC, useRef } from 'react';
import classNames from 'classnames';

import { SectionBlockHeaderProps } from './interfaces';

import useStyles from './style';

const SectionBlockHeader: FC<SectionBlockHeaderProps> = ({ handleClick, children }) => {
  const classes = useStyles();

  const headerRef = useRef<Nullable<HTMLDivElement>>(null);

  return (
    <div
      ref={headerRef}
      className={classNames('sectionBlockHeader', classes.sectionBlockHeader)}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default SectionBlockHeader;
