import { Quill } from 'react-quill';
import { ToolbarButton, ListType } from './interfaces';
import { tollbarSvgIcons } from './constants';

const icons = Quill.import('ui/icons');

icons[ToolbarButton.link] = tollbarSvgIcons[ToolbarButton.link];
icons[ToolbarButton.paragraph] = tollbarSvgIcons[ToolbarButton.paragraph];
icons[ToolbarButton.italic] = tollbarSvgIcons[ToolbarButton.italic];
icons[ToolbarButton.undo] = tollbarSvgIcons[ToolbarButton.undo];
icons[ToolbarButton.redo] = tollbarSvgIcons[ToolbarButton.redo];
icons[ToolbarButton.underline] = tollbarSvgIcons[ToolbarButton.underline];
icons[ToolbarButton.clean] = tollbarSvgIcons[ToolbarButton.clean];
icons[ToolbarButton.header][1] = tollbarSvgIcons[ToolbarButton.header][1];
icons[ToolbarButton.header][2] = tollbarSvgIcons[ToolbarButton.header][2];
icons[ToolbarButton.header][ListType.bullet] = tollbarSvgIcons[ToolbarButton.list][ListType.bullet];
icons[ToolbarButton.header][ListType.ordered] =
  tollbarSvgIcons[ToolbarButton.list][ListType.ordered];
