import { useLocation, useParams, useHistory } from 'react-router';
import get from 'lodash/get';

type TGoBackParams = TParentLocation & {
  state: TCurrentParams;
};

type TUseParentPageReturn = {
  getGoBackParams: (fallbackUrl: string) => TGoBackParams;
  goForward: (url: string) => void;
};

export type TParentLocation = {
  pathname?: string;
  search?: string;
};

type TCurrentParams = {
  id?: string;
};

export const useParentPage = (): TUseParentPageReturn => {
  const history = useHistory();
  const location = useLocation<TParentLocation>();
  const { id } = useParams<TCurrentParams>();

  return {
    getGoBackParams: (fallbackUrl: string): TGoBackParams => {
      const pathname: Maybe<string> = get(location.state, 'pathname', fallbackUrl);
      const search: Maybe<string> = get(location.state, 'search');

      return {
        pathname,
        search,
        state: {
          id,
        },
      };
    },
    goForward: (url: string) =>
      history.push(url, {
        pathname: location.pathname,
        search: location.search,
      }),
  };
};
