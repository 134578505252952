import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Action from '../Action';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';
import { TimelineSongsContext } from '../../TimelineSongsContext';

import { useToggleableUpdateFieldSong } from 'graphql/hooks/blocks';

import { IconmoonFont } from 'vibo-ui/Icon';
import { ToggleableSongUpdateField } from 'types/enums';
import { FlagProps } from './interfaces';

import useStyles from './style';

const Flag: FC<FlagProps> = ({ song, songIds, className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { songsOptions } = useContext(TimelineSongsContext);

  const { updateField } = useToggleableUpdateFieldSong({
    selectedIds: songIds,
    field: ToggleableSongUpdateField.isFlagged,
    songs: songsOptions.songsList,
  });

  return (
    <StopPropagationWrapper className="confirmActionButton">
      <Action
        onClick={() => updateField()}
        songIds={songIds}
        icon={IconmoonFont[song?.isFlagged ? 'flagFilled-16' : 'flagOutlined-16']}
        className={classNames(
          'flag',
          classes.flag,
          song?.isFlagged ? classes.flagged : `unflagged ${classes.unflagged}`,
          className
        )}
        text={t('flag')}
        {...rest}
      />
    </StopPropagationWrapper>
  );
};

export default Flag;
