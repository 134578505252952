import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  signUpWizard: {
    height: '100%',

    '& .step': {
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },

    '& .stepInfo': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0 0 auto 0',
      height: '100%',

      '& h3, .description': {
        fontWeight: 400,
        textAlign: 'center',
      },

      '& h3': {
        fontSize: '15px',
        lineHeight: '22.5px',
        margin: '0',

        '@media screen and (min-width: 1441px)': {
          fontSize: '18px',
          lineHeight: '27px',
        },
      },

      '& .description': {
        fontSize: '17px',
        lineHeight: '24.09px',
        color: colors.gray,
        margin: '105px 0 auto 0',

        '@media screen and (min-width: 1441px)': {
          fontSize: '20px',
          lineHeight: '28.34px',
          margin: '122px 0 auto 0',
        },
      },
    },

    '& .checkEmail': {
      '& .viboLogo': {
        color: theme.palette.primary.main,
        width: '96.6px',
        height: '37px',

        '@media screen and (min-width: 1441px)': {
          width: '125.3px',
          height: '48px',
        },
      },

      '& .appleLoginButton': {
        margin: '6px 0 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '8px 0 0 0',
        },
      },
    },

    '& .signIn': {
      '& .forgot-password-link': {
        margin: '0 0 0 auto',
        padding: '0',
      },
    },

    '& .createPassword': {
      '& .passwordPolicyInfo': {
        fontSize: '9px',
        lineHeight: '12.6px',
        margin: '0 0 10px 0',

        '@media screen and (min-width: 1441px)': {
          fontSize: '12px',
          lineHeight: '16.8px',
        },
      },
    },

    '& .createPassword, & .userName, & .addPicture': {
      position: 'relative',

      '& .wizardStepsIndicator': {
        position: 'absolute',
        top: '65px',

        '@media screen and (min-width: 1441px)': {
          top: '80px',
        },
      },
    },

    '& .userName': {
      '& .viboInputWrapper:not(:last-child)': {
        margin: '0 0 30px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 36px 0',
        },
      },
    },

    '& .addPicture': {
      '& .stepInfo': {
        '& .description': {
          margin: '80px 0 auto 0',

          '@media screen and (min-width: 1441px)': {
            margin: '100px 0 auto 0',
          },
        },
      },

      '& .formSwitch': {
        margin: '10px 0 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '20px 0 0 0',
        },
      },

      '& .viboImage ': {
        margin: '0 auto',
        color: theme.palette.primary.main,

        '& .gradient': {
          backgroundImage: 'none',
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',

          '& .viboIcon': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },

  signUpLayout: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    '& .error': {
      bottom: '-4px',
      transform: 'translateY(100%)',
    },

    '& h3': {
      width: '100%',
      textAlign: 'center',
      margin: '0 0 90px 0',
      fontSize: '15px',
      lineHeight: '22.5px',
      fontWeight: 400,

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 100px 0',
        fontSize: '18px',
        lineHeight: '27px',
      },
    },

    '& .description': {
      width: '100%',
      textAlign: 'center',
      fontSize: '17px',
      lineHeight: '24.09px',
      opacity: '0.7',

      '@media screen and (min-width: 1441px)': {
        fontSize: '20px',
        lineHeight: '28.34px',
      },
    },
  },

  stepIndicator: {
    left: '0',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    top: '95px',
    width: '100%',

    '& .viboIcon': {
      color: theme.palette.text.primary,
      opacity: '0.3',
      fontSize: '14px',

      '&:not(:last-child)': {
        margin: '0 9px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 8px 0 0',
        },
      },

      '&.active': {
        color: theme.palette.primary.main,
        opacity: '1',
        fontSize: '20px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '24px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      top: '90px',
    },
  },

  timer: {
    justifyContent: 'center',
    fontSize: '13px',
    lineHeight: '19.5px',
    margin: '0 0 16px 0',

    '& label': {
      color: theme.palette.text.secondary,
    },

    '& .time': {
      color: theme.palette.primary.main,
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0 0 24px 0',
    },
  },

  photoPlaceholder: {
    cursor: 'pointer',
    borderRadius: '4px',
    overflow: 'hidden',
    width: '124px',
    height: '124px',
    margin: '0 auto 9px auto',
    color: colors.white,

    '& .gradient': {
      background: theme.palette.background.photo,
    },

    '&.withImage': {
      backgroundColor: 'transparent',
    },

    '& svg': {
      width: '20px',
      height: '20px',

      '@media screen and (min-width: 1441px)': {
        width: '24px',
        height: '24px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '166px',
      height: '166px',
      margin: '0 auto 25px auto',
    },
  },

  addPhoto: {
    '& .switchWithTooltip': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .label': {
        fontSize: '13px',
        lineHeight: '19.56px',
        margin: '0 4px 0 0',

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },

    '& .selectImage': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0',
      height: '100%',
      width: '100%',
    },
  },

  confirmCodeContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  nextBtn: {
    width: '100%',
    margin: '30px 0 0 0',

    '@media screen and (min-width: 1441px)': {
      margin: '36px 0 0 0',
    },
  },

  finalGuideControls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '45px 0 0 0',

    '& .stepIndicator': {
      position: 'relative',
      top: '0',
      left: '0',
    },

    '& .viboButton': {
      fontSize: '13px',
      lineHeight: '15.23px',

      '&.hiddenBtn': {
        opacity: 0,
        pointerEvents: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '18.75px',
      },
    },

    '& .next': {
      fontWeight: 700,
    },

    '@media screen and (min-width: 1441px)': {
      margin: '30px 0 0 0',
    },
  },

  guideTemplateTitle: {
    color: `${theme.palette.primary.main}!important`,
    margin: '0 0 10px 0!important',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 13px 0!important',
    },
  },

  guideTemplateDesc: {
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '19.5px',
    maxWidth: '190px',
    margin: '0 auto 16px auto',
    color: theme.palette.text.secondary,

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
      maxWidth: '215px',
      margin: '0 auto 20px auto',
    },
  },

  guideTemplateIcon: {
    margin: '0 auto',
    width: '169px',
    height: '218px',

    '@media screen and (min-width: 1441px)': {
      width: '231px',
      height: '298px',
    },
  },
}));

export default useStyles;
