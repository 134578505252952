import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  prepModeMeidaLinks: {
    margin: '0 0 0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 5px 0 0',

    '& .songLinkMenuItem': {
      '&:not(:last-child)': {
        margin: '0 8px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 12px 0 0',
        },
      },
    },
  },

  footerLeft: {
    display: 'flex',
  },

  expandCollapseBtn: {
    maxWidth: '90px',
    padding: 0,
    marginRight: '36px',

    '& svg': {
      position: 'relative',
    },
  },

  songLinkMenuItem: {
    display: 'flex',

    '& svg': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },
  },
}));

export default useStyles;
