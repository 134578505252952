import React, { FC, useContext, useMemo } from 'react';
import uniq from 'lodash/uniq';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useFormikContext } from 'formik';

import Option from './Option';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { RadioChangeEventTarget } from 'vibo-ui/Radio';

import useStyles from 'components/questions/common/style';

const CheckboxType: FC = () => {
  const classes = useStyles();

  const formik = useFormikContext<UpdateQuestionForm>();

  const { question } = useContext(QuestionContext);

  const selectedOptions = useMemo(() => formik.values.answer?.selectedOptions || [], [
    formik.values.answer?.selectedOptions,
  ]);

  const handleChange = debounce((target: RadioChangeEventTarget) => {
    if (target.checked) {
      formik.setFieldValue('answer.selectedOptions', uniq([...selectedOptions, target.value]));
    } else {
      const newSelected = selectedOptions.filter(item => item !== target.value);

      formik.setFieldValue('answer.selectedOptions', uniq(newSelected));
    }
  }, 100);

  return (
    <div className={classNames(classes.multiplyGroup, 'checkboxess')}>
      {question.question.options?.map((option, idx) => (
        <Option
          index={idx}
          handleChange={handleChange}
          value={option}
          key={`question-checkbox-type-${idx}`}
        />
      ))}
    </div>
  );
};

export default CheckboxType;
