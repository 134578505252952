import React, { FC, useState, useContext, memo } from 'react';
import { useTranslation } from 'react-i18next';

import SongsDjView from './views/SongsDjView';
import SongModal from 'components/modals/SongModal';
import SongsGuestView from './views/SongsGuestView';
import WithEventRole from 'components/user/WithEventRole';
import NoSongsFound from 'components/emptyStates/NoSongsFound';

import { TimelineSongsContext } from '../TimelineSongsContext';

import { GET_EVENT_SECTION_SONGS } from 'graphql/queries/songs';

import { EventUserType } from 'types/enums';
import { SongsListProps } from './interfaces';

const SongsList: FC<SongsListProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  const [openedSong, setOpenedSong] = useState<Nullable<string>>(null);
  const [highlightedSong, setHighlightedSong] = useState<Maybe<string>>('');

  const { songsOptions, variables, section } = useContext(TimelineSongsContext);

  const combinedProps = {
    ...rest,
    highlightedSong,
    handleHighlightSong: setOpenedSong,
  };

  return (
    <>
      <WithEventRole roles={[EventUserType.guest]}>
        <SongsGuestView {...combinedProps} />
      </WithEventRole>
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
        <SongsDjView {...combinedProps} />
      </WithEventRole>
      {songsOptions.songsList.length ? null : <NoSongsFound>{t('noSongsFound')}</NoSongsFound>}
      {!!openedSong ? (
        <SongModal
          songId={openedSong}
          sectionName={section.name}
          queryKey={'getSectionSongs'}
          query={GET_EVENT_SECTION_SONGS}
          skip={!!variables?.templateId}
          variables={{
            ...variables,
            pagination: { skip: 0, limit: section.songsCount },
          }}
          onPlaySong={setHighlightedSong}
          onClose={() => setOpenedSong(null)}
          visible
        />
      ) : null}
    </>
  );
};

export default memo(SongsList);
