import React, { FC } from 'react';
import classNames from 'classnames';

import { PageContentHeaderProps } from './interfaces';

import useStyles from './style';

const PageContentHeader: FC<PageContentHeaderProps> = ({ extra, children, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames('pageContentHeader', classes.pageContentHeader, className)}>
      {children}
      {!!extra ? <div className={classNames('extra', classes.extra)}>{extra}</div> : null}
    </div>
  );
};

export default PageContentHeader;
