import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon';
import SettingsItem from './SettingsItem';
import AppleMusicSwitcher from '../../components/user/common/AppleMusicSwitcher';
import AppleMusicAuthProvider from '../../components/context/AppleMusicAuthContext';

import { ReactComponent as AppleMusicOriginalIcon } from '../../resources/img/svg/appleMusicOriginal.svg';

import useStyles from './style';

const AppleConnect: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={AppleMusicOriginalIcon} />
        {t('appleConnection')}
      </div>
      <AppleMusicAuthProvider autoConnect={false}>
        <AppleMusicSwitcher />
      </AppleMusicAuthProvider>
    </SettingsItem>
  );
};

export default AppleConnect;
