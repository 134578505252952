import MenuItem from './MenuItem';
import MenuComponent from './Menu';
import { CompoundedComponent } from './interfaces';
import 'rc-menu/assets/index.css';

const Menu = MenuComponent as CompoundedComponent;
Menu.Item = MenuItem;

export * from './interfaces';
export default Menu;
