import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  djsPreview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',

    '&.short': {
      '& .djPreview': {
        '& .content': {
          display: 'none',
        },
      },
    },

    '& .djPreview': {
      margin: '0 6px 0 0',

      '& .djName': {
        maxWidth: '70px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '140px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        margin: '0 16px 0 0',
      },
    },
  },
}));

export default useStyles;
