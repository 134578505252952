import Input from './Input';
import TextArea from './TextArea';
import { CompoundedComponent } from './interfaces';

const TextPreviewField = {} as CompoundedComponent;
TextPreviewField.Input = Input;
TextPreviewField.TextArea = TextArea;

export * from './interfaces';
export default TextPreviewField;
