import React, { useState, useEffect } from 'react';

import Mobile from '../../pages/Mobile';

interface MobileDetectProps {}

const MobileDetect: React.FC<MobileDetectProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 640) {
      setIsMobile(true);
    }
  }, []);

  return isMobile ? <Mobile /> : <React.Fragment>{children}</React.Fragment>;
};

export default MobileDetect;
