import React, { FC } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SPACE_CHAR } from 'services/constants';

import { CustomValueContainerProps } from './interfaces';

import useStyles from './style';

const CustomValueContainer: FC<CustomValueContainerProps> = ({
  selectProps,
  getValue,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const values = getValue();

  const label = get(values[0], 'selectedLabel', '') || get(values[0], 'label', '');

  return (
    <span
      onClick={e => {
        e.stopPropagation();
      }}
      onMouseDown={() => {
        if (!selectProps.isDisabled) {
          if (selectProps.menuIsOpen) {
            selectProps?.onMenuClose?.();
          } else {
            selectProps?.onMenuOpen?.();
          }
        }
      }}
      className={classNames('customValueContainer', classes.customValueContainer)}
    >
      <span className="prefix">
        {children}
        {SPACE_CHAR}
      </span>
      <span className="highlighted">{t(label)}</span>
    </span>
  );
};

export default CustomValueContainer;
