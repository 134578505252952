import { useContext } from 'react';
import { PolicyContext } from '../../components/context/PolicyContext';

export const useFieldPolicy = (field: string) => {
  const { isFieldEditable } = useContext(PolicyContext);
  const isEditable = isFieldEditable(field);

  return {
    isEditable,
  };
};
