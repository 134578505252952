import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  videoPreview: {
    cursor: 'pointer',
    position: 'relative',

    '& > .viboIconSVG': {
      pointerEvents: 'none',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      width: '34px',

      '@media screen and (min-width: 1441px)': {
        width: '48px',
      },
    },

    '& > .viboImage': {
      borderRadius: '4px',

      '& img': {
        objectFit: 'cover',
        width: '100%',
      },
    },

    '& > div': {
      height: '100%',
    },

    '& iframe': {
      height: '100%',
      width: '100%',
      border: 'none',
    },
  },
}));

export default useStyles;
