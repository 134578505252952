import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';

import Image from 'vibo-ui/Image';
import FilePreview from '../FilePreview/FilePreview';
import VideoPreview from 'components/common/VideoPreview';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { ImagePreviewModalProps } from 'components/modals/ImagePreviewModal';

import useStyles from './style';

const QuestionMediaPreview: FC = () => {
  const classes = useStyles();

  const { question } = useContext(QuestionContext);

  const { openModal } = useModal();

  const imageUrl = useMemo(() => question.question.images?.[0]?.imageUrl || '', [
    question.question.images?.[0]?.imageUrl,
  ]);
  const questionFile = useMemo(() => question.question.files?.[0], [
    question.question.files?.[0]?.fileId,
  ]);

  const fileUrl = useMemo(() => questionFile?.fileUrl, [questionFile?.fileUrl]);
  const fileName = useMemo(() => questionFile?.fileName, [questionFile?.fileName]);
  const videoLink = useMemo(() => question.question.youtubeLink?.link, [
    question.question.youtubeLink?.link,
  ]);

  const openImagePreviewModal = () =>
    openModal<ImagePreviewModalProps>({
      key: Modals.imagePreview,
      props: {
        imageUrl,
      },
    });

  return !!imageUrl || !!fileUrl || videoLink ? (
    <div className={classNames('questionMediaPreview', classes.questionMediaPreview)}>
      {!!imageUrl ? (
        <Image className="imagePreview" src={imageUrl} onClick={openImagePreviewModal} />
      ) : null}
      {!!fileUrl ? <FilePreview fileName={fileName} fileUrl={fileUrl} /> : null}
      {!!videoLink ? <VideoPreview link={videoLink} /> : null}
    </div>
  ) : null;
};

export default QuestionMediaPreview;
