import { useRef, useEffect } from 'react';
import round from 'lodash/round';

export function usePrevious<TValues>(value: TValues): TValues {
  const ref = useRef<TValues>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const getPercentage = (percent?: number): number => {
  return percent ? round(100 * percent) : 0;
};
