export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function getDestination(sourceIndex: number, destinationIndex: number): number {
  return destinationIndex - (sourceIndex < destinationIndex ? 0 : 1);
}
