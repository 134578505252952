import { MouseEventHandler } from 'react';

import { IconmoonFont } from 'vibo-ui/Icon';
import { PdfSettingOptions, PdfSettingsLayout, SectionBlockName } from 'types/enums';
export interface SettingOptionProps {
  icon: IconmoonFont | SvgElement;
  optionKey: PdfSettingOptions;
  inputName: string;
}

export interface EventInfoRowProps {
  icon: JSX.Element;
}

export interface EventInfoTimeProps {
  name: string;
}

export interface PdfSectionRowProps<T> {
  section: T;
  sectionsControl: useEventFileLayoutReturn;
  layout: PdfSettingsLayout;
}

export interface SongsTogglerProps extends PdfSectionTogglerBase {
  songsCount: number;
  someSongsEnabled?: boolean;
  section?: Nullable<
    EventFileTimelineOptions | EventFileSectionsOptionsWeb | EventFilePlaylistsOptionsWeb
  >;
}

export interface QuestionsTogglerProps extends PdfSectionTogglerBase {
  someQuestionsEnabled?: boolean;
  section?: Nullable<EventFileSectionsOptionsWeb>;
}

export interface NotesTogglerProps extends PdfSectionTogglerBase {
  someNotesEnabled?: boolean;
  section?: Nullable<EventFileTimelineOptions | EventFileSectionsOptionsWeb>;
}

export interface PdfSectionTogglerBase {
  onClick?: MouseEventHandler<HTMLDivElement>;
  selected: boolean;
  layout: PdfSettingsLayout;
}

export interface PdfSectionTogglerProps extends PdfSectionTogglerBase {
  content?: Nullable<JSX.Element>;
  className?: string;
  name: SectionBlockName;
}

export interface PdfSectionsHeaderProps {
  sectionsControl: useEventFileLayoutReturn;
  layout: PdfSettingsLayout;
}

export interface PdfSectionTypes
  extends EventFileTimelineOptions,
    EventFileSectionsOptionsWeb,
    EventFilePlaylistsOptionsWeb {}

export interface PdfSectionProps {
  layout: PdfSettingsLayout;
}

export interface ContentBlockHeaderProps {
  subFieldName?:
    | PdfSettingsLayout.playlists
    | PdfSettingsLayout.sections
    | PdfSettingsLayout.timeline;
  inputName: string;
  name: string;
}

export enum PrintQuestionTypes {
  'onlyAnswers' = 'onlyAnswers',
  'questionsAnswers' = 'questionsAnswers',
  'onlyAnswered' = 'onlyAnswered',
  'allQuestions' = 'allQuestions',
}
