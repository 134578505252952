import React, { FC } from 'react';

import CustomLabel from '../../../labels/CustomLabel';

import { CustomLabelTypes } from '../../../labels/CustomLabel/interfaces';
import { EventContactStatus } from '../../../../types/enums';
import { ContactStatusIndicatorProps } from '../interfaces';

const ContactStatusIndicator: FC<ContactStatusIndicatorProps> = ({ status }) => {
  const labelType =
    status === EventContactStatus.joined ? CustomLabelTypes.success : CustomLabelTypes.gray;

  return (
    <CustomLabel className="filled" type={labelType}>
      {status}
    </CustomLabel>
  );
};

export default ContactStatusIndicator;
