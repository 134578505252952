import { EventType } from 'types/enums';
import { IconFontSize, IconmoonFont } from 'vibo-ui/Icon';

const eventTypesIcon = {
  [IconFontSize.regular]: {
    [EventType.birthday]: IconmoonFont['birthday-16'],
    [EventType.corporate]: IconmoonFont['corporate-16'],
    [EventType.mitzvah]: IconmoonFont['mitzvah-16'],
    [EventType.party]: IconmoonFont['party-16'],
    [EventType.quinceanera]: IconmoonFont['quinceanera-16'],
    [EventType.schoolDance]: IconmoonFont['schoolDance-16'],
    [EventType.sweet16]: IconmoonFont['sweet16-16'],
    [EventType.wedding]: IconmoonFont['wedding-16'],
  },
  [IconFontSize.large]: {
    [EventType.birthday]: IconmoonFont['birthday-24'],
    [EventType.corporate]: IconmoonFont['corporate-24'],
    [EventType.mitzvah]: IconmoonFont['mitzvah-24'],
    [EventType.party]: IconmoonFont['party-24'],
    [EventType.quinceanera]: IconmoonFont['quinceanera-24'],
    [EventType.schoolDance]: IconmoonFont['schoolDance-24'],
    [EventType.sweet16]: IconmoonFont['sweet16-24'],
    [EventType.wedding]: IconmoonFont['wedding-24'],
  },
};

export function getEventTypeIcon(type: Nullable<EventType>, size: IconFontSize): any {
  return type ? eventTypesIcon[size][type] : null;
}
