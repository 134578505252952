import React, { useMemo } from 'react';
import classNames from 'classnames';

import Image from '../Image';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import {
  getSmallestAvailableThumbnail,
  getFormattedSongArtistAndTitle,
} from '../../../services/songs/helpers';

import { SongImageProps } from './interfaces';

import useStyles from './style';

const SongImage = <T extends SongBase>({ src, className, song, isLazy }: SongImageProps<T>) => {
  const classes = useStyles();

  const imageSrc = useMemo(() => getSmallestAvailableThumbnail(song?.thumbnails), [
    song?.thumbnails,
  ]);
  const alt = useMemo(() => getFormattedSongArtistAndTitle(song), [song?.title, song?.artist]);

  return useMemo(
    () => (
      <Image
        src={src || imageSrc}
        alt={alt}
        className={classNames('songImage', classes.songImage, className)}
        token={src || alt}
        isLazy={isLazy}
        showExtra
      >
        <Icon icon={IconmoonFont['playFilled-24']} className="playIcon" disableAutoFontSize />
      </Image>
    ),
    [song?.thumbnails]
  );
};

export default SongImage;
