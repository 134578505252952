import React, { forwardRef } from 'react';
import classNames from 'classnames';

import Input from 'vibo-ui/Input';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { CustomInputProps } from './interfaces';

import useStyles from './style';

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  (
    { hideIcons, className, iconPrefix = <Icon icon={IconmoonFont['calendar-24']} />, ...rest },
    ref
  ) => {
    const classes = useStyles();

    return (
      <>
        {hideIcons ? null : iconPrefix}
        <Input
          ref={ref}
          wrapperClassName={classNames('customInputWrapper', className, {
            [classes.customInputWrapper]: !hideIcons,
          })}
          type="text"
          preventChanges
          {...rest}
          className={classNames('customInput datePickerInput', classes.customInput)}
        />
      </>
    );
  }
);

export default CustomInput;
