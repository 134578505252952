import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FILE_DETAIL_FIELDS } from 'services/constants';
import { getSongFileOptionsLabel } from 'services/common/getTransaltedLabel';

import { FileDetailsProps } from './interfaces';

import useStyles from './style';

const FileDetails: FC<FileDetailsProps> = ({ file }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.fileDetails}>
      {FILE_DETAIL_FIELDS.map(field => {
        const value = file?.[field];

        return !!value ? (
          <div className={classes.detail} key={field}>
            <label>{t(getSongFileOptionsLabel(field))}:</label>
            <span> {value}</span>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default FileDetails;
