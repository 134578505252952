import React, { FC, useEffect, useState } from 'react';

import Input, { InputProps } from 'vibo-ui/Input';

import { ReviewLinkInputProps } from './interfaces';

const ReviewLinkInput: FC<Omit<InputProps, 'onChange'> & ReviewLinkInputProps> = ({
  initialValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue.toString());
    }
  }, [initialValue]);

  return (
    <Input
      {...props}
      onChange={(_, value) => {
        const newValue = value.trim();

        setValue(newValue);

        if (onChange && typeof onChange === 'function') {
          onChange(newValue);
        }
      }}
      value={value}
      placeholder={'https://www.review_site.com/your_company'}
    />
  );
};

export default ReviewLinkInput;
