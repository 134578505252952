export const getIds = <T extends Nullable<{ _id?: string; id?: string }>>(array: T[]): string[] => {
  return array.map(item => item?._id ?? item?.id ?? '');
};

export const concatIdsWithIndex = (ids: string[]): string[] => {
  return ids.map((id, idx) => `${id}[${idx}`);
};

export const splitIdsWithIndex = (ids: string[]): string[] => {
  return ids.map(id => {
    const idx = id.indexOf('[');

    return id.slice(0, idx);
  });
};
