import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  LegacyRef,
  MutableRefObject,
  forwardRef,
} from 'react';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import { RangeStatic } from 'quill';

import Toolbar from './Toolbar';

import { strippString } from '../../../services/common/stringHelpers';
import {
  defaultToolbarSchema,
  moduleClipboard,
  defaultModuleFormats,
  moduleHistory,
  prepareLists,
} from './constants';

import { QuillEditorProps, Editor } from './interfaces';

import useStyles from './style';

const QuillEditor = forwardRef<Editor, QuillEditorProps>(
  (
    {
      editorClassName,
      wrapperClassName,
      onChange,
      toolbarId,
      children,
      moduleFormats = defaultModuleFormats,
      toolbarSchema = defaultToolbarSchema,
      value = '',
      autoFocus = false,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const quillRef = (useRef<Nullable<Editor>>(ref as Nullable<Editor>)
      ?.current as unknown) as MutableRefObject<Editor>;

    const undo = () => quillRef.current?.editor?.history?.undo?.();
    const redo = () => quillRef.current?.editor?.history?.redo?.();

    const bindings = {
      clear: {
        key: 67,
        altKey: true,
        handler: (range: RangeStatic) => {
          quillRef?.current?.editor?.removeFormat(range.index, range.length - 1);
        },
      },
    };

    const modules = useMemo(
      () => ({
        history: moduleHistory,
        clipboard: moduleClipboard,
        toolbar: {
          container: `#${toolbarId}`,
          handlers: {
            undo,
            redo,
          },
        },
        keyboard: {
          bindings,
        },
      }),
      [toolbarId]
    );

    useEffect(() => {
      if (autoFocus) {
        quillRef.current?.editor?.focus();
      }
    }, []);

    return (
      <div
        className={classNames('quillEditor', classes.quillEditor, wrapperClassName)}
        data-text-editor={`quill-editor-${toolbarId}`}
        onClick={e => e.preventDefault()}
      >
        {/* @ts-ignore */}
        <ReactQuill
          className={editorClassName}
          ref={(quillRef as unknown) as LegacyRef<ReactQuill> | undefined}
          onChange={content => {
            if (strippString(content).length === 0) {
              onChange?.('');
            } else {
              const preparedValue = content.includes('indent') ? prepareLists(content) : content;

              onChange?.(preparedValue);
            }
          }}
          defaultValue={value}
          modules={modules}
          formats={moduleFormats}
          bounds={`[data-text-editor="quill-editor-${toolbarId}"]`}
          {...rest}
        />
        <Toolbar toolbarSchema={toolbarSchema} toolbarId={toolbarId} />
      </div>
    );
  }
);

const QuillEditorWrapper = forwardRef<Editor, QuillEditorProps>(
  ({ toolbarSchema = defaultToolbarSchema, ...rest }, ref) => {
    const classes = useStyles();

    const [isToolbarReady, setIsToolbarReady] = useState(false);

    const toolbarRef = useRef(null);

    useEffect(() => {
      if (!!toolbarRef.current) {
        setIsToolbarReady(true);
      }
    }, []);

    return (
      <div
        className={classNames('quillEditor', classes.quillEditor, rest.wrapperClassName, {
          isToolbarReady,
        })}
        data-text-editor={`quill-editor-${rest.toolbarId}`}
        onClick={e => e.preventDefault()}
      >
        {isToolbarReady ? <QuillEditor {...rest} ref={ref} /> : null}
        <div ref={toolbarRef} id={rest.toolbarId} className="quillToolbar">
          <Toolbar toolbarSchema={toolbarSchema} toolbarId={rest.toolbarId} />
        </div>
      </div>
    );
  }
);

export default QuillEditorWrapper;
