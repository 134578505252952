import React, { FC } from 'react';
import YouTube from 'react-youtube';

import { DEFAULT_OPTIONS } from './constants';

import { VideoPreviewModalProps } from './interfaces';

const Video: FC<VideoPreviewModalProps> = ({
  loomVideoLink,
  youtubeVideoId,
  options = DEFAULT_OPTIONS,
}) => {
  return loomVideoLink ? (
    <iframe src={loomVideoLink} title={`loom-video-${loomVideoLink}`} allowFullScreen />
  ) : (
    <YouTube
      videoId={youtubeVideoId}
      // @ts-ignore
      opts={options}
    />
  );
};

export default Video;
