import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  viboWizard: {
    width: '100%',
    height: '100%',
  },
});

export default useStyles;
