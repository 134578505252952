import React, { FC } from 'react';

import { EventsContextProvider } from '../../../components/context/EventsContext';
import EventsLayout from '../EventsLayout/EventsLayout';
import EventsList from '../../../components/events/EventsList';

import { GET_HISTORY_EVENTS } from '../../../graphql/queries/events';

import { EventsType, Page } from '../../../types/enums';

import useStyles from './style';

const EventsHistory: FC = () => {
  const classes = useStyles();

  return (
    <EventsLayout>
      <EventsContextProvider
        value={{ query: GET_HISTORY_EVENTS, queryKey: EventsType.historyEvents }}
      >
        <EventsList
          className={classes.eventsHistoryTable}
          page={Page.eventsHistory}
          query={GET_HISTORY_EVENTS}
          queryKey={EventsType.historyEvents}
          columns={['historyStatus', 'date', 'users', 'songs', 'planning']}
        />
      </EventsContextProvider>
    </EventsLayout>
  );
};

export default EventsHistory;
