import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button, { ButtonDecor } from 'vibo-ui/Button';

import { ConfirmActionButtonProps } from './interfaces';

import useStyles from './style';

const ConfirmActionButton: FC<ConfirmActionButtonProps> = ({
  action,
  children,
  className,
  previewClassName,
  confirmText,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isPreview, setIsPreview] = useState<boolean>(false);

  const togglePreview = () => setIsPreview(!isPreview);

  useEffect(() => {
    if (isPreview) {
      const handleShowPreviewTimer = setTimeout(() => {
        togglePreview();
        clearTimeout(handleShowPreviewTimer);
      }, 2000);
    }
  }, [isPreview]);

  return (
    <div
      onClick={togglePreview}
      className={classNames('confirmActionButton', classes.confirmActionButton, className)}
      {...rest}
    >
      {isPreview ? (
        <Button
          className={classNames('previewClassName', previewClassName, ButtonDecor.success)}
          onClick={action}
          displayType="primary"
        >
          {confirmText || t('clickComfirm')}
        </Button>
      ) : (
        children
      )}
    </div>
  );
};

export default ConfirmActionButton;
