import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  eventStatusIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .statusDot': {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      margin: '0 6px 0 0',

      '@media screen and (min-width: 1441px)': {
        width: '12px',
        height: '12px',
      },
    },

    '& .statusLabel': {
      textTransform: 'capitalize',
    },
  },
}));

export default useStyles;
