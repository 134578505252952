import React, { FC } from 'react';

import ContentBlockHeader from './ContentBlockHeader';
import PdfSections from '../sections/PdfSections';
import { SelectListProvider } from '../../../../components/context/SelectListContext';

import { PDF_OPTIONS_TEXT } from '../../../../services/constants';

import { PdfSettingOptions, PdfSettingsLayout } from '../../../../types/enums';

import useStyles from '../style';

const SectionDetailsContent: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentBlock} data-spy-id={PdfSettingOptions.sectionDetails}>
      <SelectListProvider>
        <ContentBlockHeader
          name={PDF_OPTIONS_TEXT[PdfSettingOptions.sectionDetails]}
          subFieldName={PdfSettingsLayout.sections}
          inputName="withSections"
        />
        <PdfSections layout={PdfSettingsLayout.sections} />
      </SelectListProvider>
    </div>
  );
};

export default SectionDetailsContent;
