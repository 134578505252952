import React, { FC } from 'react';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoTemplatesIcon } from '../../../resources/img/svg/emptyStateNoTemplates.svg';

import { DefaultEmptyStateProps } from '../interfaces';

import useStyles from './style';

const NoTemplates: FC<DefaultEmptyStateProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <EmptyState className={classes.noTemplates} size="lg" icon={EmptyStateNoTemplatesIcon}>
      {children}
    </EmptyState>
  );
};

export default NoTemplates;
