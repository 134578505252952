import React, { FC, memo, useCallback, useContext } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';

import Button from 'vibo-ui/Button';
import ContactControls from './ContactControls';
import CellName from 'components/common/CellName';
import ContactRoleSelect from './ContactRoleSelect';
import UserImage from 'components/user/images/UserImage';
import WithEventRole from 'components/user/WithEventRole';
import ContactStatusIndicator from './ContactStatusIndicator';
import LoadingOverlay from 'components/common/LoadingOverlay';
import {
  EventContactContext,
  EventContactContextProvider,
} from 'components/context/EventContactContext';

import { getFullName } from 'services/users/helpers';
import { getEventContactPhone } from 'services/events/helpers';

import { copyToClipboard } from 'services/common/stringHelpers';
import { EventUserType } from 'types/enums';

import { EventContactRowProps } from '../interfaces';

import useStyles from '../style';

const EventContactRow: FC<EventContactRowProps> = ({ contact }) => {
  const classes = useStyles();

  const { updating } = useContext(EventContactContext);

  const name = getFullName(contact);
  const phone = getEventContactPhone(contact);

  const copyEmail = useCallback(() => copyToClipboard(contact.email), [contact.email]);
  const copyPhone = useCallback(() => copyToClipboard(phone), [phone]);

  return (
    <div className={classNames('eventContactRow', classes.eventContactRow)}>
      <CellName name="photo">
        <UserImage user={contact} src={get(contact, 'photo.url', '')} loadMs={400} />
      </CellName>
      <CellName name="name" title={name}>
        {name}
      </CellName>
      <WithEventRole roles={[EventUserType.dj]}>
        <CellName name="status" className={contact.status}>
          <ContactStatusIndicator status={contact.status} />
        </CellName>
      </WithEventRole>
      <CellName name="role">
        <ContactRoleSelect contact={contact} />
      </CellName>
      <CellName name="email" title={contact.email}>
        <Button onClick={copyEmail} displayType="link">
          {contact.email}
        </Button>
      </CellName>
      <CellName name="phone">
        {!phone ? null : (
          <Button onClick={copyPhone} displayType="link">
            {phone}
          </Button>
        )}
      </CellName>
      <ContactControls contact={contact} />
      {updating ? <LoadingOverlay /> : null}
    </div>
  );
};

const EventContactUpdateProvider: FC<EventContactRowProps> = ({ ...rest }) => (
  <EventContactContextProvider value={rest}>
    <EventContactRow {...rest} />
  </EventContactContextProvider>
);

export default memo(EventContactUpdateProvider);
