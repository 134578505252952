import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DownloadBlock from './DownloadBlock';
import MyDevicesBlock from './MyDevicesBlock';
import Header from 'components/common/Header';
import Container from 'components/common/Container';
import EventTitle from 'components/events/Timeline/EventTitle';
import PageContentHeader from 'components/common/PageContentHeader';

import useStyles from './style';

const ScannerPage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.scannerPage}>
      <Header />
      <Container>
        <PageContentHeader>
          <EventTitle name={t('scanner')} />
        </PageContentHeader>
        <div className="blocks">
          <DownloadBlock />
          <MyDevicesBlock />
        </div>
      </Container>
    </div>
  );
};

export default ScannerPage;
