import React, { FC, createContext, Dispatch, SetStateAction, useState } from 'react';

type TSortContext = {
  sort: Nullable<TSort>;
  setSort: Dispatch<SetStateAction<Nullable<TSort>>>;
};

export const SortContext = createContext<TSortContext>(undefined!);

export const SortContextProvider: FC = ({ children }) => {
  const [sort, setSort] = useState<Nullable<TSort>>(null);

  return (
    <SortContext.Provider
      value={{
        sort,
        setSort,
      }}
    >
      {children}
    </SortContext.Provider>
  );
};
