import React, { FC } from 'react';

import { EventsContextProvider } from '../../../components/context/EventsContext';
import EventsLayout from '../EventsLayout/EventsLayout';
import EventsList from '../../../components/events/EventsList';

import { GET_UPCOMING_EVENTS } from '../../../graphql/queries/events';

import { EventsType, Page } from '../../../types/enums';

const EventsUpcoming: FC = () => {
  return (
    <EventsLayout>
      <EventsContextProvider
        value={{ query: GET_UPCOMING_EVENTS, queryKey: EventsType.upcomingEvents }}
      >
        <EventsList
          page={Page.eventsUpcoming}
          query={GET_UPCOMING_EVENTS}
          queryKey={EventsType.upcomingEvents}
          columns={['status', 'djs', 'date', 'lastModified', 'users', 'songs', 'planning']}
        />
      </EventsContextProvider>
    </EventsLayout>
  );
};

export default EventsUpcoming;
