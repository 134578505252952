import React from 'react';
import { components } from 'react-select';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

const ClearIndicator: typeof components.ClearIndicator = ({ ...rest }) => (
  <components.ClearIndicator {...rest}>
    <Icon icon={IconmoonFont['closeCircleFilled-16']} />
  </components.ClearIndicator>
);

export default ClearIndicator;
