import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import Icon, { IconFontSize } from 'vibo-ui/Icon';

import { getEventTypeIcon } from 'services/events/iconType';

import { EventTypeIconProps } from './interfaces';

const EventTypeIcon: FC<EventTypeIconProps> = ({ type, className, size = IconFontSize.large }) => {
  const eventIcon = useMemo(() => getEventTypeIcon(type, size), [type, size]);

  if (!eventIcon) {
    return null;
  }

  return <Icon className={classNames('eventTypeIcon', className)} icon={eventIcon} />;
};

export default EventTypeIcon;
