import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getEventStatusLabel, getStatusColor } from '../../../services/events/helpers';

import { EventStatusIndicatorProps } from './interfaces';

import useStyles from './style';

const EventStatusIndicator: FC<EventStatusIndicatorProps> = ({ event }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const statusLabel = useMemo(() => getEventStatusLabel(event), [
    event.isDeleted,
    event.isPast,
    event.status,
  ]);
  const statusColor = useMemo(() => getStatusColor(statusLabel), [statusLabel]);

  return (
    <div className={classNames('eventStatusIndicator', classes.eventStatusIndicator)}>
      <div className="statusDot" style={{ backgroundColor: statusColor }} />
      <div className="statusLabel">{t(statusLabel)}</div>
    </div>
  );
};

export default EventStatusIndicator;
