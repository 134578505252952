import React, { FC } from 'react';
import { useRouteMatch } from 'react-router';

import Container from 'components/common/Container';
import SongIdeasList from 'components/songIdeas/SongIdeasList';
import SongIdeasHeader from 'components/headers/SongIdeasHeader';

import { SONG_IDEAS_KEYS } from 'services/constants';

import {
  GET_COMPANY_SONG_IDEAS,
  GET_USER_SONG_IDEAS,
  GET_VIBO_SONG_IDEAS,
} from 'graphql/queries/songIdeas';

import { SongIdeasStructure } from 'components/headers/SongIdeasHeader/interfaces';

import useStyles from './style';

const SongIdeas: FC = () => {
  const classes = useStyles();

  const { params } = useRouteMatch<{ type: string }>();

  const songIdeasStructure: SongIdeasStructure = {
    [SONG_IDEAS_KEYS.me]: {
      query: GET_USER_SONG_IDEAS,
      queryKey: 'getUserSongIdeas',
    },
    [SONG_IDEAS_KEYS.vibo]: {
      query: GET_VIBO_SONG_IDEAS,
      queryKey: 'getViboSongIdeas',
    },
    [SONG_IDEAS_KEYS.company]: {
      query: GET_COMPANY_SONG_IDEAS,
      queryKey: 'getCompanySongIdeas',
    },
  };

  const currentPage = songIdeasStructure[params.type];

  return (
    <div className={classes.songIdeasPage}>
      <SongIdeasHeader />
      <Container className={classes.songIdeasContainer}>
        <SongIdeasList query={currentPage.query} queryKey={currentPage.queryKey} />
      </Container>
    </div>
  );
};

export default SongIdeas;
