import React, { useCallback, useContext } from 'react';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';

import SelectChildDjs from 'components/common/SelectChildDjs';
import { EventsFilterableContext } from '../../../EventsListContext';

import { SimpleOption } from 'vibo-ui/Select';

const DjsColumn: React.FC<ColumnProps> = () => {
  const { t } = useTranslation();
  const { filter, setFilter } = useContext(EventsFilterableContext);

  const handleChangeDjId = useCallback(option => {
    const { value: djId } = option as SimpleOption;

    if (!!djId) {
      return setFilter((prev: EventsFilter) => ({
        ...prev,
        djId,
      }));
    }

    setFilter((prev: EventsFilter) => omit(prev, ['djId']));
  }, []);

  return (
    <div className="djsColumn">
      <span className="label">{t('dj', { count: !!filter.djId ? 1 : 2 })}</span>
      <SelectChildDjs djId={filter.djId} handleChangeDjId={handleChangeDjId} />
    </div>
  );
};

export default DjsColumn;
