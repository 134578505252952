import React from 'react';

import GoBack from '../../buttons/GoBack';
import Header from '../../common/Header';

import { useParentPage } from '../../../graphql/hooks/navigation';

import useHeaderStyles from '../../common/Header/style';

interface SongIdeasPlaylistHeaderProps {
  currentTab: string;
}

const SongIdeasPlaylistHeader: React.FC<SongIdeasPlaylistHeaderProps> = ({ currentTab }) => {
  const headerClasses = useHeaderStyles();

  const { getGoBackParams } = useParentPage();

  return (
    <Header>
      <GoBack
        className={headerClasses.goBack}
        to={getGoBackParams(`/songIdeas/${currentTab}`).pathname}
      />
    </Header>
  );
};

export default SongIdeasPlaylistHeader;
