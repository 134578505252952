import SelectComponent from './Select';
import Option from './Option';
import CustomValueContainer from './CustomValueContainer';

import { CompoundedComponent } from './interfaces';

const Select = SelectComponent as CompoundedComponent;
Select.Option = Option;
Select.CustomValueContainer = CustomValueContainer;

export * from './interfaces';
export default Select;
