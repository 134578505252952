import { ChangeEvent } from 'react';
import isUndefined from 'lodash/isUndefined';

export const handleVlidateMax = (e: ChangeEvent<HTMLInputElement>, max?: number) => {
  if (!isUndefined(max) && max < +e.target.value) {
    return false;
  }

  return true;
};
