import React, { FC, useMemo } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import SearchInput from 'vibo-ui/SearchInput';
import EventTitle from '../../events/Timeline/EventTitle';
import PageContentHeader from '../../common/PageContentHeader';
import CreateTemplateButton from '../../buttons/CreateTemplateButton';

import { TEMPLATE_TYPES_LABEL } from './constants';

import { TemplatesContentHeaderProps } from './interfaces';

const TemplatesContentHeader: FC<TemplatesContentHeaderProps> = ({
  templatesCount,
  templatesType,
}) => {
  const { t } = useTranslation();

  const templateTypesLabel = useMemo(() => get(TEMPLATE_TYPES_LABEL, templatesType), [
    templatesType,
  ]);

  return (
    <PageContentHeader
      extra={
        <>
          <SearchInput placeholder={t('searchTemplates')} />
          <CreateTemplateButton />
        </>
      }
    >
      <EventTitle
        name={t('amountTemplatesAvailable', {
          count: templatesCount,
          type: t(templateTypesLabel, { count: templatesCount }).toLocaleLowerCase(),
        })}
      />
    </PageContentHeader>
  );
};

export default TemplatesContentHeader;
