import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  inputErrors: {
    whiteSpace: 'nowrap',
    color: theme.palette.error.main,
    textAlign: 'right',
    fontSize: '12px',
    lineHeight: '19.84px',
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translateY(100%)',
  },
}));

export default useStyles;
