import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  templatesPage: {
    background: theme.palette.background.default,
    height: '100%',
    padding: '0',
  },

  container: {
    height: '100%',
  },
}));

export default useStyles;
