import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  templatePage: {
    background: theme.palette.background.default,
    height: '100%',
    width: '100%',

    '& > .container': {
      height: 'calc(100vh - 40px)!important',

      '& .containerContent': {
        height: '100%',
        minHeight: '100%',
      },

      '@media screen and (min-width: 1441px)': {
        height: 'calc(100vh - 50px)!important',
      },
    },
  },
}));

export default useStyles;
