import React, { FC, SVGProps } from 'react';

import Menu from 'vibo-ui/Menu';
import Scrollbar from 'vibo-ui/Scrollbar';

import { canAddSongsToSection } from 'services/songs/helpers';
import { getSectionIcon } from 'services/sections/getSectionIcon';
import { getSectionIconType } from 'services/sections/helpers';

import { SectionIconType } from 'types/enums';
import { MoveToSectionsMenuProps } from './interfaces';

import useStyles from './style';

const MoveToSectionsMenu: FC<MoveToSectionsMenuProps> = ({
  sections,
  sectionId,
  onSelectSection,
  numSongs = 1,
}) => {
  const classes = useStyles();

  return (
    <Scrollbar className={classes.scrollbar}>
      <Menu
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
        }}
        styleModificator="asDropdown"
        selectable={false}
        activeKey=""
      >
        {sections.map(section => {
          const sectionIconType: SectionIconType = getSectionIconType(section);
          const SectionIcon: FC<SVGProps<SVGSVGElement>> = getSectionIcon(sectionIconType);

          const isSectionDisabled: boolean =
            section._id === sectionId || !canAddSongsToSection(section, numSongs);

          return (
            <Menu.Item
              key={section._id}
              disabled={isSectionDisabled}
              icon={SectionIcon}
              onClick={e => {
                e.domEvent.stopPropagation();
                onSelectSection(section);
              }}
              title={section.name}
              disableAutoFontSize
            >
              <span className={classes.sectionName}>{section.name}</span>
            </Menu.Item>
          );
        })}
      </Menu>
    </Scrollbar>
  );
};

export default MoveToSectionsMenu;
