import React, { FC } from 'react';

import SignInWithLinkContent from './SignInWithLinkContent';
import DeepLinkWizard from '../../common/DeepLinkWizard';

const SignInWithLink: FC = ({ children }) => {
  return (
    <DeepLinkWizard>
      <SignInWithLinkContent>{children}</SignInWithLinkContent>
    </DeepLinkWizard>
  );
};

export default SignInWithLink;
