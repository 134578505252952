import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  maxCharsLimit: {
    opacity: '0',
    textAlign: 'right',
    width: '100%',
    fontSize: '12px',
    lineHeight: '19.84px',
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
