export const getChildIndex = (element: HTMLElement): Nullable<number> => {
  const parent = element?.parentNode;

  if (element && parent?.children) {
    return Array.from(parent?.children).indexOf(element);
  }

  return null;
};

export const setSelection = (el: HTMLInputElement, position: number) => {
  if (el.selectionStart === position && el.selectionEnd === position) {
    return;
  }

  if (!!el.selectionStart) {
    el.focus();
    el.setSelectionRange(position, position);

    const selectTimer = setTimeout(() => {
      setSelection(el, position);
      clearTimeout(selectTimer);
    });
  } else {
    el.focus();
  }
};
