import { BEATSOURCE_AUTH_LINK } from 'services/constants';

export const transformIdsObjToArr: (ids: { [key: string]: boolean }) => string[] = ids => {
  const newIds = Object.entries(ids)
    .map(a => (!!a[1] ? a[0] : null))
    .filter(Boolean) as string[];

  return newIds;
};

export const goAuthBeatsource = () => {
  window.open(BEATSOURCE_AUTH_LINK, '_self', 'noreferrer');
};
