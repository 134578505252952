import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import InfoPanel from 'components/layouts/InfoPanel';
import { IntercomContext } from 'components/context/IntercomContext';

import { StatusBarViewProps } from './interfaces';

import useStyles from '../style';

const StatusBarView: FC<StatusBarViewProps> = ({ text, className, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { toggleShowChat } = useContext(IntercomContext);

  return (
    <InfoPanel className={classNames('warning', className)} textClass={classes.text} text={text}>
      <Button
        onClick={handleClose}
        className={classes.button}
        displayType="bordered"
        shape="round"
        size="lg"
      >
        {t('remindMeLater')}
      </Button>
      <Button
        onClick={toggleShowChat}
        className={classes.button}
        displayType="primary"
        shape="round"
        size="lg"
      >
        {t('contactSupport')}
      </Button>
    </InfoPanel>
  );
};

export default StatusBarView;
