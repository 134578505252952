import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import AuthNotRequired from '../../auth/AuthNotRequired';
import MobileDetect from '../../auth/MobileDetect';

const AuthLayout: FC<RouteProps> = ({ component: Component, ...rest }: RouteProps) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <MobileDetect>
          <AuthNotRequired>{Component && <Component {...matchProps} />}</AuthNotRequired>
        </MobileDetect>
      )}
    />
  );
};

export default AuthLayout;
