import React, { FC } from 'react';

import { SectionBlockInfoProps } from './interfaces';

import useStyles from './style';

const SectionBlockInfo: FC<SectionBlockInfoProps> = ({ title, info }) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionBlockInfo}>
      <div className={classes.title}>{title}</div>
      {info && <span className={classes.extraInfo}>{info}</span>}
    </div>
  );
};

export default SectionBlockInfo;
