import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  questionMediaPreview: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    padding: '0 0 20px 0',
    gap: '16px',

    '& > .viboImage': {
      maxWidth: '100%',
      maxHeight: '400px',
      cursor: 'pointer',

      '& img': {
        maxWidth: '100%',
        borderRadius: '4px',
        height: 'auto',
        maxHeight: '100%',
        display: 'flex',
      },
    },

    '& .videoPreview': {
      width: '360px',
      height: '200px',

      '@media screen and (min-width: 1441px)': {
        width: '505px',
        height: '280px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '0 0 24px 0',
    },
  },
}));

export default useStyles;
