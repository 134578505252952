import { useLayoutEffect, useState } from 'react';
import get from 'lodash/get';

import { getScrollParent } from '../../services/common/domHelpers';

const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil;

const useScrollspy = (scrollId: string, offset: number = 0) => {
  const [activeId, setActiveId] = useState('');
  const [ids, setIds] = useState<string[]>([]);

  const scroll = getScrollParent(scrollId);

  scroll?.querySelectorAll('[data-spy-id]').forEach((el: any) => {
    const dataAttr = get(el.dataset, 'spyId');

    if (!ids.includes(dataAttr)) {
      setIds(vaults => [...vaults, dataAttr]);
    }
  });

  useLayoutEffect(() => {
    const listener = () => {
      const scrollTop = scroll?.scrollTop || 0;

      const position = ids
        .map(id => {
          const element = document.querySelector(`[data-spy-id=${id}]`);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scrollTop - offset);
          const bottom = clamp(rect.bottom + scrollTop - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scrollTop, top, bottom));

      !!position?.id && setActiveId(position?.id);
    };

    listener();

    scroll?.addEventListener('scroll', listener);

    return () => {
      scroll?.removeEventListener('scroll', listener);
    };
  }, [ids, offset]);

  return activeId;
};

export default useScrollspy;
