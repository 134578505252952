import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  downloadPdfButton: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.5px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '20.85px',
    },
  },
}));

export default useStyles;
