export const GRADIENT_CLASSES: GradientClasses[] = [
  'gradient1',
  'gradient2',
  'gradient3',
  'gradient4',
  'gradient5',
  'gradient6',
  'gradient7',
  'gradient8',
  'gradient9',
];

export const getGradientClass = (text: string): GradientClasses => {
  let lettersSum: number = 0;

  for (const letter of text) {
    lettersSum += letter.charCodeAt(0);
  }

  return GRADIENT_CLASSES[lettersSum % GRADIENT_CLASSES.length];
};
