import React, { FC, useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import Input from 'vibo-ui/Input';
import { UserPolicyFields } from '../../../../context/PolicyContext';

import { useFieldPolicy } from '../../../../../graphql/hooks/policy';
import { MAX_PROFILE_NAMES_LENGTH } from 'services/constants';

import { FirstNameProps } from './interfaces';

const FirstName: FC<FirstNameProps> = ({ handleUpdateProfile, user }) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState(user?.firstName);
  const [error, setError] = useState(false);

  const { isEditable } = useFieldPolicy(UserPolicyFields.firstName);

  const updateDebounced = useRef(
    debounce((value: string) => {
      handleUpdateProfile({ firstName: value })
        .then(() => setError(false))
        .catch(() => setError(true));
    }, 1000)
  ).current;

  const updateFirstName = (value: string) => {
    setFirstName(value);
    updateDebounced(value);
  };

  useEffect(() => {
    user?.firstName && setFirstName(user.firstName);
  }, [user?.firstName]);

  return (
    <Input
      onChange={(_, value) => updateFirstName(value)}
      defaultValue={firstName}
      disabled={!isEditable}
      errors={[error ? t('nameCouldNotBeEmpty') : '']}
      size="xlg"
      withUnderline
      maxLength={MAX_PROFILE_NAMES_LENGTH}
      showMaxLength
    />
  );
};

export default FirstName;
