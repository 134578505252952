import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboList: {
    display: 'flex',

    '&.verticalLayout': {
      flexDirection: 'column',
    },

    '&.horizontalLayout': {
      flexDirection: 'row',
    },
  },

  spinner: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
