import React from 'react';
import classNames from 'classnames';

import Portal from '../../common/Portal';

import useStyles from './style';

interface InfoPanelProps {
  className?: string;
  textClass?: string;
  text?: React.ReactNode;
}

const InfoPanel: React.FC<InfoPanelProps> = ({ text, className, textClass, children }) => {
  const classes = useStyles();

  return (
    <Portal>
      <div data-show="true" className={classNames(classes.infoPanel, className)}>
        <div className={classNames('text', classes.text, textClass)}>{text || null}</div>
        {children}
      </div>
    </Portal>
  );
};

export default InfoPanel;
