import React, { FC } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import Button from 'vibo-ui/Button';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { GoBackProps } from './interfaces';

import useStyles from './style';

const GoBack: FC<GoBackProps> = ({ className, to, onClick, children, ...rest }) => {
  const { location, goBack, push } = useHistory();

  const classes = useStyles();

  const handleClick = () => {
    const routeState = location.state as HistoryRouteState;

    if (!!to) {
      push(to);
    } else if (routeState?.prevPath) {
      push(routeState.prevPath);
      routeState.prevPath = null;
    } else {
      goBack();
    }
  };

  return (
    <Button
      className={classNames('goBack', classes.goBack, className)}
      displayType="link"
      {...rest}
      onClick={onClick || handleClick}
    >
      {children || <Icon icon={IconmoonFont['arrowLeft-24']} />}
    </Button>
  );
};

export default GoBack;
