import React, { FC } from 'react';

import Select from 'vibo-ui/Select';

import { SortFilterPopoverProps } from './interfaces';

const SortFilterPopover: FC<SortFilterPopoverProps> = ({
  prefixText,
  value,
  onChange,
  renderOption,
  options = [],
}) => (
  <Select
    value={value}
    onChange={onChange}
    components={{
      ...(!!renderOption && {
        Option: props => renderOption?.(props),
      }),
    }}
    menuPortalTarget={document.body}
    selectedValuePrefix={prefixText}
    options={options}
    withCustomValueContainer
    menuShouldBlockScroll
  />
);

export default SortFilterPopover;
