import React, { FC, useCallback, useContext, useMemo } from 'react';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import SortFilterPopover from 'components/events/Timeline/TimelineSongsBlock/TimelineSongsHeader/SortFilterPopover';
import { PrepModeSongsFilterableContext } from '../PrepModeSongsContext';

import { PREP_MODE_SONGS_FILTER_OPTIONS } from 'services/constants';
import { getSongsFilterOptionsLabel } from 'services/common/getTransaltedLabel';

import { SimpleOption } from 'vibo-ui/Select';

const Filter: FC = () => {
  const { t } = useTranslation();

  const { filter, setFilter } = useContext(PrepModeSongsFilterableContext);

  const isSame = (option: PrepModeSongsFilterOption) => isEqual(omit(filter, 'q'), option.value);
  const currentOption = PREP_MODE_SONGS_FILTER_OPTIONS.find(option => isSame(option));

  const options = useMemo(
    () =>
      PREP_MODE_SONGS_FILTER_OPTIONS.map(option => ({
        label: t(getSongsFilterOptionsLabel(option.iconKey)),
        icon: option.Icon,
        value: option.key,
      })),
    []
  );

  const handleSort = useCallback(
    option => {
      const { value } = option as SimpleOption;

      const filterOption = PREP_MODE_SONGS_FILTER_OPTIONS.find(({ key }) => key === value);

      if (!!filterOption) {
        setFilter((prev: SectionSongsFilter) => ({ ...filterOption.value, q: prev.q }));
      }
    },
    [filter]
  );

  return (
    <SortFilterPopover
      value={currentOption?.key}
      options={options}
      onChange={handleSort}
      prefixText={t('filter')}
    />
  );
};

export default Filter;
