import React, { FC, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FilePreview from 'components/questions/FilePreview';
import Button, { ButtonDecor } from 'vibo-ui/Button';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { PickFilesModalProps } from 'components/modals/PickFilesModal';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';

import useStyles from '../../common/style';

const PdfAnswer: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formik = useFormikContext<UpdateQuestionForm>();

  const { canAnswer } = useContext(QuestionContext);

  const { openModal, closeModal } = useModal();

  const file = useMemo(() => formik.values.answer?.files?.[0], [formik.values.answer?.files?.[0]]);

  const openPickFileModal = () =>
    openModal<PickFilesModalProps>({
      key: Modals.pickFiles,
      props: {
        title: t('addPdf'),
        onChange: async (_, file) => {
          if (!!file) {
            formik.setFieldValue('answer.files', [file]);
            closePickFileModal();
          }
        },
        accept: {
          'application/pdf': ['.pdf'],
        },
        maxSize: 10000000,
      },
    });
  const openConfirmDeleteModal = () =>
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('deleteAnswer'),
        children: t('confirmDeleteAnswer'),
        submit: {
          onClick: handleRemoveFile,
          text: t('delete'),
        },
      },
    });
  const closePickFileModal = useCallback(() => closeModal(Modals.pickFiles), []);

  const handleRemoveFile = useCallback(() => {
    formik.setFieldValue('answer.files', []);
  }, []);

  return (
    <div
      className={classNames('pdfAnswer', classes.fileAnswer, {
        withAnswer: !!file?.fileUrl,
      })}
    >
      {!!file?.fileUrl ? (
        <>
          <div className="label">
            <FilePreview fileName={file?.fileName} fileUrl={file?.fileUrl}>
              {t('pdfAnswer')}:
            </FilePreview>
            <Button
              onClick={openConfirmDeleteModal}
              prefixIcon={IconmoonFont['delete-16']}
              className={ButtonDecor.danger}
              displayType="link"
            />
          </div>
        </>
      ) : (
        <Button
          onClick={openPickFileModal}
          disabled={!canAnswer}
          prefixIcon={IconmoonFont['add-file-16']}
          displayType="bordered"
          shape="round"
        >
          {t('addPdf')}
        </Button>
      )}
    </div>
  );
};

export default PdfAnswer;
