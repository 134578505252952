import React, { FC } from 'react';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStatesNoUsersIcon } from '../../../resources/img/svg/emptyStatesNoUsers.svg';

const NoUsers: FC = ({ children }) => (
  <EmptyState size="lg" icon={EmptyStatesNoUsersIcon}>
    {children}
  </EmptyState>
);

export default NoUsers;
