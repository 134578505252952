import React, { FC } from 'react';
import classNames from 'classnames';

import { ReactComponent as InfoIcon } from 'resources/img/svg/common/infoFilled.svg';
import { ReactComponent as CheckIcon } from 'resources/img/svg/common/checkCircle.svg';
import { ReactComponent as QuestionIcon } from 'resources/img/svg/common/questionCircleFilled.svg';

import { QuestionType } from 'types/enums';
import { QuestionIconProps } from './interfaces';

import useStyles from './style';

const QuestionIconComponent: FC<QuestionIconProps> = ({ question }) => {
  const classes = useStyles();

  return (
    <div className={classNames('questionIcon', classes.questionIcon)}>
      {question.settings.type === QuestionType.header ? (
        <InfoIcon className={classNames(classes.icon, classes.infoIcon)} />
      ) : question.isAnswered ? (
        <CheckIcon className={classes.icon} />
      ) : (
        <QuestionIcon className={classNames(classes.icon, classes.unanswred)} />
      )}
    </div>
  );
};

export default QuestionIconComponent;
