import React, { useState, useEffect, forwardRef, PropsWithChildren, useMemo } from 'react';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Radio from './Radio';
import { RadioGroupContextProvider } from './context';

import { RadioChangeEvent, RadioGroupProps } from './interfaces';

import useStyles from './style';

const Group = forwardRef<HTMLDivElement, PropsWithChildren<RadioGroupProps>>(
  (
    {
      onMouseEnter,
      onMouseLeave,
      onChange,
      defaultValue,
      value,
      disabled,
      options,
      id,
      vertical,
      style,
      children,
      className,
      name,
    },
    ref
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [currentValue, setCurrentValue] = useState(defaultValue);

    const formikContext = useFormikContext();

    const formikValue = useMemo(() => get(formikContext?.values, name || '', null), [
      formikContext?.values,
    ]);

    const onRadioChange = (e: RadioChangeEvent) => {
      const eValue = e.target.value;

      if (isUndefined(value)) {
        setCurrentValue(eValue);
      }

      if (!!onChange && eValue !== currentValue) {
        onChange(e);
      }

      if (!!formikContext && !!name) {
        formikContext.setFieldValue(name, eValue);
      }
    };

    const classList = classNames(
      'viboRadioGroup',
      classes.viboRadioGroup,
      {
        [`vertical ${classes.vertical}`]: vertical,
      },
      className
    );

    const renderGroup = () => {
      if (options && options.length > 0) {
        return (
          <div className={classList}>
            {options.map((option, idx) => (
              <Radio key={idx} disabled={disabled} value={option} checked={value === option}>
                {t(option as string)}
              </Radio>
            ))}
          </div>
        );
      }

      return (
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          id={id}
          ref={ref}
          style={style}
          className={classList}
        >
          {children}
        </div>
      );
    };

    useEffect(() => {
      if (!isUndefined(formikValue) && formikValue !== currentValue) {
        setCurrentValue(formikValue);
      }
    }, [formikValue]);

    useEffect(() => {
      if (value !== currentValue) {
        setCurrentValue(value);
      }
    }, [value]);

    return (
      <RadioGroupContextProvider
        value={{
          onChange: onRadioChange,
          value: currentValue,
          disabled,
          name,
        }}
      >
        {renderGroup()}
      </RadioGroupContextProvider>
    );
  }
);

export default Group;
