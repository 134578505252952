import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import Button from 'vibo-ui/Button';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { getFileNameFromLink } from 'services/common/stringHelpers';

import { FilePreviewProps } from './interfaces';

import useStyles from './style';

const FilePreview: FC<FilePreviewProps> = ({ fileUrl = '', fileName, children }) => {
  const classes = useStyles();

  const parsedName = useMemo(() => fileName || getFileNameFromLink(fileUrl), [fileName, fileUrl]);

  const handleDownloadFile = useCallback(() => window.open(fileUrl, '_blank'), [fileUrl]);

  return (
    <div className={classNames('filePreview', classes.filePreview)}>
      <Icon icon={IconmoonFont['empty-file-16']} />
      {children}
      {!!fileUrl ? (
        <Button
          onClick={handleDownloadFile}
          sufixIcon={IconmoonFont['download-24']}
          displayType="link"
          type="button"
        >
          <span className="fileName">{parsedName}</span>
        </Button>
      ) : null}
    </div>
  );
};

export default FilePreview;
