import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  formNumberInput: {
    maxWidth: '48px',

    '& .viboInput': {
      textAlign: 'center',
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '36px',
      maxWidth: '54px',
    },
  },
}));

export default useStyles;
