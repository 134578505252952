import React, { FC } from 'react';

import Sort from './Sort';
import Filter from './Filter';

import { PrepModeSongsSubHeaderProps } from './interfaces';

import useStyles from './style';

const PrepModeSongsSubHeader: FC<PrepModeSongsSubHeaderProps> = ({ renderExpandCollapseIcon }) => {
  const classes = useStyles();

  return (
    <div className={classes.prepModeSongsSubHeader}>
      {renderExpandCollapseIcon()}
      <Sort />
      <Filter />
    </div>
  );
};

export default PrepModeSongsSubHeader;
