import React, { FC } from 'react';

import ContentBlockHeader from './ContentBlockHeader';
import PdfSections from '../sections/PdfSections';
import { SelectListProvider } from '../../../../components/context/SelectListContext';

import { PDF_OPTIONS_TEXT } from '../../../../services/constants';

import { PdfSettingOptions, PdfSettingsLayout } from '../../../../types/enums';

import useStyles from '../style';

const PlaylistsContent: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentBlock} data-spy-id={PdfSettingOptions.playlists}>
      <SelectListProvider>
        <ContentBlockHeader
          name={PDF_OPTIONS_TEXT[PdfSettingOptions.playlists]}
          subFieldName={PdfSettingsLayout.playlists}
          inputName="withPlaylists"
        />
        <PdfSections layout={PdfSettingsLayout.playlists} />
      </SelectListProvider>
    </div>
  );
};

export default PlaylistsContent;
