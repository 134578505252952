import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .stateIcon': {
      color: theme.palette.primary.main,
    },

    '&.xs': {
      '& .stateIcon': {
        '& svg': {
          width: '40px',
          height: '40px',

          '@media screen and (min-width: 1441px)': {
            width: '48px',
            height: '48px',
          },
        },
      },
    },

    '&.sm': {
      '& .stateIcon': {
        '& svg': {
          width: '160px',
          height: '160px',
        },
      },
    },

    '&.lg': {
      '& .stateIcon': {
        '& svg': {
          width: '256px',
          height: '256px',
        },
      },
    },

    '& .description': {
      fontWeight: 500,
      color: theme.palette.primary.main,
      fontSize: '15px',
      lineHeight: '22.5px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '18px',
        lineHeight: '27px',
      },
    },
  },
}));

export default useStyles;
