import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import useStyles from './style';

const ModalHeader: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles();

  return <div className={classNames('modalHeader', classes.modalHeader)}>{children}</div>;
};

export default ModalHeader;
