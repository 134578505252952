import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  myAccount: {
    margin: '0 auto',
    padding: '25px 0',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 40px)',

    '& .djs-scrollbar': {
      maxHeight: 'calc(100% - 20px)',
    },

    '@media screen and (min-width: 1441px)': {
      height: 'calc(100vh - 48px)',
    },
  },

  changeInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '13px 16px',
    margin: '0 0 9px 0',
    fontSize: '13px',
    lineHeight: '19.5px',

    '& .viboCheckbox': {
      marginRight: '12px',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  header: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    borderRadius: '4px',
    padding: '20px 25px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },

  value: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    fontWeight: 500,
  },

  checkbox: {
    marginRight: '16px',
  },
}));

export default useStyles;
