import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  eventUsers: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .emptyState': {
      padding: '50px 0 0 0',

      '& .description': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& .viboToggleDropdown': {
          margin: '21px 0 0 0',
        },
      },

      '@media screen and (min-width: 1441px)': {
        padding: '100px 0 0 0',
      },
    },

    '& .eventTitle': {
      margin: '0',
    },

    '& .usersList:nth-child(odd)': {
      marginRight: '18px',

      '@media screen and (min-width: 1441px)': {
        marginRight: '24px',
      },
    },
  },

  usersLists: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
