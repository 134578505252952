import React, { FC } from 'react';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { StateIconProps } from './interfaces';

import useStyles from './style';

const StateIcon: FC<StateIconProps> = ({ icon }) => {
  const classes = useStyles();

  return <Icon className={classes.stateIcon} icon={icon} disableAutoFontSize />;
};

const icons = {
  CheckedIcon: () => <StateIcon icon={IconmoonFont['checkMark-16']} />,
  UncheckedIcon: () => <StateIcon icon={IconmoonFont['close-16']} />,
};

export default icons;
