import React, { FC, useCallback, useContext, useMemo } from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';

import Select, { SimpleOption } from 'vibo-ui/Select';
import { EventsFilterableContext } from '../../EventsListContext';

import useStyles from '../style';

const StatusColumn: FC<ColumnProps> = ({ data = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { filter, setFilter } = useContext(EventsFilterableContext);

  const options = useMemo(
    () =>
      data.map(option => ({
        label: t(option.title),
        ...option,
      })),
    [data]
  );

  const handleChange = useCallback(option => {
    const { value: statusId } = option as SimpleOption;

    if (!!statusId) {
      return setFilter((prev: EventsFilter) => ({
        ...prev,
        statusId,
      }));
    }

    setFilter((prev: EventsFilter) => omit(prev, ['statusId']));
  }, []);

  return (
    <div className="statusColumn">
      <span className="label">{t('status')}</span>
      <Select
        value={filter.statusId}
        onChange={handleChange}
        components={{
          Option: props => {
            const data = get(props, 'data') as { color: string };

            return (
              <Select.Option {...props}>
                <div className={classes.dot} style={{ backgroundColor: data.color }} />
                {props.label}
              </Select.Option>
            );
          },
        }}
        options={options}
        styleModificator="asLink"
        hideDropdownIndicator
        menuShouldBlockScroll
      />
    </div>
  );
};

export default StatusColumn;
