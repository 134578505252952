import React, { FC } from 'react';

import PhoneNumber from './PhoneNumber';
import { UserPolicyFields } from 'components/context/PolicyContext';

import { useFieldPolicy } from 'graphql/hooks/policy';

import { PhoneNumberProps } from './interfaces';

import useInputStyles from 'resources/styles/inputs/style';

const PhoneNumberWithPolicy: FC<PhoneNumberProps> = ({ ...rest }) => {
  const classes = useInputStyles();

  const { isEditable } = useFieldPolicy(UserPolicyFields.phone);

  return <PhoneNumber {...rest} containerClass={classes.underline} disabled={!isEditable} />;
};

export default PhoneNumberWithPolicy;
