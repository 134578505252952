import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  scrollbar: {
    height: 'calc(100% - 130px)!important',

    '& .listItemWrapper': {
      boxShadow: theme.shadows[3],
      borderRadius: '4px',
      overflow: 'hidden',

      '&:not(:last-child)': {
        margin: '0 0 8px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 12px 0',
        },
      },
    },
  },

  songsHeaderCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
    margin: '0 0 8px 0',

    '@media screen and (min-width: 1441px)': {
      padding: '6px',
      margin: '0 0 12px 0',
    },
  },

  listItemWrapper: {
    display: 'flex',
    border: 'none!important',

    '& > span': {
      margin: 'auto',
      textAlign: 'center',
      minWidth: '25px',
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
  },

  listBtnWrapper: {
    width: '265px',
    bottom: '59px',
    position: 'absolute',
    margin: '0 274px 0',
  },

  expandBtn: {
    padding: '0 5px',
    margin: '0 auto 0 0',
  },
}));

export default useStyles;
