import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  savingLoadingDataIndicator: {
    height: '44px',
    padding: '5px 18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    userSelect: 'none',

    '& .text, & .viboSpinner': {
      color: theme.palette.primary.light,
    },

    '& .text': {
      fontSize: '13px',
      lineHeight: '19.56px',
      margin: '0 0 0 10px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 12px',
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      height: '48px',
    },
  },
}));

export default useStyles;
