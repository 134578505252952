import React, { FC, useState, useEffect } from 'react';
import isBoolean from 'lodash/isBoolean';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';

import { PREP_MODE_SONG_FILES_COUNT } from 'services/constants';

import { IconmoonFont } from 'vibo-ui/Icon';
import { PrepModeSongBlockProps } from './interfaces';

import useStyles from './style';

const PrepModeSongBlock: FC<PrepModeSongBlockProps> = ({
  controlRef,
  renderHeader,
  children,
  song,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isShown, setIsShown] = useState<boolean>(false);

  const handleCollapse = (state?: boolean) => {
    setIsCollapsed(isBoolean(state) ? state : !isCollapsed);
    if (state || !isBoolean(state)) {
      setIsShown(false);
    }
  };

  const handleShow = (state?: boolean) => setIsShown(isBoolean(state) ? state : !isCollapsed);

  const renderProps = {
    isCollapsed,
    handleCollapse,
    isShown,
    handleShow,
  };

  const handleShowContent = () => (song.files.length > 1 ? handleCollapse() : null);

  useEffect(() => {
    controlRef?.(renderProps);
  }, [isCollapsed, isShown]);

  return (
    <div className="prepModeSongBlock">
      <div className={classes.songBlockHeader} onClick={handleShowContent}>
        {renderHeader?.(renderProps)}
      </div>
      <div className={classes.songBlockBody} onClick={handleShowContent}>
        {children}
      </div>
      {song.files.length > PREP_MODE_SONG_FILES_COUNT && !isCollapsed ? (
        <div className={classes.songBlockFooter}>
          {isShown ? null : (
            <Button
              onClick={() => handleShow()}
              sufixIcon={IconmoonFont['caretDown-16']}
              displayType="link"
            >
              {t('showSongs', { count: song.files.length - 1 })}
            </Button>
          )}
          <Button
            onClick={() => handleCollapse(true)}
            sufixIcon={IconmoonFont['caretUp-16']}
            displayType="link"
          >
            {t('close')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default PrepModeSongBlock;
