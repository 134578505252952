import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SettingsItem from './SettingsItem';
import UserLanguage from '../../components/user/UserLanguage';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';

import useStyles from './style';

const LanguageSelect: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { openModal } = useModal();

  const openChangeLanguageModal = useCallback(
    () =>
      openModal({
        key: Modals.changeLanguage,
      }),
    []
  );

  return (
    <SettingsItem>
      <div
        className={classNames('label withAction', classes.label)}
        onClick={openChangeLanguageModal}
      >
        <UserLanguage />
        {t('language')}
      </div>
    </SettingsItem>
  );
};

export default LanguageSelect;
