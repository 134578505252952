import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  sider: {
    position: 'relative',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    zIndex: 2,
    scrollbarWidth: 'none',
    overflow: 'hidden',
    width: '120px',
    minWidth: '120px',
    maxWidth: '120px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',

    '-ms-overflow-style': 'none',

    '& *': {
      ...theme.mixins.noScroll,
    },

    '&.isSiderClosed': {
      transition: 'all .2s',
      width: '44px',
      minWidth: '44px',
      maxWidth: '44px',

      '& .savingLoadingDataIndicator': {
        '& .spinner': {
          margin: '0 auto',
        },

        '& .text': {
          display: 'none',
        },
      },

      '@media screen and (min-width: 1441px)': {
        width: '56px',
        minWidth: '56px',
        maxWidth: '56px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '153px',
      minWidth: '153px',
      maxWidth: '153px',
    },
  },

  logoContainer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '120px',
    minHeight: '120px',

    '& > div': {
      width: '100%',
    },

    '& img': {
      width: '100%',
      height: 'auto',
      transition: 'linear',
      transitionDuration: '0.1s',
    },

    '& svg': {
      transition: 'linear',
      transitionDuration: '0.1s',
    },

    '@media screen and (min-width: 1441px)': {
      height: '153px',
      minHeight: '153px',
    },
  },

  logoContainerCollapsed: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '44px',

    '& img': {
      width: '100%',
      height: '56px',
      transition: 'linear',
    },

    '& svg': {
      width: '50%',
      height: '50%',
      transition: 'linear',
    },

    '@media screen and (min-width: 1441px)': {
      width: '56px',
    },
  },

  menu: {
    overflow: 'scroll',
    padding: '0 0 80px 0',

    '& .menuItem': {
      transition: 'linear',
      transitionDuration: '0s',
      whiteSpace: 'pre-wrap',
      color: isDark ? colors.white : colors.emperor,
      wordBreak: 'break-all',
    },

    '&.collapsed': {
      '& .menuItem': {
        padding: '12px 0',

        '& .menuItemContent': {
          margin: '0 auto',

          '& .viboIcon': {
            margin: 0,
          },
        },

        '& span': {
          display: 'none',
        },
      },
    },
  },
}));

export default useStyles;
