import React, { FC } from 'react';
import classNames from 'classnames';

import { EventInfoRowProps } from '../interfaces';

import useStyles from '../style';

const EventInfoRow: FC<EventInfoRowProps> = ({ icon, children }) => {
  const classes = useStyles();

  return (
    <div className={classNames('eventInfoRow', classes.eventInfoRow)}>
      {icon}
      <div className={classes.eventInfoText}>{children}</div>
    </div>
  );
};

export default EventInfoRow;
