import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import SettingsItem from './SettingsItem';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';

import useStyles from './style';

const DeleteAccount: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { openModal } = useModal();

  const openDeleteAccModal = useCallback(
    () =>
      openModal({
        key: Modals.deleteAccount,
      }),
    []
  );

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={IconmoonFont['delete-24']} className={classes.generalIcon} />
        <Button onClick={openDeleteAccModal} displayType="link">
          {t('deleteAccount')}
        </Button>
      </div>
    </SettingsItem>
  );
};

export default DeleteAccount;
