import React, { FC, memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Classes } from 'jss';

import { getGradientClass } from './helpers';

import { GradientCoverProps, GradientClasses } from './interfaces';

import { createGradientStyles } from './style';

const GradientCover: FC<PropsWithChildren<GradientCoverProps>> = ({
  className,
  children,
  gradientId = '',
}) => {
  const classes = createGradientStyles();
  const gradientClasses: Classes<GradientClasses> = createGradientStyles();

  const gradientClass: GradientClasses = getGradientClass(gradientId || Date.now().toString());

  return (
    <div
      className={classNames(
        'gradient',
        classes.viboGradient,
        gradientClasses[gradientClass],
        className
      )}
    >
      {children}
    </div>
  );
};

const shouldUpdate = (prevProps: GradientCoverProps, nextProps: GradientCoverProps) => {
  return prevProps.gradientId === nextProps.gradientId;
};

export default memo<FC<PropsWithChildren<GradientCoverProps>>>(GradientCover, shouldUpdate);
