import React, { FC } from 'react';
import classNames from 'classnames';

import { getSongLinksWithMetadata } from 'services/songs/helpers';

import { SongLinksWithMetadataProps } from './interfaces';

import useStyles from './style';

const SongLinksWithMetadata: FC<SongLinksWithMetadataProps> = ({ links, renderLink }) => {
  const classes = useStyles();

  const linksWithMetadata: SongLinkWithMetadata[] = getSongLinksWithMetadata(links);

  if (!linksWithMetadata.length) {
    return null;
  }

  return (
    <div className={classNames('songLinksWithMetadata', classes.songLinksWithMetadata)}>
      {linksWithMetadata.map(link => renderLink(link))}
    </div>
  );
};

export default SongLinksWithMetadata;
