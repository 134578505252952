import React, { FC } from 'react';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoEventsIcon } from '../../../resources/img/svg/emptyStateNoEvents.svg';

import { DefaultEmptyStateProps } from '../interfaces';

import useNoTemplatesStyles from '../NoTemplates/style';

const NoEvents: FC<DefaultEmptyStateProps> = ({ children }) => {
  const noTemplatesClasses = useNoTemplatesStyles();

  return (
    <EmptyState className={noTemplatesClasses.noTemplates} size="lg" icon={EmptyStateNoEventsIcon}>
      {children}
    </EmptyState>
  );
};

export default NoEvents;
