import React, { FC } from 'react';
import classNames from 'classnames';

import useStyles from './style';

const SettingsItem: FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classNames('settingsItem', classes.settingsItem)}>{children}</div>;
};

export default SettingsItem;
