import React, { FC } from 'react';
import classNames from 'classnames';

import { BrickProps } from './interfaces';

import useStyles from './style';

const Brick: FC<BrickProps> = ({ className }) => {
  const classes = useStyles();

  return <div className={classNames('brick', classes.brick, className)} />;
};

export default Brick;
