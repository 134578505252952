import React, { FC, useCallback, useState } from 'react';
import moment from 'moment';
import useKeyPressEvent from 'react-use/lib/useKeyPressEvent';
import { Key } from 'ts-key-enum';
import { useTranslation } from 'react-i18next';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import Button from 'vibo-ui/Button';

import { IconmoonFont } from 'vibo-ui/Icon';

import useStyles from './style';
import ChooseMonths from './ChooseMonths';

const CustomHeader: FC<ReactDatePickerCustomHeaderProps> = ({
  changeMonth,
  decreaseYear,
  increaseYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  monthDate,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showYear, setShowYear] = useState(false);

  const handlePressNext = useCallback(
    () =>
      showYear
        ? !nextYearButtonDisabled && increaseYear()
        : !nextMonthButtonDisabled && increaseMonth(),
    [showYear, nextMonthButtonDisabled, nextYearButtonDisabled]
  );
  const handlePressPrev = useCallback(
    () =>
      showYear
        ? !prevYearButtonDisabled && decreaseYear()
        : !prevMonthButtonDisabled && decreaseMonth(),
    [showYear, prevMonthButtonDisabled, prevYearButtonDisabled]
  );

  useKeyPressEvent(Key.ArrowLeft, handlePressPrev);
  useKeyPressEvent(Key.ArrowRight, handlePressNext);
  useKeyPressEvent('a', handlePressPrev);
  useKeyPressEvent('d', handlePressNext);

  const toggleMode = useCallback(() => setShowYear(!showYear), [showYear]);

  const handleChangeMonth = useCallback(
    (month: number) => {
      toggleMode();
      changeMonth(month);
    },
    [showYear]
  );

  return (
    <>
      <div className={classes.customHeader}>
        <div className="head">{t('eventDate')}</div>
        <div className="controls">
          <Button
            onClick={handlePressPrev}
            disabled={prevMonthButtonDisabled}
            prefixIcon={IconmoonFont['caretLeft-16']}
          />
          <div onClick={toggleMode} className="date">
            {moment(monthDate).format(showYear ? 'YYYY' : 'MMMM YYYY')}
          </div>
          <Button
            onClick={handlePressNext}
            disabled={nextMonthButtonDisabled}
            prefixIcon={IconmoonFont['caretRight-16']}
          />
        </div>
      </div>
      {showYear ? <ChooseMonths handleChangeMonth={handleChangeMonth} /> : null}
    </>
  );
};

export default CustomHeader;
