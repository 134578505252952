import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import CopyButton from '../../../../buttons/CopyButton';

import { getFormattedSongArtistAndTitle } from '../../../../../services/songs/helpers';

import { CopyProps } from './interfaces';

import useStyles from './style';

const Copy = <T extends SectionSong>({ song, ...rest }: CopyProps<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CopyButton
      text={getFormattedSongArtistAndTitle(song)}
      tooltipTitle={t('copyTitle')}
      className={classNames('copy', classes.copy)}
      {...rest}
    />
  );
};

export default Copy;
