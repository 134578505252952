import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  reviewLinks: {
    '& .linksContainer + .linksContainer': {
      margin: '20px 0 0 0!important',
    },
  },

  addLink: {
    margin: '16px 0 0 0',

    '&.smSize': {
      padding: '0',
    },
  },

  linksContainer: {
    '& input': {
      fontSize: '11px',
      lineHeight: '16.55px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '14px',
        lineHeight: '19.84px',
      },
    },

    '& .removeIcon': {
      display: 'none',
    },

    '& .viboIcon': {
      color: theme.palette.primary.main,
    },

    '&:hover': {
      '& .removeIcon': {
        display: 'block',
      },
    },
  },

  removeIcon: {
    cursor: 'pointer',
  },

  linkLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 7px 0',
  },

  linkTitle: {
    fontSize: '11px',
    lineHeight: '16.55px',
    color: theme.palette.text.secondary,
    margin: '0 auto 0 8px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '14px',
      lineHeight: '21.07px',
    },
  },
}));

export default useStyles;
