import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songArtistNames: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
    maxWidth: '200px',

    '@media screen and (min-width: 1441px)': {
      maxWidth: '350px',
    },

    '@media screen and (min-width: 1600px)': {
      maxWidth: '400px',
    },
  },

  songName: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18.2px',

    '&': { ...theme.mixins.longText },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },

  artistName: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',

    '&': { ...theme.mixins.longText },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '16px',
    },
  },

  extraInfoTooltip: {
    pointerEvents: 'none',

    '& .rc-tooltip-inner': {
      padding: '4px 6px',
    },

    '& *': {
      pointerEvents: 'none',
    },
  },

  extraInfoTooltipContent: {
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& .label': {
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.text.hint,
      fontSize: '11px',
      lineHeight: '15.59px',

      '&:not(:last-child)': {
        margin: '0 0 4px 0',
      },

      '& span': {
        color: theme.palette.text.primary,
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        padding: '0 0 0 4px',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '14px',
        lineHeight: '19.6px',
      },
    },
  },
}));

export default useStyles;
