import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import SongArtistNames from 'components/songs/SongArtistNames';

import { IconmoonFont } from 'vibo-ui/Icon';
import { PlaylistSongHeaderProps } from './interfaces';

import useStyles from './style';

const PlaylistSongHeader: FC<PlaylistSongHeaderProps> = ({
  song,
  removeSong,
  renderCustomButton,
  canEdit = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.playlistSongHeader}>
      <SongArtistNames song={song} />
      {renderCustomButton?.(song)}
      {canEdit && !!removeSong ? (
        <Button
          className="removeSong"
          onClick={() => removeSong?.(song.viboSongId)}
          prefixIcon={IconmoonFont['close-16']}
          displayType="bordered"
          shape="round"
        >
          {t('removeSong')}
        </Button>
      ) : null}
    </div>
  );
};

export default PlaylistSongHeader;
