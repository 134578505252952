import React, { FC } from 'react';
import classNames from 'classnames';

import ContentArea from '../../common/ContentArea';
import EventTitle from '../../events/Timeline/EventTitle';
import SelectedSongsProgressbar from './SelectedSongsProgressbar';

import { SectionBlocksHeaderProps } from './interfaces';

import useStyles from './style';

const SectionBlocksHeader: FC<SectionBlocksHeaderProps> = ({ section }) => {
  const classes = useStyles();

  return (
    <ContentArea
      className={classNames('sectionBlocksHeader', classes.sectionBlocksHeader)}
      shadowed
    >
      <EventTitle className={classes.sectionName} name={section.name} />
      <SelectedSongsProgressbar
        songsCount={section.songsCount}
        selectedCount={section.preparedSongsCount}
      />
    </ContentArea>
  );
};

export default SectionBlocksHeader;
