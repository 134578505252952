import React, { FC } from 'react';
import classNames from 'classnames';

import { ContainerProps } from './interfaces';

import useStyles from './style';

const Container: FC<ContainerProps> = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={classNames('container', classes.container, className)}>
      <div className={classNames('containerContent', classes.containerContent)}>{children}</div>
    </div>
  );
};

export default Container;
