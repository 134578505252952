import React, { FC } from 'react';
import classNames from 'classnames';

import SettingOption from './SettingOption';
import WithEventRole from '../../../components/user/WithEventRole';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventUserType, PdfSettingOptions } from 'types/enums';

import useStyles from './style';

const NavSidebar: FC = () => {
  const classes = useStyles();

  return (
    <div className={classNames('settingOptions', classes.settingOptions)}>
      <SettingOption
        optionKey={PdfSettingOptions.eventInfo}
        icon={IconmoonFont['infoRotatedOutlined-24']}
        inputName="withEventDetails"
      />
      <WithEventRole roles={[EventUserType.dj]}>
        <SettingOption
          optionKey={PdfSettingOptions.djNotes}
          icon={IconmoonFont['notes-24']}
          inputName="withDJNotes"
        />
      </WithEventRole>
      <SettingOption
        optionKey={PdfSettingOptions.timelineLayout}
        icon={IconmoonFont['timelineLayout-24']}
        inputName="withTimeline"
      />
      <SettingOption
        optionKey={PdfSettingOptions.sectionDetails}
        icon={IconmoonFont['searchOnScreen-24']}
        inputName="withSections"
      />
      <SettingOption
        optionKey={PdfSettingOptions.playlists}
        icon={IconmoonFont['musicNoteDoubleOutlined-24']}
        inputName="withPlaylists"
      />
    </div>
  );
};

export default NavSidebar;
