import React, { FC, memo } from 'react';
import { get } from 'lodash';

import { PolicyContextProvider } from './PolicyContext';

import { useMe } from '../../../graphql/hooks/user';
import { DEFAULT_POLICY } from './constants';

export const UserPolicyContextProvider: FC = memo(({ children }) => {
  const { user } = useMe();
  const policy = get(user, 'policy', DEFAULT_POLICY);

  return <PolicyContextProvider policy={policy}>{children}</PolicyContextProvider>;
});
