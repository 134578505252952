import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';

import { EVENTS_PAGE_MENU_ITEMS } from 'services/constants';
import { getSelectedEventsMenuKeys } from 'services/common/routesHelper';

const EventsNavigation: FC = () => {
  const { t } = useTranslation();

  const { push } = useHistory();

  return (
    <Menu
      getSelectedSidebarMenuKeys={getSelectedEventsMenuKeys}
      styleModificator="siderTop"
      mode="horizontal"
    >
      {EVENTS_PAGE_MENU_ITEMS.map(({ name, route }) => (
        <Menu.Item onClick={() => push(route)} key={route}>
          {t(name)}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default EventsNavigation;
