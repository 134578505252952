import React, { FC } from 'react';
import classNames from 'classnames';

import { DangerouslyInnerHTMLProps } from './interfaces';

const DangerouslyInnerHTML: FC<DangerouslyInnerHTMLProps> = ({
  className,
  content,
  tag = 'div',
  ...rest
}) => {
  const CustomTag = tag as keyof JSX.IntrinsicElements;

  return (
    <CustomTag
      className={classNames('dangerInnerHTML', className)}
      dangerouslySetInnerHTML={{ __html: content }}
      {...rest}
    />
  );
};

export default DangerouslyInnerHTML;
