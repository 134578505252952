import React, { FC, CSSProperties } from 'react';
import classNames from 'classnames';

import { getProgressBarColor } from 'services/common/colorsHelper';

import { ProgressBarProps } from './interfaces';

import useStyles from './style';

const ProgressBar: FC<ProgressBarProps> = ({ className, completed }) => {
  const classes = useStyles();

  const progressBarStyles: CSSProperties = {
    width: `${completed}%`,
    backgroundColor: getProgressBarColor(completed),
  };

  return (
    <div className={classNames('progressBar', classes.progressBar, className)}>
      <div className={classNames('fill', classes.fill)} style={progressBarStyles}>
        <span className={classes.progress} />
      </div>
      <span className="percentage">{completed}</span>
    </div>
  );
};

export default ProgressBar;
