import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  titlePhotoPreview: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  title: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 500,

    '@media screen and (min-width: 1441px)': {
      fontSize: '24px',
      lineHeight: '34px',
    },
  },

  photo: {
    width: '320px',
    height: '320px',

    '@media screen and (min-width: 1441px)': {
      width: '396px',
      height: '396px',
    },
  },
}));

export default useStyles;
