import React, { FC } from 'react';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoSongsIcon } from '../../../resources/img/svg/emptyStateNoSongs.svg';

const NoSongsFound: FC = ({ children }) => (
  <EmptyState className="noSongsFound" size="sm" icon={EmptyStateNoSongsIcon}>
    {children}
  </EmptyState>
);

export default NoSongsFound;
