import React, { FC } from 'react';
import classNames from 'classnames';

import Brick from '../Brick';

import useStyles from './style';

const JoinByDeepLinkPlaceholder: FC = () => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.joinByDeepLinkPlaceholder)}>
      <Brick className={classes.title} />
      <Brick className={classes.eventImage} />
    </div>
  );
};

export default JoinByDeepLinkPlaceholder;
