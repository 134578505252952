import React from 'react';

import SectionSongPreview from '../SectionSongPreview';

import useTimelineSectionStyles from '../TimelineSection/TimelineSectionSongsInfo/style';
import useStyles from './style';

interface PrepModeSectionSongsInfoProps {
  section: PrepModeSection;
}

const PrepModeSectionSongsInfo: React.FC<PrepModeSectionSongsInfoProps> = ({ section }) => {
  const timelineSectionClasses = useTimelineSectionStyles();
  const classes = useStyles();

  return (
    <div className={classes.songsInfoWithPreview}>
      <SectionSongPreview section={section} className={timelineSectionClasses.songPreview} />
    </div>
  );
};

export default PrepModeSectionSongsInfo;
