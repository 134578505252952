import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songIndex: {
    fontSize: '13px',
    lineHeight: '18.2px',
    fontWeight: 500,
    color: theme.palette.text.secondary,

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

export default useStyles;
