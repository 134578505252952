import React, { FC } from 'react';

import FormInput from '../FormInput/FormInput';

import { FormInputProps } from '../FormInput/interfaces';

import useStyles from './style';

const FormNumberInput: FC<FormInputProps> = ({ placeholder = '∞', ...rest }) => {
  const classes = useStyles();

  return (
    <FormInput
      wrapperClassName={classes.formNumberInput}
      placeholder={placeholder}
      {...rest}
      type="number"
      withNull
    />
  );
};

export default FormNumberInput;
