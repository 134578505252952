import React, { FC, useEffect, useRef, useContext, Ref } from 'react';
import classNames from 'classnames';

import { EditingNotesContext } from '../../events/Timeline/TimelineNotesBlock/TimelineNotesContext';

import QuillEditor from '../QuillEditor';
import { Editor, QuillDefaultProps } from '../QuillEditor/interfaces';

import { NotesProps, ExtraNoteData } from './interfaces';

import useStyles from './style';
import 'react-quill/dist/quill.snow.css';

const Notes: FC<NotesProps> = ({
  onChange,
  placeholder,
  className,
  autoFocus,
  initialValue = '',
}) => {
  const classes = useStyles();

  const rteRef = useRef<Nullable<QuillDefaultProps & ExtraNoteData>>(null);

  const editingContext = useContext(EditingNotesContext);

  useEffect(() => {
    if (editingContext?.isEditing || autoFocus) {
      rteRef.current?.focus();
    }
  }, [editingContext?.isEditing, autoFocus, initialValue]);

  return (
    <QuillEditor
      value={initialValue}
      onChange={onChange}
      placeholder={placeholder}
      ref={rteRef as Ref<Editor>}
      wrapperClassName={classNames(classes.editor, className)}
      toolbarId="notes-toolbar"
      autoFocus
    />
  );
};

export default Notes;
