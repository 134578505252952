import React, { FC, useMemo } from 'react';

import ContentArea from '../../common/ContentArea';
import UserImage from '../images/UserImage';

import { getFullName, getSmallerUserImage } from '../../../services/users/helpers';

import { DjCardProps } from './interfaces';

import useStyles from './style';

const DjCard: FC<DjCardProps> = ({ onClick, dj }) => {
  const classes = useStyles();

  const djName = useMemo(() => getFullName(dj), [dj.firstName, dj.lastName]);

  return (
    <ContentArea className={classes.djCard} onClick={() => onClick?.(dj)} shadowed>
      <UserImage
        user={dj as UserBase}
        src={getSmallerUserImage(dj as UserBase)}
        className={classes.djPhoto}
      />
      <div className={classes.djName}>
        <span>{djName}</span>
      </div>
    </ContentArea>
  );
};

export default DjCard;
