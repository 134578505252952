import React, { FC, useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Input from 'vibo-ui/Input';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { UserPolicyFields } from '../../../../context/PolicyContext';

import { useFieldPolicy } from '../../../../../graphql/hooks/policy';

import { SelectedLink } from './interfaces';

import useStyles from './style';

const SelectedLinkField: FC<SelectedLink> = ({
  handleChangeLink,
  handleDeselectLink,
  error,
  value,
  link,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [text, setText] = useState<string>(value);

  const { isEditable } = useFieldPolicy(UserPolicyFields.socialLinks);

  const linkName = link.name.toLowerCase();

  const isError = !!get(error, linkName);

  const linkError = useMemo(
    () => (isError ? t('pleaseEnterValidLink', { platform: link.name }) : ''),
    [isError]
  );

  const handleRemoveLink = () => handleDeselectLink(link);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <div className={classes.selectedLink}>
      <div className={classes.selectedLinkInfo}>
        <Icon icon={IconmoonFont['link-16']} />
        <div className="text">{link.name}</div>
        {isEditable ? (
          <Icon
            onClick={handleRemoveLink}
            className={classNames('deleteIcon', classes.deleteIcon)}
            icon={IconmoonFont['close-16']}
          />
        ) : null}
      </div>
      <Input
        onChange={(_, value) => {
          setText(value);
          handleChangeLink(link, value);
        }}
        errors={[linkError]}
        placeholder={link.link}
        defaultValue={text}
        disabled={!isEditable}
        withUnderline
      />
    </div>
  );
};

export default SelectedLinkField;
