import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon';
import Button from 'vibo-ui/Button';
import AuthLayout from 'components/auth/AuthLayout';

import { ReactComponent as EmptyState404Icon } from 'resources/img/svg/emptyState404.svg';

import useCommonStyles from 'resources/styles/common/style';

const NotFoundPage: FC = () => {
  const commonStylesClasses = useCommonStyles();
  const { t } = useTranslation();

  return (
    <AuthLayout className={commonStylesClasses.errorPage}>
      <Icon icon={EmptyState404Icon} disableAutoFontSize />
      {t('pageNotFound')}
      <Button
        to="/events/upcoming"
        className="homeBtn"
        displayType="bordered"
        shape="round"
        size="lg"
      >
        {t('homePage')}
      </Button>
    </AuthLayout>
  );
};

export default NotFoundPage;
