import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import BlockInfo from './BlockInfo';
import { TimelineSectionContext } from '../TimelineSectionContext';

import { getSectionSongsInfo } from '../../../../services/sections/helpers';

import { SectionBlockName } from '../../../../types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';

const SongsBlockInfo: FC = () => {
  const { t } = useTranslation();

  const { section } = useContext(TimelineSectionContext);

  const songsContent = useMemo(() => getSectionSongsInfo(section), [section]);

  return (
    <BlockInfo
      className={SectionBlockName.songs}
      icon={IconmoonFont['musicNoteDoubleOutlined-16']}
      description={`${songsContent} ${t('song', { count: section.songsCount })}`}
      content={songsContent}
    />
  );
};

export default SongsBlockInfo;
