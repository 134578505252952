import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  eventsHistoryTable: {
    '& .cellName': {
      '&.historyStatus': {
        minWidth: '500px',
        maxWidth: '500px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '750px',
          maxWidth: '750px',
        },
      },
    },
  },
}));

export default useStyles;
