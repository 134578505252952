import React, { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import CustomLabel from '../CustomLabel';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { CustomLabelProps, CustomLabelTypes } from '../CustomLabel/interfaces';

const PublicLabel: FC<CustomLabelProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <CustomLabel
      {...rest}
      type={CustomLabelTypes.success}
      className={classnames('publicLabel', rest.className)}
    >
      <Icon icon={IconmoonFont['public-16']} />
      {t('public')}
    </CustomLabel>
  );
};

export default PublicLabel;
