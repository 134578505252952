import React, { useState, useMemo, forwardRef, MutableRefObject, useEffect } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import RcDatePicker from 'react-datepicker';
import { useEnsuredForwardedRef } from 'react-use';

import Footer from './Footer';
import CustomInput from './CustomInput';
import CustomHeader from './CustomHeader';

import moment from 'moment';

import { DatePickerProps, HandleChange, DateValue } from './interfaces';

import useStyles from './style';

const DatePicker = forwardRef<RcDatePicker, DatePickerProps>(
  (
    {
      footerOkText,
      footerClearText,
      calendarClassName,
      disablePast,
      hideFooter,
      onChange,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const [value, setValue] = useState<DateValue>(null);

    const datePickerRef = useEnsuredForwardedRef(ref as MutableRefObject<RcDatePicker | null>);

    const today = useMemo(() => new Date(moment().format('MM-DD-YYYY')), []);

    const handleChange: HandleChange = (date, e) => {
      const isoDate = !!date ? moment(Date.parse((date as unknown) as string)).toISOString() : date;

      setValue(date as DateValue);
      onChange((isoDate as unknown) as Date, e);
    };

    const defaultValue = useMemo(
      () => (!!rest.value ? moment(rest.value).format('ddd, MMM Do YYYY') : rest.value),
      [rest.value]
    );

    useEffect(() => {
      if (!isEqual(rest.value, value)) {
        const newValue = !!rest.value ? moment(rest.value).toDate() : null;

        setValue(newValue);
      }
    }, [rest.value]);

    return (
      <RcDatePicker
        ref={datePickerRef}
        onChange={handleChange}
        selected={value}
        customInput={<CustomInput {...rest} />}
        renderCustomHeader={params => <CustomHeader {...params} />}
        minDate={disablePast ? today : null}
        calendarClassName={classNames('viboDatePicker', classes.viboDatePicker, calendarClassName)}
        clearButtonClassName={classNames('clearButton', classes.clearButton)}
        placeholderText="TBD"
        withPortal
        isClearable={!rest.disabled}
        {...rest}
        value={defaultValue}
        dateFormat="EEE, MMM do yyyy"
        showMonthDropdown
        showYearDropdown
      >
        {hideFooter ? null : (
          <Footer
            datePickerRef={datePickerRef}
            footerOkText={footerOkText}
            footerClearText={footerClearText}
          />
        )}
      </RcDatePicker>
    );
  }
);
export default DatePicker;
