import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import CellName from '../../../common/CellName';
import ContactStatusFilter from '../EventContactsFilter/ContactStatusFilter';
import ContactRoleFilter from '../EventContactsFilter/ContactRoleFilter';
import WithEventRole from '../../../user/WithEventRole';

import { EventUserType } from '../../../../types/enums';

import useStyles from '../style';

const EventContactsHeader: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.eventContactsHeader}>
      <CellName name="name">{t('name')}</CellName>
      <CellName name="photo" />
      <WithEventRole roles={[EventUserType.dj]}>
        <CellName name="status">
          <ContactStatusFilter />
        </CellName>
      </WithEventRole>
      <CellName name="role">
        <ContactRoleFilter />
      </CellName>
      <CellName name="email">{t('email')}</CellName>
      <CellName name="phone">{t('phone')}</CellName>
    </div>
  );
};

export default memo(EventContactsHeader);
