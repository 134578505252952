import React, { FC, useMemo, useContext } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';

import Select from 'vibo-ui/Select';
import { UsersFilterableContext } from '../UsersListContext';

import { columnsConfig } from '../../../../services/admin/definitions';

import { SelectUsersFilterProps, UserFilterOption } from './interfaces';

const SelectUsersFilter: FC<SelectUsersFilterProps> = ({ filterKey }) => {
  const { t } = useTranslation();

  const { filter, setFilter } = useContext(UsersFilterableContext);

  const currentConfig = get(columnsConfig, filterKey);

  const valuePrefix = useMemo(() => t(filterKey === 'role' ? 'role' : 'paymentStatus'), []);

  const options = useMemo(
    () =>
      currentConfig?.data?.map(option => {
        return {
          label: t(option.title),
          value: option.value,
        };
      }) || [],
    []
  );

  const initialValue = useMemo(() => find(options, ['value', get(filter, filterKey)]), [
    options,
    filter?.role,
    filter?.financeStatus,
    filterKey,
  ]);

  return (
    <Select
      onChange={option => {
        const { value } = option as UserFilterOption;

        setFilter((prev: UsersFilter) => ({
          ...prev,
          [filterKey]: value || null,
        }));
      }}
      selectedValuePrefix={valuePrefix}
      styleModificator="asLink"
      value={initialValue?.value}
      options={options}
      withCustomValueContainer
    />
  );
};

export default SelectUsersFilter;
