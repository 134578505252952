import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CopyButton from '../../buttons/CopyButton';

import { strippString } from '../../../services/common/stringHelpers';

import { CopyQuestionAnswerProps } from './interfaces';

import useStyles from './style';

const CopyQuestionAnswer: FC<CopyQuestionAnswerProps> = ({ value = '' }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const text = useMemo(() => strippString(value), [value]);

  return !!value ? (
    <CopyButton
      text={text}
      tooltipTitle={t('copy')}
      className={classNames('copyQuestionAnswer', classes.copyQuestionAnswer)}
    />
  ) : null;
};

export default CopyQuestionAnswer;
