import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import Controls from './Controls';
import CellName from '../../common/CellName';
import ContentArea from '../../common/ContentArea';
import { TemplatesContext } from 'components/context/TemplatesContext';

import { useMe } from '../../../graphql/hooks/user';
import { renderCell } from '../../events/common/table';

import { TemplateRowProps } from './interfaces';

import useEventsStyles from '../../events/EventsList/views/style';
import useStyles from './style';

const TemplateRow: FC<TemplateRowProps> = ({ template, columns, onClick }) => {
  const eventsClasses = useEventsStyles();
  const classes = useStyles();

  const templatesContext = useContext(TemplatesContext);

  const { user } = useMe();

  return (
    <ContentArea
      className={classNames('eventDjRow', eventsClasses.eventDjRow, classes.templateRow)}
      onClick={() => onClick?.()}
      shadowed
    >
      {columns.map(column => (
        <CellName name={column} key={`cell-name-${column}`}>
          {renderCell(column as EventsTableColumn, (template as unknown) as EventListItem, user)}
        </CellName>
      ))}
      {!!templatesContext ? <Controls template={template} /> : null}
    </ContentArea>
  );
};

export default TemplateRow;
