import React, { useMemo, memo } from 'react';
import get from 'lodash/get';

import moment from '../../../../../../services/moment';
import {
  getDateAsTimeFromNow,
  getDateWithTimezone,
} from '../../../../../../services/common/dateHelpers';

import useStyles from '../../style';

const getDateValue = (field: string, event: EventListItem) => {
  switch (field) {
    case 'date': {
      const eventDate = getDateWithTimezone(event.date, event.timezone);

      return (
        <span className="date">
          {!!eventDate ? moment(eventDate).format('MMM Do YYYY') : 'TBD'}
        </span>
      );
    }
    case 'dateTemplate': {
      const date = get(event, 'createdAt');

      return <span className="date">{!!date ? moment(date).format('MMM Do YYYY') : 'TBD'}</span>;
    }
    case 'lastUpdated': {
      return (
        <span className="lastUpdated">
          {getDateAsTimeFromNow(event.lastUpdated || get(event, 'updatedAt'))}
        </span>
      );
    }
    default: {
      return <span />;
    }
  }
};

const DateCell: React.FC<CellProps<EventListItem>> = ({ field, data }) => {
  const classes = useStyles();

  const date = useMemo(() => getDateValue(field, data), [field, data.date, data.lastUpdated]);

  return <div className={classes.date}>{date}</div>;
};

export default memo(DateCell);
