import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  joinByDeepLinkPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  title: {
    height: '27px',
    width: '75%',
    margin: '0 0 9px 0',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 12px 0',
      height: '34px',
    },
  },

  eventImage: {
    width: '100%',
    height: '320px',

    '@media screen and (min-width: 1441px)': {
      height: '396px',
    },
  },
}));

export default useStyles;
