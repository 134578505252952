import React, { FC } from 'react';
import classNames from 'classnames';

import DjPreview from '../DjPreview';

import { DJS_LIMIT_FULL_VIEW } from './contsants';

import { DjsPreviewProps } from './interfaces';

import useStyles from './style';

const DjsPreview: FC<DjsPreviewProps> = ({ djs, className, children, ...rest }) => {
  const classes = useStyles();

  return !!djs.length ? (
    <div
      className={classNames('djsPreview', classes.djsPreview, className, {
        short: djs.length > DJS_LIMIT_FULL_VIEW,
      })}
    >
      {djs.map(dj => (
        <DjPreview dj={dj} key={`djs-previews-${dj._id}`} {...rest} />
      ))}
      {!!children ? <div className="extra">{children}</div> : null}
    </div>
  ) : null;
};

export default DjsPreview;
