import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import AuthLayout from '../../AuthLayout';
import ViboLogo from 'components/common/ViboLogo';

import { LocationState } from './interfaces';

const ForgotPasswordSuccess: FC = () => {
  const { t } = useTranslation();

  const location = useLocation<LocationState>();

  return (
    <AuthLayout className="forgot-password-success">
      <ViboLogo />
      <div className="text">
        {!!location?.state?.email ? (
          <span
            dangerouslySetInnerHTML={{
              __html: `${t('emailSuccessfullySentTo')} <i>"${location.state.email}"</i>`,
            }}
          />
        ) : null}
        <div>{t('pleaseCheckYourInbox')}</div>
      </div>
      <Button to="/login" displayType="primary" shape="round" size="lg">
        {t('ok')}
      </Button>
    </AuthLayout>
  );
};

export default ForgotPasswordSuccess;
