import React, { FC } from 'react';
import classNames from 'classnames';

import { MaxCharsLimitProps } from './interfaces';

import useStyles from './style';

const MaxCharsLimit: FC<MaxCharsLimitProps> = ({ className, maxLength, valueLength = 0 }) => {
  const classes = useStyles();

  return maxLength ? (
    <div className={classNames('limit', classes.maxCharsLimit, className)}>
      {valueLength}/{maxLength}
    </div>
  ) : null;
};

export default MaxCharsLimit;
