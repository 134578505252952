import ModalComponent from './Modal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import { CompoundedComponent } from './interfaces';
import 'rc-dialog/assets/index.css';

export * from './useModal';
export * from './interfaces';

const Modal = ModalComponent as CompoundedComponent;
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
