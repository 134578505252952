import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import Checkbox from 'vibo-ui/Checkbox';
import { SelectListContext } from './SelectListContext';

import { SelectCheckboxProps } from './interfaces';

const SelectCheckbox: FC<SelectCheckboxProps> = ({ id, className, ...rest }) => {
  const { toggleSelectId, allSelected, selectedIds, lastTouchedId, toggleSelectAll } = useContext(
    SelectListContext
  );

  const checked = !!selectedIds.length && (!!id ? selectedIds.includes(id) : allSelected);
  const indeterminate = !!selectedIds.length && !id && !allSelected;

  return (
    <Checkbox
      checked={checked}
      onChange={() => {
        if (id) {
          toggleSelectId(id);
        } else {
          toggleSelectAll();
        }
      }}
      indeterminate={indeterminate}
      className={classNames(className, {
        lastTouched: lastTouchedId === id,
      })}
      {...rest}
    />
  );
};

export default SelectCheckbox;
