import React, { FC, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PhoneInput from 'react-phone-input-2';

import { prepareUserPhone, formatPhoneNumber } from 'services/common/phoneHelper';

import { PhoneNumberProps } from './interfaces';

import 'react-phone-input-2/lib/bootstrap.css';
import useStyles from './style';

const PhoneNumber: FC<PhoneNumberProps> = ({
  handleUpdateProfile,
  onChange,
  user,
  value,
  disabled,
  inputClass,
  containerClass,
  disableBaseClasses,
  disableDropdown = false,
}) => {
  const classes = useStyles();

  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    if (user?.phoneCode && user?.phoneNumber) {
      setPhone(formatPhoneNumber(`${user?.phoneCode}${user?.phoneNumber}`));
    }

    if (!!value) {
      setPhone(value);
    }
  }, []);

  const updateDebounced = useCallback(
    debounce((phone: string, countryData: PhoneCountry) => {
      return handleUpdateProfile?.(prepareUserPhone(phone, countryData));
    }),
    []
  );

  const updatePhone = useCallback(
    debounce((phone: string, countryData: PhoneCountry) => {
      setPhone(phone);
      updateDebounced(phone, countryData);
      onChange?.(phone, countryData);
    }, 1000),
    []
  );

  return (
    <div
      className={classNames('phoneNumber', {
        [classes.phoneNumber]: !disableBaseClasses,
      })}
    >
      <PhoneInput
        value={phone}
        onChange={updatePhone}
        country="us"
        placeholder="+ 1 (702) 123-4567"
        disabled={disabled}
        inputClass={inputClass}
        containerClass={containerClass}
        disableSearchIcon
        enableSearch
        disableDropdown={disableDropdown}
      />
    </div>
  );
};

export default PhoneNumber;
