export const columnsConfig: { [key in ViboUsersTableColumn]: ColumnProps<keyof UsersFilter> } = {
  name: {
    columnKey: 'name',
  },
  role: {
    columnKey: 'role',
    field: 'role',
    data: [
      { value: undefined, key: 'all', title: 'all' },
      { value: 'user', key: 'user', title: 'user' },
      { value: 'single', key: 'single', title: 'single' },
      { value: 'company', key: 'company', title: 'company' },
      { value: 'child', key: 'child', title: 'child' },
    ],
  },
  email: {
    columnKey: 'email',
  },
  financeStatus: {
    columnKey: 'paymentStatus',
    field: 'financeStatus',
    data: [
      { value: undefined, key: 'all', title: 'all' },
      {
        value: 'activated',
        key: 'activated',
        title: 'activated',
      },
      { value: 'limited', key: 'limited', title: 'limited' },
      {
        value: 'failedCharge',
        key: 'failedCharge',
        title: 'failedCharge',
      },
      {
        value: 'ambassador',
        key: 'ambassador',
        title: 'ambassador',
      },
    ],
  },
};

export const cellsFields: { [key in ViboUsersTableColumn]: string } = {
  name: 'name',
  role: 'role',
  email: 'email',
  financeStatus: 'djSettings.financeStatus',
};
