import Quill from 'quill';

export interface QuillEditorProps extends ToolbarProps {
  value?: string;
  editorClassName?: string;
  wrapperClassName?: string;
  placeholder?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  moduleFormats?: string[];
  onChange?: (newValue: string) => void;
}

export type ToolbarSchemaOption = Exclude<ToolbarButton, ToolbarButton.header | ToolbarButton.list>;

export interface ToolbarProps {
  toolbarId: string;
  toolbarSchema?: ToolbarSchemaOption[];
}

export interface Editor {
  editor: QuillDefaultProps;
}

export interface QuillDefaultProps extends Quill {
  history: EditorHistory;
}

export interface EditorHistory {
  undo: Function;
  redo: Function;
}

export enum ToolbarButton {
  'link' = 'link',
  'undo' = 'undo',
  'redo' = 'redo',
  'bold' = 'bold',
  'italic' = 'italic',
  'underline' = 'underline',
  'clean' = 'clean',
  'header' = 'header',
  'paragraph' = 'paragraph',
  'list' = 'list',
  //Extended - custom names
  'list_ordered' = 'list_ordered',
  'list_bullet' = 'list_bullet',
  'divider' = 'divider',
  'header_1' = 'header_1',
  'header_2' = 'header_2',
}

export enum ListType {
  'ordered' = 'ordered',
  'bullet' = 'bullet',
}
