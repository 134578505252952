import React, { FC } from 'react';
import classNames from 'classnames';

import { CellNameProps } from './interfaces';

const CellName: FC<CellNameProps> = ({ className, children, name, ...rest }) => (
  <span className={classNames('cellName', name, className)} {...rest}>
    {children}
  </span>
);

export default CellName;
