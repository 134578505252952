import React, { FC } from 'react';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';

import { ReactComponent as ViboLogoIcon } from '../../../resources/img/svg/viboLogo.svg';

import { ViboLogoProps } from './interfaces';

const ViboLogo: FC<ViboLogoProps> = ({ className }) => (
  <Icon icon={ViboLogoIcon} className={classNames('viboLogo', className)} disableAutoFontSize />
);
export default ViboLogo;
