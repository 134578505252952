import WaveSurfer from 'wavesurfer.js';
import useKeyPressEvent from 'react-use/lib/useKeyPressEvent';
import { Key } from 'ts-key-enum';

import { checkElementsInFocus } from 'services/common/domHelpers';

export const usePlayerShortKeys = ({
  player,
  handleMute,
  togglePlay,
  playSong,
  handleVolume,
}: {
  player: Nullable<WaveSurfer>;
  handleMute: Function;
  togglePlay: Function;
  playSong: Function;
  handleVolume: Function;
}) => {
  const allowPress = () => !checkElementsInFocus(['input', 'textarea', '.ql-editor']);

  useKeyPressEvent(Key.ArrowLeft, () => allowPress() && player?.skipBackward());

  useKeyPressEvent(Key.ArrowRight, () => allowPress() && player?.skipForward());

  useKeyPressEvent(Key.ArrowUp, () => allowPress() && handleVolume(true));

  useKeyPressEvent(Key.ArrowDown, () => allowPress() && handleVolume(false));

  useKeyPressEvent('m', () => allowPress() && handleMute());

  useKeyPressEvent(' ', () => allowPress() && togglePlay());

  useKeyPressEvent(Key.Escape, () => allowPress() && playSong(null));
};
