export const getGaPageTitle = (pathname: string) => {
  const path = pathname.split('/').filter(Boolean);
  const pageName = path[0];
  const pageId = path[1];
  const pageTab = path[2];

  switch (pageName) {
    case 'songIdeas':
      return pageId === 'v' ? `${pageName} details` : `${pageName} ${pageId}`;
    case 'template':
    case 'event':
      return `${pageName} ${pageTab}`;
    case 'templates':
    case 'events':
      return `${pageName} ${pageId}`;
    default:
      return pageName;
  }
};
