import React, { FC } from 'react';

import { toolbarButtons } from './constants';

import { ToolbarProps } from './interfaces';

const Toolbar: FC<ToolbarProps> = ({ toolbarSchema, toolbarId }) => (
  <div id={toolbarId} className="quillToolbar">
    {toolbarSchema?.map((button, idx) => toolbarButtons[button]?.(`${toolbarId}-${idx}`))}
  </div>
);

export default Toolbar;
