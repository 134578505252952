import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  logoContainer: {
    minHeight: '44px',
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,

    '& *': {
      borderRadius: 0,
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '56px',
    },
  },
}));

export default useStyles;
