import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useWindowSize } from 'react-use';
import { ToastOptions } from 'react-toastify/dist/types';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { buttonIcons } from '../../services/common/iconButton';
import { SCREEN_SIZE } from '../../services/constants';

import { ButtonIconType } from '../../types/enums';

interface ToastNotifyProps extends ToastOptions {
  text: string;
  iconType?: ButtonIconType;
  className?: string;
}

export const useClearableTimer = (): UseClearableTimer => {
  const [timer, setTimer] = useState<Timer>(null);

  const clearTimer = () => timer && clearTimeout(timer);
  const handleUpdateTimer = (newTimer: Timer) => {
    setTimer(newTimer);
  };

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  return {
    timer,
    clearTimer,
    setTimer: handleUpdateTimer,
  };
};

export const toastNotify = ({
  text = '',
  type = 'success',
  autoClose = 2000,
  position = 'top-right',
  iconType = ButtonIconType.checkMarkOutlined,
  ...rest
}: ToastNotifyProps) => {
  toast(text, {
    position,
    autoClose,
    type,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    bodyClassName: 'toastNotifyBody',
    progressClassName: 'toastNotifyProgress',
    icon: iconType ? <Icon icon={buttonIcons[iconType]} /> : null,
    closeButton: <Icon className="close" icon={IconmoonFont['close-16']} />,
    ...rest,
    className: classNames('toastNotify', rest.className),
  });
};

export const openErrorNotification = (message: string) => {
  toastNotify({ text: message, type: 'error', iconType: ButtonIconType.infoNotRotated });
};

export const useScreen = () => {
  const [isLg, setIsLg] = useState(false);

  const { width } = useWindowSize();

  useEffect(() => {
    const isLarge = width > SCREEN_SIZE.lg;

    if (isLarge !== isLg) {
      setIsLg(isLarge);
    }
  }, [width]);

  return {
    isLg,
  };
};
