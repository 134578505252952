import React, { useMemo, memo } from 'react';
import round from 'lodash/round';
import { useTranslation } from 'react-i18next';

import EventProgress from 'pages/events/EventGeneral/EventProgress';

import { getPercentage } from 'services/common/dataProcessingHelpers';

import useStyles from '../../style';

const ProgressCell: React.FC<CellProps<EventListItem>> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const progress = useMemo(() => getPercentage(data?.progress), [data?.progress]);
  const songsProgress = useMemo(() => getPercentage(data?.sectionsWithSongsProgress), [
    data?.sectionsWithSongsProgress,
  ]);

  return (
    <div className={classes.progressBars}>
      <EventProgress
        label={`${data?.sectionsWithSongs}/${data?.sectionsWithSongsTotal} ${t('songSections')}`}
        info={`${round(songsProgress)}%`}
        progress={songsProgress}
      />
      <EventProgress
        label={`${data?.answeredQuestionsCount}/${data?.questionsCount} ${t('questions')}`}
        info={`${round(progress)}%`}
        progress={progress}
      />
    </div>
  );
};

export default memo(ProgressCell);
