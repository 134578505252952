import React, { SVGProps } from 'react';

import { SectionIconType } from '../../types/enums';

import { ReactComponent as MusicNoteCrossedOutIcon } from '../../resources/img/svg/sections/types/musicNoteCrossedOut.svg';
import { ReactComponent as MusicNoteSingleIcon } from '../../resources/img/svg/sections/types/musicNoteSingle.svg';
import { ReactComponent as MusicNoteDoubleIcon } from '../../resources/img/svg/musicNoteDouble.svg';
import { ReactComponent as DontPlayIcon } from '../../resources/img/svg/sections/types/dontPlay.svg';
import { ReactComponent as PublicIcon } from '../../resources/img/svg/sections/types/public.svg';

const sectionIcons = {
  [SectionIconType.noMusic]: MusicNoteCrossedOutIcon,
  [SectionIconType.specialMoment]: MusicNoteSingleIcon,
  [SectionIconType.songList]: MusicNoteDoubleIcon,
  [SectionIconType.dontPlay]: DontPlayIcon,
  [SectionIconType.public]: PublicIcon,
};

export function getSectionIcon(type: SectionIconType): React.FC<SVGProps<SVGSVGElement>> {
  return sectionIcons[type];
}
