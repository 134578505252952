import React, { FC } from 'react';
import Switch from 'vibo-ui/Switch';
import { Field, FieldProps, useFormikContext } from 'formik';

import { FormSwitchProps } from './interfaces';

const FormSwitch: FC<FormSwitchProps> = ({ name, ...rest }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field }: FieldProps) => {
        return (
          <Switch
            {...field}
            checked={field.value}
            {...rest}
            onChange={(checked, e) => {
              setFieldValue(field.name, checked);
              rest.onChange?.(checked, e);
            }}
          />
        );
      }}
    </Field>
  );
};

export default FormSwitch;
