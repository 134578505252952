import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SortFilterPopover from '../SortFilterPopover';
import { TimelineSongsContext } from '../../TimelineSongsContext';

import { SONGS_SORT_OPTIONS } from 'services/constants';
import { gtmTriggerClick } from 'services/googleAnalytics';
import { getSongsSortOptionsLabel } from 'services/common/getTransaltedLabel';

import { SimpleOption } from 'vibo-ui/Select';

const Sort: FC = () => {
  const { t } = useTranslation();

  const { songsOptions } = useContext(TimelineSongsContext);

  const isSame = (option: SongsSortOption) =>
    songsOptions?.songsSort?.field === option.value?.field &&
    songsOptions?.songsSort?.direction === option.value?.direction;
  const currentSort = SONGS_SORT_OPTIONS.find(option => isSame(option));

  const options = useMemo(
    () =>
      SONGS_SORT_OPTIONS.map(option => ({
        label: t(getSongsSortOptionsLabel(option.key)),
        icon: option.Icon,
        value: option.key,
      })),
    []
  );

  const handleSort = useCallback(
    option => {
      const { value } = option as SimpleOption;

      const sortOption = SONGS_SORT_OPTIONS.find(({ key }) => key === value);

      gtmTriggerClick(`timeline-section-details-songs-sort-${value}`);

      if (!!sortOption) {
        songsOptions.setTotalCount(songsOptions.songsList.length);
        songsOptions.setSongsSort(sortOption.value);
      }
    },
    [songsOptions.songsList.length]
  );

  return (
    <SortFilterPopover
      value={currentSort?.key}
      options={options}
      onChange={handleSort}
      prefixText={t('sort')}
      // data-gtm-target-id={`timeline-section-details-songs-sort-${option.key}`}
    />
  );
};

export default Sort;
