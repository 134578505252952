import React, { FC, useContext, useState } from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import { useLocalStorage } from 'react-use';

import SongPlayer from './SongPlayer';
import Icon from 'vibo-ui/Icon/Icon';
import Container from 'components/common/Container';
import ContentArea from 'components/common/ContentArea';
import PlayerSongPlaceholder from 'components/Placeholders/PlayerSongPlaceholder';
import Button, { ButtonDecor } from 'vibo-ui/Button';
import { BeatsourceContext } from '../BeatsourceContext';

import { ReactComponent as CloseIcon } from 'resources/img/svg/common/close.svg';
import { ReactComponent as BeatsourceLogoIcon } from 'resources/img/svg/beatsourceLogo.svg';

import { PlayerBarProps } from './interfaces';

import useStyles from './style';

const PlayerBar: FC<PlayerBarProps> = ({ track }) => {
  const classes = useStyles();

  const [audio, setAudio] = useState<Nullable<HTMLAudioElement>>(null);

  const { playSong } = useContext(BeatsourceContext);

  const [isSiderClosed] = useLocalStorage<boolean>('isSiderClosed', false);

  return (
    <ContentArea
      className={classNames(classes.songPlayerWrapper, {
        isSiderClosed,
      })}
      shadowed
    >
      <Container>
        <Icon icon={BeatsourceLogoIcon} className="logo" disableAutoFontSize />
        {!!audio ? (
          <SongPlayer audio={audio} artist={track.artist} name={track.name} />
        ) : (
          <PlayerSongPlaceholder />
        )}
        <Button
          onClick={() => playSong(null)}
          className={classNames('closeBtn', ButtonDecor.danger)}
          displayType="link"
        >
          <Icon icon={CloseIcon} disableAutoFontSize />
        </Button>
        {/* @ts-ignore */}
        <ReactPlayer
          url={track.sample_url}
          onReady={player => {
            const internalPlayer = player.getInternalPlayer() as HTMLAudioElement;

            setAudio(internalPlayer);
          }}
          width={0}
          height={0}
        />
      </Container>
    </ContentArea>
  );
};

export default PlayerBar;
