import client from '../client';
import { TEMPLATE_PUBLIC } from '../fragments/templates';

export const updateTemplatePublic = (template: Template): void => {
  const isPublic = !template.isPublic;

  client.writeFragment({
    fragment: TEMPLATE_PUBLIC,
    id: `Template:${template._id}`,
    data: {
      isPublic,
      __typename: 'Template',
    },
  });
};
