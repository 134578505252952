import {
  ForwardRefExoticComponent,
  Dispatch,
  ReactElement,
  PropsWithChildren,
  SetStateAction,
  CSSProperties,
} from 'react';
import { DialogProps } from 'rc-dialog';

import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';

export type ModalRenderer = ({ key, props }: ModalWindowDescriptor<UnknownProps>) => ReactElement;

export interface ModalProps extends DialogProps {
  modalName: string;
  size?: ModalSize;
  preventDefaultClosing?: boolean;
}

export interface ModalBodyProps {
  autoHeight?: boolean;
  noScroll?: boolean;
  autoStaticHeight?: boolean;
  style?: CSSProperties;
  id?: string;
}

export interface CompoundedComponent extends ForwardRefExoticComponent<ModalProps> {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
}

export interface ModalWindowsResponse {
  openedModals: ModalsSet;
  setOpenedModals: Dispatch<SetStateAction<ModalsSet>>;
}

export interface ModalWindowDescriptor<H> {
  key: string;
  props?: PropsWithChildren<H>;
  type?: ModalType;
}

export enum ModalType {
  'permanent' = 'permanent',
  'temporary' = 'temporary',
}

export type CommonModalProps = UnknownProps & Partial<ModalProps>;

export type UseModalResponse = {
  openedModals: ModalsSet;
  closeModal: (name: string) => void;
  openModal: <T extends UnknownProps>(modalProps: ModalWindowDescriptor<T>) => void;
  isModalOpened: (name: string) => boolean;
};

export type ModalsSet = ModalWindowDescriptor<UnknownProps>[];

export type ModalSize = 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';
