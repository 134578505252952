import React, { FC, createContext, memo, useCallback, useState } from 'react';

import PlayerBar from './PlayerBar';

import { TApolloLoadingContext } from './interfaces';

export const BeatsourceContext = createContext<TApolloLoadingContext>(undefined!);

export const BeatsourceContextProvider: FC = memo(({ children }) => {
  const [track, setTrack] = useState<Nullable<BeatsourceTrack>>(null);
  const [isSongPaused, setIsSongPaused] = useState<boolean>(false);

  const playSong = useCallback((track: Nullable<BeatsourceTrack>) => {
    setTrack(track);
  }, []);

  return (
    <BeatsourceContext.Provider value={{ track, isSongPaused, setIsSongPaused, playSong }}>
      {!!track ? <PlayerBar track={track} key={track.id} /> : null}
      {children}
    </BeatsourceContext.Provider>
  );
});
