import React from 'react';
import classNames from 'classnames';

import Image from '../../../image/Image';
import ImageDefaultPlaceholder from '../../../image/ImageDefaultPlaceholder';

import { ReactComponent as UserIcon } from '../../../../resources/img/svg/common/user.svg';

import { getFullName } from '../../../../services/users/helpers';

import useStyles from '../style';

interface UserImageProps {
  user: Maybe<UserBase>;
  src: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  loadMs?: number;
}

const UserImage = ({ user, className, loadMs, onClick, src }: UserImageProps) => {
  const classes = useStyles();
  const alt = !!user ? getFullName(user) : '';

  return (
    <Image
      token={user?._id}
      src={src}
      alt={alt}
      onClick={onClick}
      className={classNames(classes.img, className)}
      debounceLoadMs={loadMs}
    >
      <ImageDefaultPlaceholder icon={<UserIcon />} />
    </Image>
  );
};

export default UserImage;
