import React, { FC, createContext } from 'react';

import useScrollspy from '../../../graphql/hooks/useScrollSpy';

type TScrollSpyContext = {
  activeId: string;
};

interface ScrollSpyContextProviderProps {
  offset?: number;
  scrollId: string;
}

export const ScrollSpyContext = createContext<TScrollSpyContext>(undefined!);

export const ScrollSpyContextProvider: FC<ScrollSpyContextProviderProps> = ({
  scrollId,
  children,
  offset = 0,
}) => {
  const activeId = useScrollspy(scrollId, offset);

  return <ScrollSpyContext.Provider value={{ activeId }}>{children}</ScrollSpyContext.Provider>;
};
