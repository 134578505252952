import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  KeyboardEvent,
  MouseEventHandler,
  ChangeEventHandler,
  MutableRefObject,
  useMemo,
  useCallback,
  ChangeEvent,
  FocusEventHandler,
} from 'react';
import isNull from 'lodash/isNull';
import classNames from 'classnames';
import { Key } from 'ts-key-enum';
import { mergeRefs } from 'react-merge-refs';

import Spinner from 'vibo-ui/Spinner';
import InputErrors from 'vibo-ui/common/InputErrors';
import MaxCharsLimit from 'vibo-ui/common/MaxCharsLimit';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { EXTRA_INPUT_SPACE } from 'vibo-ui/utils/constants';
import { handleVlidateMax } from './constants';

import { InputProps } from './interfaces';

import useInputStyles from 'resources/styles/inputs/style';
import useStyles from './style';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      preventChanges,
      withClearBtn,
      withNumberArrows,
      withUnderline,
      maxLength,
      className,
      wrapperClassName,
      autoWidth,
      autoFocus,
      iconPrefix,
      iconSuffix,
      showMaxLength,
      errors,
      onChange,
      onPressEnter,
      onKeyDown,
      onKeyUp,
      onFocus,
      onBlur,
      onClick,
      alwaysShowError = false,
      selectOnFocus = false,
      isTime = false,
      withNull = false,
      loading = false,
      size = 'lg',
      type = 'text',
      value = '',
      ...rest
    },
    ref
  ) => {
    const inputClasses = useInputStyles();
    const classes = useStyles();

    const [currentValue, setCurrentValue] = useState<string | number>('');
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [highlighted, setHighlighted] = useState<boolean>(false);

    const autoSizerRef = useRef<Nullable<HTMLDivElement>>(null);
    const inputRef = useRef<Nullable<HTMLInputElement>>(null);
    const mergedRef = (mergeRefs([inputRef, ref]) as unknown) as MutableRefObject<HTMLInputElement>;

    const isPassword = useMemo(() => type === 'password', [type]);

    const handleToggleVisibility = useCallback(() => setIsVisible(!isVisible), [isVisible]);

    const handleResizeIput: (newSize?: number) => void = newSize => {
      if (inputRef.current && autoSizerRef.current && autoWidth) {
        inputRef.current.style.width = `${
          typeof newSize === 'number' ? newSize : autoSizerRef.current.clientWidth
        }px`;
      }
    };

    const handlePressKey: (e: KeyboardEvent<HTMLInputElement>) => void = e => {
      switch (e.key) {
        case Key.Enter:
          onPressEnter?.();
          return;
        default:
          onKeyDown?.(e);
          return;
      }
    };

    const handleClear: MouseEventHandler<HTMLDivElement> = () => {
      onChange?.((null as unknown) as ChangeEvent<HTMLInputElement>, '');
      setCurrentValue('');
      autoWidth && handleResizeIput(0);
    };

    const handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void = e => {
      preventChanges && e.preventDefault();
      onKeyDown?.(e);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
      const targetValue = e.currentTarget.value;
      const newValue =
        !targetValue && withNull ? null : type === 'number' ? +targetValue : targetValue;

      if (handleVlidateMax(e, rest.max)) {
        onChange?.(e, newValue as string);
        setCurrentValue(targetValue);
        autoWidth &&
          handleResizeIput(
            newValue ? autoSizerRef.current?.clientWidth ?? 0 + EXTRA_INPUT_SPACE : 0
          );
      }
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = e => {
      onBlur?.(e);
      setHighlighted(false);
    };

    useEffect(() => {
      value !== currentValue && setCurrentValue(value);
    }, [value]);

    useEffect(() => {
      autoWidth && handleResizeIput();
    }, [autoWidth]);

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }

      if (!!rest.defaultValue) {
        setCurrentValue(rest.defaultValue);
      }
    }, []);

    return (
      <div
        className={classNames(
          'viboInputWrapper',
          classes.viboInputWrapper,
          inputClasses.viboUnderlineWrapper,
          {
            [`${inputClasses.underline} underline`]: withUnderline,
            [`${inputClasses.autoWidth} autoWidth`]: autoWidth,
            [`${inputClasses.withAutoWidth} withAutoWidth`]: maxLength,
            withClearBtn: withClearBtn,
            errorsPresent: !!errors?.filter(Boolean).length,
            alwaysShowError,
          },
          wrapperClassName
        )}
      >
        <div className={classNames('closeWrapper', classes.closeWrapper)}>
          {iconPrefix ? (
            <span className={classNames('iconPrefix', classes.icon, classes.iconPrefix)}>
              {iconPrefix}
            </span>
          ) : null}
          <input
            value={isTime ? value : isNull(currentValue) ? '' : currentValue}
            onChange={handleChange}
            maxLength={maxLength}
            className={classNames(
              'viboInput',
              classes.viboInput,
              withUnderline
                ? `withUnderline ${inputClasses.withUnderline}`
                : `withBorder ${inputClasses.withBorder}`,
              size,
              {
                withArrows: withNumberArrows,
                [`withIconPrefix ${classes.withIconPrefix}`]: !!iconPrefix,
                [`withIconSuffix ${classes.withIconSuffix}`]: !!iconSuffix || isPassword,
                [`disabled ${inputClasses.disabled}`]: rest.disabled,
              },
              className
            )}
            onFocus={onFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onKeyUp={onKeyUp}
            onKeyPress={handlePressKey}
            type={isVisible ? 'text' : type}
            ref={mergedRef}
            min={0}
            onClick={e => {
              onClick?.(e);

              if (selectOnFocus && !highlighted) {
                setHighlighted(true);
                e.currentTarget.select();
              }
            }}
            {...rest}
          />
          {(iconSuffix && !withClearBtn) || isPassword || loading ? (
            <span className={classNames('iconSuffix', classes.icon, classes.iconSuffix)}>
              {loading ? (
                <Spinner size="sm" />
              ) : isPassword ? (
                <Icon
                  onClick={handleToggleVisibility}
                  icon={IconmoonFont[isVisible ? 'notVisible-24' : 'visible-24']}
                />
              ) : (
                iconSuffix
              )}
            </span>
          ) : null}
          {withClearBtn && !loading ? (
            <Icon
              className={classNames(classes.clearBtn, {
                clearBtn: currentValue.toString().length,
              })}
              onClick={handleClear}
              icon={IconmoonFont['closeCircleFilled-16']}
            />
          ) : null}
        </div>
        {errors?.filter(Boolean)?.length ? (
          <InputErrors errors={errors} />
        ) : showMaxLength ? (
          <MaxCharsLimit maxLength={maxLength} valueLength={currentValue.toString().length} />
        ) : null}
        {autoWidth ? (
          <div
            ref={autoSizerRef}
            className={classNames('autoWidthSizer', inputClasses.autoWidthSizer)}
          >
            {currentValue.toString().replace(/ /g, '\u00a0')}
          </div>
        ) : null}
      </div>
    );
  }
);

export default Input;
