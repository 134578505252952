import React, { FC, useMemo } from 'react';

import ProgressBar from 'components/common/ProgressBar';

import { getPercentage } from 'services/common/dataProcessingHelpers';

import { SelectedSongsProgressbarProps } from './interfaces';

import useStyles from './style';

const SelectedSongsProgressbar: FC<SelectedSongsProgressbarProps> = ({
  songsCount,
  selectedCount,
}) => {
  const classes = useStyles();

  const progress = useMemo(() => getPercentage(selectedCount / songsCount), [
    selectedCount / songsCount,
  ]);

  return (
    <div className={classes.selectedProgress}>
      <ProgressBar completed={progress} />
      <div className="info">{`${selectedCount}/${songsCount}`}</div>
    </div>
  );
};

export default SelectedSongsProgressbar;
