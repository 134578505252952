import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/da';
import 'moment/locale/he';
import 'moment/locale/es';
import 'moment/locale/en-gb';

let lang = localStorage.getItem('i18nextLng');

// transform lang code for Hebrew
if (lang === 'iw') {
  lang = 'he';
}

if (lang) {
  moment.locale(lang);
}

export default moment;
