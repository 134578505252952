import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon';
import SettingsItem from './SettingsItem';
import BeatsourceSwitcher from 'components/user/common/BeatsourceSwitcher';

import { ReactComponent as BeatsourceLogoBIcon } from 'resources/img/svg/beatsourceLogoB.svg';

import useStyles from './style';

const BeatsourceConnect: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={BeatsourceLogoBIcon} />
        {t('beatsourceConnection')}
      </div>
      <BeatsourceSwitcher />
    </SettingsItem>
  );
};

export default BeatsourceConnect;
