import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  defaultColumn: {
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
