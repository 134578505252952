import React, { FC, ReactElement, MouseEvent, cloneElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { IntercomContext } from 'components/context/IntercomContext';

import { useModal } from 'vibo-ui/Modal';

import { useMe } from 'graphql/hooks/user';
import { isDjLimited } from 'services/users/helpers';

import { Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';

const ActiveDj: FC = ({ children }) => {
  const { t } = useTranslation();

  const { toggleShowChat } = useContext(IntercomContext);

  const { user } = useMe();

  const { openModal } = useModal();

  const openConfirmModal = () => {
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('createNewEvent'),
        children: t('pleaseActivateYourAccount'),
        bodyStyle: {
          width: 350,
        },
        cancel: {
          text: t('remindMeLater'),
        },
        submit: {
          text: t('contactSupport'),
          onClick: toggleShowChat,
        },
      },
    });
  };

  if (isDjLimited(user)) {
    return (
      <>
        {!!children
          ? cloneElement(children as ReactElement, {
              onClick: (e: MouseEvent) => {
                e.preventDefault();
                openConfirmModal();
              },
            })
          : null}
      </>
    );
  }

  return <>{children}</>;
};

export default ActiveDj;
