import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboInput: {
    padding: '0',
    width: '100%',
    maxWidth: '100%',
    resize: 'none',
    color: theme.palette.text.primary,

    '&.bold': {
      fontWeight: 500,
    },

    '&:focus-visible': {
      outline: 'none',
    },

    '&::placeholder': {
      color: theme.palette.text.hint,
    },

    '&[type=number]': {
      '-moz-appearance': 'textfield',

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    },

    '&[type="email"], &[type="password"], &[type="text"], &[type="tel"]': {
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        '-webkit-transition': 'color 9999s ease-out, background-color 9999s ease-out',
        '-webkit-transition-delay': '9999s',
      },
    },
  },

  viboInputWrapper: {
    '&.withClearBtn': {
      '& .viboInput': {
        paddingRight: '25px',
      },
    },

    '& .withArrows': {
      '&[type=number]': {
        '-moz-appearance': 'auto',

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          appearance: 'auto',
        },
      },
    },

    '&.errorsPresent.alwaysShowError .withBorder': {
      border: `1px solid ${theme.palette.error.main}`,
    },

    '&.errorsPresent .withBorder': {
      '&:hover, &:focus, &:active': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
  },

  withIconPrefix: {
    paddingLeft: '28px!important',

    '@media screen and (min-width: 1441px)': {
      paddingLeft: '30px!important',
    },
  },

  withIconSuffix: {
    paddingRight: '28px!important',

    '@media screen and (min-width: 1441px)': {
      paddingRight: '30px!important',
    },
  },

  icon: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
  },

  iconPrefix: {
    left: '8px',
  },

  iconSuffix: {
    right: '8px',
  },

  closeWrapper: {
    position: 'relative',
    display: 'flex',
  },

  clearBtn: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: '6px',
    transform: 'translateY(-50%)',
    color: theme.palette.primary.main,
    opacity: '0',
  },
}));

export default useStyles;
