import React, { FC, useRef, useContext, useEffect, PropsWithChildren } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { WizardContext } from 'vibo-ui/Wizard/WizardStepContext';

import { getChildIndex } from 'vibo-ui/utils/helpers/dom';

import { WizardStepProps } from './interfaces';

const Step: FC<PropsWithChildren<Partial<WizardStepProps>>> = ({ children, ...rest }) => {
  const stepProps = rest as WizardStepProps;

  const divRef = useRef<Nullable<HTMLDivElement>>(null);

  const { wizardRefProps, setWizardRefProps } = useContext(WizardContext);

  useEffect(() => {
    const index = getChildIndex(divRef.current as HTMLElement);

    if (index === 0 && !isEqual(stepProps.currentStep, wizardRefProps?.currentStep)) {
      setWizardRefProps(stepProps);
    }
  }, [stepProps.currentStep, divRef.current]);

  return (
    <div ref={divRef} className={classNames('step', rest.stepName)}>
      {children}
    </div>
  );
};

export default Step;
