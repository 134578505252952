import React, { FC } from 'react';
import classNames from 'classnames';

import ProgressBar from 'components/common/ProgressBar';

import { ProgressValue } from 'types/enums';
import { EventProgressProps } from './interfaces';

import useStyles from './style';

const EventProgress: FC<EventProgressProps> = ({ className, progress, info, label }) => {
  const classes = useStyles();

  const progressColor = classNames({
    [classes.progressFull]: progress >= ProgressValue.full,
    [classes.progressMiddle]: progress >= ProgressValue.middle && progress < ProgressValue.full,
    [classes.progressLow]: progress < ProgressValue.middle,
  });

  return (
    <div className={classNames('eventProgress', classes.eventProgress, className)}>
      <div className={classNames('progressBarTop', classes.progressBarTop)}>
        <div className={classNames('progressBarLabel', classes.progressBarLabel)}>{label}</div>
        <div className={classNames('progressBarInfo', classes.progressBarInfo)}>{info}</div>
      </div>
      <ProgressBar className={progressColor} completed={progress} />
    </div>
  );
};

export default EventProgress;
