import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import ContentArea from '../../common/ContentArea';

import { AuthLayoutProps } from './interfaces';

import useStyles from './styles';

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={classNames('authLayout', classes.authLayout, className)}>
      <ContentArea className={classNames(classes.authLayoutCard)} shadowed>
        {children}
      </ContentArea>
    </div>
  );
};

export default AuthLayout;
