import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';

import { notificationIconByType } from './contstants';

import { NotificationIconProps } from './interface';

import useStyles from './style';

const NotificationIcon: FC<NotificationIconProps> = ({ notificationType }) => {
  const classes = useStyles();

  const icon = useMemo(() => notificationIconByType[notificationType], [notificationType]);

  return (
    <Icon
      className={classNames('notificationIcon', classes.notificationIcon, notificationType)}
      icon={icon}
      disableAutoFontSize
    />
  );
};

export default NotificationIcon;
