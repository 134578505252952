import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  phoneNumber: {
    width: 'fit-content',

    '& .arrow': {
      display: 'none',
    },

    '& .react-tel-input .flag': {
      '@media screen and (min-width: 1441px)': {
        transform: 'scale(1.15)',
      },
    },

    '& .react-tel-input .form-control': {
      border: 'none',
      borderBottom: '1px solid transparent',
      padding: '0',
      marginLeft: '36px',
      borderRadius: '0',
      boxShadow: theme.shadows[0],
      transition: 'none',
      fontSize: '13px',
      lineHeight: '19.5px',
      color: theme.palette.text.primary,
      width: 'auto',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    '& .react-tel-input .flag-dropdown': {
      top: '4px',

      '@media screen and (min-width: 1441px)': {
        top: '2px',
      },
    },

    '& .react-tel-input .form-control.open': {
      boxShadow: theme.shadows[0],
    },

    '& .react-tel-input .selected-flag': {
      width: '20px',
      height: '14px',
      padding: '0',
      overflow: 'hidden',
      borderRadius: '2px',

      '& .flag': {
        marginLeft: '-2px',

        '@media screen and (min-width: 1441px)': {
          marginLeft: '0',
        },
      },

      '@media screen and (min-width: 1441px)': {
        width: '25.36px',
        height: '18.34px',
      },
    },

    '& .react-tel-input .country-list': {
      maxHeight: '325px',
    },
  },
}));

export default useStyles;
