import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'vibo-ui/Checkbox';
import { UserPolicyFields } from 'components/context/PolicyContext';

import { useFieldPolicy } from 'graphql/hooks/policy';

import { ShowNameSwitcherProps } from './interfaces';

import useStyles from '../style';

const ShowNameSwitcher: FC<ShowNameSwitcherProps> = ({ handleUpdateProfile, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isEditable } = useFieldPolicy(UserPolicyFields.showName);

  const toggleShowName = (showName: boolean) =>
    handleUpdateProfile({
      showName,
    });

  return (
    <Checkbox
      defaultChecked={user?.showName || false}
      onChange={e => toggleShowName(e.target.checked)}
      disabled={!isEditable || !user?.nick}
      className={classes.showRealName}
    >
      <span>{t('displayMyRealName')}</span>
    </Checkbox>
  );
};

export default ShowNameSwitcher;
