import React, { useMemo } from 'react';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { AutoSizer, WindowScroller, Grid, GridCellProps } from 'react-virtualized';

import { usePrapareData } from 'graphql/hooks/virtualizeList';

import { VirtualizedGridProps } from './interfaces';

import useStyles from './style';

const VirtualizedGrid = <T extends {}>({
  totalOptionsCount = 0,
  columnWidths,
  columnsCount,
  className,
  scrollId,
  loadMore,
  data,
  rowHeights,
  gaps,
  rowRenderer,
}: VirtualizedGridProps<T>) => {
  const classes = useStyles();

  const {
    rowHeight,
    colWidth,
    colsCount,
    scrollElement,
    preparedContent,
    rowCount,
  } = usePrapareData({
    rowRenderer,
    data,
    scrollId,
    rowHeights,
    columnWidths,
    columnsCount,
    gaps,
  });

  const hasMore = useMemo(() => data.length < totalOptionsCount, [data.length, totalOptionsCount]);

  const getCell = ({ rowIndex, columnIndex, style, key }: GridCellProps) => {
    const index = rowIndex * colsCount + columnIndex;

    return (
      <div key={key} style={style} className="virtualized-row">
        {preparedContent[index]}
      </div>
    );
  };

  return !!scrollElement ? (
    <InfiniteScroll
      useWindow={false}
      initialLoad={false}
      threshold={150}
      hasMore={hasMore}
      loadMore={loadMore}
      getScrollParent={() => scrollElement}
    >
      <WindowScroller scrollElement={scrollElement}>
        {({ height = 0, isScrolling, scrollTop }) => (
          <AutoSizer
            disableHeight
            className={classNames('virtualizedList', classes.virtualizedList, className)}
          >
            {({ width }) => (
              <Grid
                isScrolling={isScrolling}
                cellRenderer={getCell}
                columnWidth={colWidth}
                columnCount={colsCount}
                height={height}
                scrollTop={scrollTop}
                overscanColumnCount={0}
                overscanRowCount={0}
                rowHeight={rowHeight}
                rowCount={Math.ceil(rowCount / colsCount)}
                scrollToColumn={undefined}
                scrollToRow={undefined}
                width={width}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </InfiniteScroll>
  ) : (
    <></>
  );
};

export default VirtualizedGrid;
