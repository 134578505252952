import React from 'react';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';

import SongArtistNames from '../SongArtistNames';
import SongImage from 'components/image/SongImage';
import SongIndex from 'components/events/Timeline/TimelineSongsBlock/SongIndex';
import useTimelineSongStyles from 'components/events/Timeline/TimelineSongsBlock/TimelineSong/style';

import { SongProps } from './interfaces';
import useStyles from './style';

const Song = <T extends Nullable<SongBase>>({
  onClick,
  song,
  index,
  className,
  extra,
}: SongProps<T>) => {
  const timelineSongClasses = useTimelineSongStyles();
  const classes = useStyles();

  return (
    <div
      className={classNames('song', timelineSongClasses.song, classes.song, className)}
      onClick={() => onClick?.(song)}
    >
      <SongImage song={song} className={timelineSongClasses.songImage} isLazy={false} />
      {isNumber(index) ? (
        <SongIndex index={index + 1} className={timelineSongClasses.songIndex} />
      ) : null}
      <SongArtistNames song={song} />
      {!!extra ? <span className="extra">{extra}</span> : null}
    </div>
  );
};

export default Song;
