import React, { forwardRef, PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { LinkProps } from './interfaces';

const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>(
  ({ children, className, to, href, ...rest }, ref) => {
    const baseClassName = classNames('link', className);

    const { location } = useHistory();

    if (to) {
      return (
        <RouterLink
          className={baseClassName}
          to={{ pathname: to, state: { prevPath: location?.pathname } }}
          ref={ref}
        >
          {children}
        </RouterLink>
      );
    }

    return (
      <a className={baseClassName} href={href} ref={ref} {...rest}>
        {children}
      </a>
    );
  }
);

export default Link;
