import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { createMusicKitScript } from '../../services/appleMusic/helpers';
import { openErrorNotification } from 'graphql/hooks/common';

interface MusicKitParams {
  developerToken?: string;
  onMusicToken?: (token: string) => void;
  onMusicTokenFailed?: (error: Error) => void;
}

interface MusicKitReturn {
  loaded: boolean;
  connect: () => void;
  disconnect: () => void;
}

export const useMusicKit = ({
  developerToken,
  onMusicToken,
  onMusicTokenFailed,
}: MusicKitParams): MusicKitReturn => {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState<boolean>(false);

  const handleMusicKitLoaded = () => {
    setLoaded(true);
  };

  useEffect(() => {
    document.addEventListener('musickitloaded', handleMusicKitLoaded);

    return () => {
      document.removeEventListener('musickitloaded', handleMusicKitLoaded);
    };
  }, []);

  useEffect(() => {
    const script: HTMLScriptElement = createMusicKitScript();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (loaded && developerToken) {
      MusicKit.configure({ developerToken });
    }
  }, [loaded, developerToken]);

  const connect = (): void => {
    if (loaded && developerToken) {
      MusicKit.getInstance()
        .authorize()
        .then(token => {
          if (onMusicToken) {
            return onMusicToken(token);
          }
        })
        .catch(e => {
          openErrorNotification(t('pleaseReconnectAppleMusic'));

          if (onMusicTokenFailed) {
            return onMusicTokenFailed(new Error(e));
          }
        });
    }
  };

  const disconnect = (): void => {
    MusicKit.getInstance().unauthorize();
  };

  return {
    connect,
    disconnect,
    loaded,
  };
};
