import React, { FC, useRef } from 'react';

import SectionNotes from './SectionNotes';

import useStyles from './style';

const Content: FC = () => {
  const classes = useStyles();

  const wrapperRef = useRef<Nullable<HTMLDivElement>>(null);

  return (
    <div className={classes.content} ref={wrapperRef}>
      <SectionNotes />
    </div>
  );
};

export default Content;
