import { RefetchQuery, SectionSongIdeasListQueries } from '../../types/graphql';

export const loadMoreSongIdeas = (
  queryKey: string,
  loading: boolean,
  songIdeasData: SongIdeasResponseData,
  fetchMore: Function,
  variables: SongIdeasListVariables
) => {
  return (
    !loading &&
    songIdeasData.next &&
    fetchMore({
      variables: {
        ...variables,
        pagination: songIdeasData.next,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prev;

        return {
          [queryKey]: {
            ...prev[queryKey],
            songIdeas: [...prev[queryKey].songIdeas, ...fetchMoreResult[queryKey].songIdeas],
            next: fetchMoreResult[queryKey].next,
          },
        };
      },
    })
  );
};

const isViboSongIdeasResponse = (v: any): v is ViboSongIdeasResponse => {
  return v && v.getViboSongIdeas !== undefined;
};

const isCompanySongIdeasResponse = (v: any): v is CompanySongIdeasResponse => {
  return v && v.getCompanySongIdeas !== undefined;
};

const isUserSongIdeasResponse = (v: any): v is UserSongIdeasResponse => {
  return v && v.getUserSongIdeas !== undefined;
};

const isTemplateSectionViboSongIdeasResponse = (
  v: any
): v is TemplateSectionViboSongIdeasResponse => {
  return v && v.getTemplateSectionViboSongIdeas !== undefined;
};

const isTemplateSectionCompanySongIdeasResponse = (
  v: any
): v is TemplateSectionCompanySongIdeasResponse => {
  return v && v.getTemplateSectionCompanySongIdeas !== undefined;
};

const isTemplateSectionUserSongIdeasResponse = (
  v: any
): v is TemplateSectionUserSongIdeasResponse => {
  return v && v.getTemplateSectionUserSongIdeas !== undefined;
};

const isTemplateSectionSongIdeasResponse = (v: any): v is TemplateSectionSongIdeasResponse => {
  return v && v.getTemplateSectionSongIdeas !== undefined;
};

const isEventSectionViboSongIdeasResponse = (v: any): v is EventSectionViboSongIdeasResponse => {
  return v && v.getEventSectionViboSongIdeas !== undefined;
};

const isFavoriteSectionViboSongIdeasResponse = (
  v: any
): v is FavoriteSectionViboSongIdeasResponse => {
  return v && v.getFavoriteSectionViboSongIdeas !== undefined;
};

const isEventSectionCompanySongIdeasResponse = (
  v: any
): v is EventSectionCompanySongIdeasResponse => {
  return v && v.getEventSectionCompanySongIdeas !== undefined;
};

const isFavoriteSectionCompanySongIdeasResponse = (
  v: any
): v is FavoriteSectionCompanySongIdeasResponse => {
  return v && v.getFavoriteSectionCompanySongIdeas !== undefined;
};

const isEventSectionUserSongIdeasResponse = (v: any): v is EventSectionUserSongIdeasResponse => {
  return v && v.getEventSectionUserSongIdeas !== undefined;
};

const isFavoriteSectionUserSongIdeasResponse = (
  v: any
): v is FavoriteSectionUserSongIdeasResponse => {
  return v && v.getFavoriteSectionUserSongIdeas !== undefined;
};

const isEventSectionSongIdeasResponse = (v: any): v is EventSectionSongIdeasResponse => {
  return v && v.getEventSectionSongIdeas !== undefined;
};

const isFavoriteSectionSongIdeasResponse = (v: any): v is FavoriteSectionSongIdeasResponse => {
  return v && v.getFavoriteSectionSongIdeas !== undefined;
};

export const getSongIdeasData = (data: any): SongIdeasResponseData => {
  if (isViboSongIdeasResponse(data)) {
    return data.getViboSongIdeas;
  }

  if (isCompanySongIdeasResponse(data)) {
    return data.getCompanySongIdeas;
  }

  if (isUserSongIdeasResponse(data)) {
    return data.getUserSongIdeas;
  }

  if (isTemplateSectionViboSongIdeasResponse(data)) {
    return data.getTemplateSectionViboSongIdeas;
  }

  if (isTemplateSectionCompanySongIdeasResponse(data)) {
    return data.getTemplateSectionCompanySongIdeas;
  }

  if (isTemplateSectionUserSongIdeasResponse(data)) {
    return data.getTemplateSectionUserSongIdeas;
  }

  if (isTemplateSectionSongIdeasResponse(data)) {
    return data.getTemplateSectionSongIdeas;
  }

  if (isEventSectionViboSongIdeasResponse(data)) {
    return data.getEventSectionViboSongIdeas;
  }

  if (isEventSectionCompanySongIdeasResponse(data)) {
    return data.getEventSectionCompanySongIdeas;
  }

  if (isEventSectionUserSongIdeasResponse(data)) {
    return data.getEventSectionUserSongIdeas;
  }

  if (isEventSectionSongIdeasResponse(data)) {
    return data.getEventSectionSongIdeas;
  }

  if (isFavoriteSectionViboSongIdeasResponse(data)) {
    return data.getFavoriteSectionViboSongIdeas;
  }

  if (isFavoriteSectionCompanySongIdeasResponse(data)) {
    return data.getFavoriteSectionCompanySongIdeas;
  }

  if (isFavoriteSectionUserSongIdeasResponse(data)) {
    return data.getFavoriteSectionUserSongIdeas;
  }

  if (isFavoriteSectionSongIdeasResponse(data)) {
    return data.getFavoriteSectionSongIdeas;
  }

  return {
    next: null,
    totalCount: 0,
    songIdeas: [],
  };
};

export const getSectionSongIdeasRefetchQueries = (
  listQueries: SectionSongIdeasListQueries,
  variables: SectionSongIdeasListVariables,
  isChildDj: boolean
): RefetchQuery<SectionSongIdeasListVariables>[] => {
  const refetchQueries: RefetchQuery<SectionSongIdeasListVariables>[] = [
    {
      query: listQueries.user.query,
      variables,
    },
    {
      query: listQueries.vibo.query,
      variables,
    },
    {
      query: listQueries.section.query,
      variables,
    },
  ];

  if (isChildDj) {
    refetchQueries.push({
      query: listQueries.company.query,
      variables,
    });
  }

  return refetchQueries;
};

export const getSectionSongIdeasSongsTitle = (
  songIdeasTitle: Maybe<string>,
  sectionTitle: Maybe<string>,
  sectionLabel: string
): string => {
  return [songIdeasTitle, sectionTitle].filter(v => v).join(` | ${sectionLabel} - `) || 'Songs';
};

export const getRedirectPlaylistLink = (id?: string) => {
  return !!id ? `/songIdeas/v/${id}` : '/songIdeas/vibo';
};
