import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  infoPanel: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    height: '80px',
    bottom: 0,
    textAlign: 'left',
    alignItems: 'center',
    zIndex: 10,
    background: theme.palette.background.paper,
    borderRadius: 0,
    border: 0,
    borderTop: `1px solid ${theme.palette.primary.light}`,
    padding: '8px 15px',
    justifyContent: 'center',

    '&.warning': {
      borderTop: `4px  solid ${theme.palette.warning.main}`,
    },

    '& .viboButton': {
      minWidth: '250px',
    },
  },

  text: {
    color: theme.palette.text.primary,
    maxWidth: '755px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '19px',
    margin: '0 90px 0 0',

    '& a': {
      marginLeft: '5px',
    },
  },

  closeButton: {
    position: 'initial',
    maxWidth: '320px',
    minWidth: '290px',

    '& button': {
      width: '100%',
    },
  },
}));

export default useStyles;
