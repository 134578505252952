import React, { useCallback, useState, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import update from 'immutability-helper';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import {
  AutoSizer,
  InfiniteLoader,
  List,
  ListRowRenderer,
  WindowScroller,
} from 'react-virtualized';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from 'react-beautiful-dnd';

import { usePrapareData } from 'graphql/hooks/virtualizeList';

import { DragOptionProps, VirtualizedListDndProps } from './interfaces';

import useStyles from './style';

const VirtualizedListDnd = <T extends {}>({
  className,
  scrollId,
  droppableId,
  data,
  rowHeights,
  gaps,
  totalRowsCount,
  loadMore,
  overscanRowCount,
  rowRenderer,
  onDropCallback,
  destroyOnUnmount = false,
  isDragDisabled = false,
}: VirtualizedListDndProps<T>) => {
  const classes = useStyles();

  const [key, setKey] = useState('');

  const { rowHeight, scrollElement, preparedContent, rowCount, isRowLoaded } = usePrapareData({
    rowRenderer,
    data,
    scrollId,
    rowHeights,
    gaps,
  });

  const getRow: ListRowRenderer = ({ index, style }) => {
    const id = get(data[index], '_id', '') as string;

    return (
      <Draggable key={id} draggableId={id} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <DragOption provided={provided} snapshot={snapshot} index={index} style={style} />
        )}
      </Draggable>
    );
  };

  const onDragEnd: OnDragEndResponder = useCallback(
    ({ destination, source }) => {
      const removedIndex = source.index;
      const addedIndex = destination?.index;

      if (isNumber(removedIndex) && isNumber(addedIndex)) {
        const draggedOption = data[removedIndex];
        const newData = update(data, {
          $splice: [
            [removedIndex, 1],
            [addedIndex, 0, draggedOption],
          ],
        });

        onDropCallback?.(newData, { removedIndex, addedIndex });
      }
    },
    [data]
  );

  const DragOption = ({ snapshot, provided, index, style }: DragOptionProps) => {
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={classNames('virtualized-row', {
          dragging: snapshot.isDragging,
        })}
        style={{ ...provided.draggableProps.style, ...style }}
        ref={provided.innerRef}
      >
        {preparedContent[index]}
      </div>
    );
  };

  useEffect(() => {
    if (destroyOnUnmount) {
      setKey(`${Math.random()}`);
    }
  }, []);

  return useMemo(
    () => (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          renderClone={(provided, snapshot, rubric) => (
            <DragOption provided={provided} snapshot={snapshot} index={rubric.source.index} />
          )}
          droppableId={droppableId}
          mode="virtual"
        >
          {droppableProvided => (
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={() => loadMore?.()}
              rowCount={totalRowsCount}
            >
              {({ onRowsRendered }) => (
                <WindowScroller scrollElement={scrollElement}>
                  {({ height, isScrolling, scrollTop }) => (
                    <AutoSizer
                      disableHeight
                      className={classNames(
                        'virtualizedList dnd',
                        classes.virtualizedList,
                        className
                      )}
                    >
                      {({ width }) => (
                        <List
                          ref={ref => {
                            if (ref) {
                              const Parent = ReactDOM.findDOMNode(ref);

                              if (Parent instanceof HTMLElement) {
                                droppableProvided.innerRef(Parent);
                              }
                            }
                          }}
                          onRowsRendered={onRowsRendered}
                          rowRenderer={getRow}
                          scrollTop={scrollTop}
                          isScrolling={isScrolling}
                          height={height}
                          width={width}
                          rowCount={rowCount}
                          rowHeight={rowHeight}
                          overscanRowCount={overscanRowCount}
                          autoHeight
                        />
                      )}
                    </AutoSizer>
                  )}
                </WindowScroller>
              )}
            </InfiniteLoader>
          )}
        </Droppable>
      </DragDropContext>
    ),
    [data, key, rowHeight]
  );
};

export default VirtualizedListDnd;
