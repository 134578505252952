import React, { FC, useContext } from 'react';

import Button from 'vibo-ui/Button';
import { DeepLinkContext } from 'components/context/DeepLinkContext';

import { DeepLinkActionButtonProps } from './interfaces';

const DeepLinkActionButton: FC<DeepLinkActionButtonProps> = ({ loading, children }) => {
  const { action } = useContext(DeepLinkContext);

  return (
    <Button
      onClick={() => action?.()}
      loading={loading}
      disabled={loading}
      displayType="primary"
      type="submit"
      shape="round"
      size="lg"
    >
      {children}
    </Button>
  );
};

export default DeepLinkActionButton;
