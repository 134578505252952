import { cloneElement, ReactElement, FC } from 'react';

import { WithTimelineSectionProps } from './interfaces';

const WithTimelineSection: FC<WithTimelineSectionProps> = ({
  children,
  allowedTypes,
  exludedTypes,
  section,
  ...rest
}) => {
  const sctionType = section.type;

  if (
    (!allowedTypes?.includes(sctionType) && !exludedTypes) ||
    exludedTypes?.includes(sctionType)
  ) {
    return null;
  }

  return !!children ? cloneElement(children as ReactElement, rest) : null;
};

export default WithTimelineSection;
