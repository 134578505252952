import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Classes } from 'jss';

import { getGradientClass } from 'services/common/gradientHelper';

import { GradientCoverProps } from './interfaces';

import useStyles, { useGradientStyles } from './style';

const GradientCover: FC<GradientCoverProps> = ({ className, children, token = '' }) => {
  const classes = useStyles();

  const gradientClasses: Classes<GradientClasses> = useGradientStyles();

  const gradientClass: GradientClasses = useMemo(
    () => getGradientClass(token || Date.now().toString()),
    [token]
  );

  return (
    <div
      className={classNames(
        'gradient',
        classes.viboGradient,
        gradientClasses[gradientClass],
        className
      )}
    >
      {children}
    </div>
  );
};

export default GradientCover;
