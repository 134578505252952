import React, { FC, useMemo } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import WithRole from '../../user/WithRole';
import SearchInput from 'vibo-ui/SearchInput';
import EventTitle from '../Timeline/EventTitle';
import JoinEventButton from '../../buttons/JoinEventButton';
import PageContentHeader from '../../common/PageContentHeader';
import CreateEventButton from '../../buttons/CreateEventButton';

import { EVENT_TYPES_LABEL } from './constants';

import { UserRole } from '../../../types/enums';
import { EventsContentHeaderProps } from './interfaces';

const EventsContentHeader: FC<EventsContentHeaderProps> = ({ eventsCount, eventsType }) => {
  const { t } = useTranslation();

  const eventTypeLabel = useMemo(() => get(EVENT_TYPES_LABEL, eventsType), [eventsType]);

  return (
    <>
      <PageContentHeader
        extra={
          <>
            <SearchInput placeholder={t('searchEventsLocation')} />
            <WithRole roles={[UserRole.dj]}>
              <CreateEventButton />
            </WithRole>
            <WithRole roles={[UserRole.user]}>
              <JoinEventButton />
            </WithRole>
          </>
        }
      >
        <EventTitle
          name={t('youHaveAmountEvents', {
            count: eventsCount,
            type: eventTypeLabel,
          })}
        />
      </PageContentHeader>
    </>
  );
};

export default EventsContentHeader;
