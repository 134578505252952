import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SortFilterPopover from 'components/events/Timeline/TimelineSongsBlock/TimelineSongsHeader/SortFilterPopover';
import { SortContext } from 'components/context/SortContext';

import { SONGS_SORT_OPTIONS } from 'services/constants';
import { getSongsSortOptionsLabel } from 'services/common/getTransaltedLabel';

import { SimpleOption } from 'vibo-ui/Select';

const Sort: FC = () => {
  const { t } = useTranslation();

  const { sort, setSort } = useContext(SortContext);

  const currentOption = SONGS_SORT_OPTIONS.find(
    ({ value }) => value?.field === sort?.field && value?.direction === sort?.direction
  );

  const options = useMemo(
    () =>
      SONGS_SORT_OPTIONS.map(option => ({
        label: t(getSongsSortOptionsLabel(option.key)),
        icon: option.Icon,
        value: option.key,
      })),
    []
  );

  const handleSort = useCallback(
    option => {
      const { value } = option as SimpleOption;

      const sortOption = SONGS_SORT_OPTIONS.find(({ key }) => key === value);

      if (!!sortOption) {
        setSort(sortOption.value);
      }
    },
    [sort]
  );

  return (
    <SortFilterPopover
      value={currentOption?.key}
      options={options}
      onChange={handleSort}
      prefixText={t('sort')}
    />
  );
};

export default Sort;
