import React, { FC, PropsWithChildren, ReactElement } from 'react';
import StepWizard from 'react-step-wizard';
import classNames from 'classnames';

import { customStyles } from './constants';

import { WizardProps } from './interfaces';

import useStyles from './style';

const Wizard: FC<PropsWithChildren<WizardProps>> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <StepWizard
      {...rest}
      className={classNames('viboWizard', classes.viboWizard, rest.className)}
      transitions={customStyles}
      isLazyMount
    >
      {children as ReactElement[]}
    </StepWizard>
  );
};

export default Wizard;
