const isMyTemplatesResponse = (v: any): v is MyTemplatesResponse => {
  return v && v.getMyTemplates !== undefined;
};

const isViboTemplatesResponse = (v: any): v is ViboTemplatesResponse => {
  return v && v.getViboTemplates !== undefined;
};

const isCompanyTemplatesResponse = (v: any): v is CompanyTemplatesResponse => {
  return v && v.getCompanyTemplates !== undefined;
};

export const getTemplatesData = (data: any): TemplatesListData => {
  if (isMyTemplatesResponse(data)) {
    return data.getMyTemplates;
  }

  if (isViboTemplatesResponse(data)) {
    return data.getViboTemplates;
  }

  if (isCompanyTemplatesResponse(data)) {
    return data.getCompanyTemplates;
  }

  return {
    templates: [],
    next: null,
    totalCount: 0,
  };
};

export const getInitialUpdateTemplateValues = (template: Template): UpdateTemplateForm => {
  return {
    title: template.title,
    typeId: template.typeId,
    daysCountBeforeLock: template.daysCountBeforeLock,
    settings: template.settings,
    emailMessage: template.emailMessage,
    textMessage: template.textMessage,
    location: template.location,
    arrivalTime: template.arrivalTime,
    startTime: template.startTime,
    endTime: template.endTime,
  };
};
