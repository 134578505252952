import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  filePlayStats: {
    width: '110px',
    letterSpacing: '0.38px',
  },

  header: {
    fontWeight: 500,
    marginBottom: '4px',
  },

  stat: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',

    '& svg': {
      width: '16px',
      height: '16px',
      marginRight: '4px',
    },

    '& span': {
      color: theme.palette.text.secondary,
      fontSize: '14px',
    },
  },
}));

export default useStyles;
