import React, { FC, createContext, useState, PropsWithChildren } from 'react';

import { WizardStepProps } from 'vibo-ui/Wizard/interfaces';

import { WizardStateResponse } from './interfaces';

export const WizardContext = createContext<WizardStateResponse>(undefined!);

export const WizardState: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [wizardRefProps, setWizardRefProps] = useState<Nullable<WizardStepProps>>(null);

  return (
    <WizardContext.Provider value={{ wizardRefProps, setWizardRefProps }}>
      {children}
    </WizardContext.Provider>
  );
};
