import * as Yup from 'yup';

import i18n from 'i18n';
import { MAX_EVENT_TITLE_LENGTH } from 'services/constants';

export const updateTemplateValidationSchema = Yup.object().shape({
  title: Yup.string()
    .max(
      MAX_EVENT_TITLE_LENGTH,
      i18n.t('templateTitleShouldCharactersMax', { maxLength: MAX_EVENT_TITLE_LENGTH })
    )
    .trim()
    .required(i18n.t('provideTemplateTitle')),
});
