import React, { FC } from 'react';
import classNames from 'classnames';

import ContentArea from '../../../common/ContentArea';

import { SectionBlockContentProps } from './interfaces';

import useStyles from './style';

const SectionBlockContent: FC<SectionBlockContentProps> = ({
  isShown,
  toggleShow,
  children,
  className,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!isShown) {
      toggleShow();
    }
  };

  return (
    <ContentArea
      onClick={handleClick}
      className={classNames(
        'sectionBlockContent',
        {
          [classes.notShownContent]: !isShown,
        },
        classes.sectionBlockContent,
        className
      )}
    >
      {children}
    </ContentArea>
  );
};

export default SectionBlockContent;
