import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  templateInvite: {
    '& .viboScrollbar': {
      maxHeight: 'calc(100vh - 110px)!important',

      '@media screen and (min-width: 1441px)': {
        maxHeight: 'calc(100vh - 130px)!important',
      },
    },

    '& .contentArea': {
      display: 'flex',
      flexDirection: 'column',

      '&:not(:last-child)': {
        marginBottom: '12px',
      },
    },
  },

  invitation: {
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'row',

    '& > label': {
      width: '100%',
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '27px',
      margin: '0 0 20px 0',
    },

    '& .viboIcon': {
      margin: '0 8px 0 0',
    },
  },
}));

export default useStyles;
