import momentTz from 'moment-timezone';
import moment from '../moment';

export const getDateAsTimeFromNow = (date: Maybe<Date>): string => {
  return moment(date).fromNow();
};

export const getFormattedDateWithFullMonthName = (date: Date | string): string =>
  moment(date).format('LL');

export const getTimezoneOffset = (): number => new Date().getTimezoneOffset() * 60;

export const getDateWithTimezone = (
  date?: Nullable<string>,
  timezone?: string,
  format?: string
) => {
  if (date) {
    return timezone
      ? momentTz(date)
          .tz(timezone)
          .format(format || 'YYYY-MM-DD')
      : momentTz(date).format(format || 'YYYY-MM-DD');
  }

  return null;
};
