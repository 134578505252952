import React, { FC } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, useFormikContext } from 'formik';

import Input from 'vibo-ui/Input';

import { FormInputProps } from './interfaces';

const FormInput: FC<FormInputProps> = ({ name, validate, ...rest }) => {
  const { t } = useTranslation();

  const { touched, setFieldValue } = useFormikContext();

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        const { error = '' } = meta;
        const isTouched = get(touched, field.name);

        return (
          <Input
            {...field}
            errors={[rest.alwaysShowError || isTouched ? t(error) : '']}
            {...rest}
            onChange={(_, targetValue) => {
              setFieldValue(
                field.name,
                !!targetValue || rest.type !== 'number' ? targetValue : null
              );
            }}
          />
        );
      }}
    </Field>
  );
};

export default FormInput;
