import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  scannerPage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    background: theme.palette.background.default,

    '& > .container': {
      '& > .containerContent': {
        '& .blocks': {
          gap: '20px',
          display: 'flex',
          flexDirection: 'row',

          '& > .contentArea': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '14px 12px 12px 12px',

            '@media screen and (min-width: 1441px)': {
              padding: '24px 24px 16px 24px',
            },
          },
        },
      },

      '@media screen and (min-width: 1441px)': {
        height: 'calc(100% - 48px)',
      },
    },

    '& .blockTitle': {
      width: '100%',
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '19.5px',
      margin: '0 0 14px 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 40px 0',
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  devicesScrollbar: {
    height: 'auto',
    maxHeight: '250px',
    margin: '0 0 auto 0',

    '& + .viboButton': {
      marginTop: '40px',
    },

    '& .device': {
      '&:not(:last-child)': {
        margin: '0 0 16px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 24px 0',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      maxHeight: '300px',
    },
  },

  emptyDeviceListText: {
    paddingTop: '10px',
    fontStyle: 'italic',
    color: theme.palette.text.primary,
  },

  deviceName: {
    fontSize: '13px',
    lineHeight: '19.56px',
    color: theme.palette.text.secondary,
    margin: '0 0 2px 0',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 4px 0',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  deviceDetails: {
    fontSize: '13px',
    lineHeight: '19.56px',
    color: theme.palette.text.primary,

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  scannerDescription: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '19.5px',
    padding: '0 0 20px 0',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  scannerSteps: {
    margin: '15px 0 0 0',
    padding: '0 0 0 14px',
    fontWeight: 'bold',

    '@media screen and (min-width: 1441px)': {
      margin: '30px 0 0 0',
      padding: '0 0 0 18px',
    },
  },

  downloadButtonContainer: {
    margin: 'auto 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    '& .viboButton': {
      '&:not(:last-child)': {
        margin: '0 0 12px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 16px 0',
        },
      },
    },
  },
}));

export default useStyles;
