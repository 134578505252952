import React, { FC, PropsWithChildren, ElementType, useMemo } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { components } from 'react-select';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { CustomOptionProps } from './interfaces';

import useStyles from './style';

const Option: FC<PropsWithChildren<CustomOptionProps>> = ({
  renderSelectedOption,
  children,
  ...rest
}) => {
  const classes = useStyles();

  const SelectedOptionWrapper = useMemo(
    () => (!!renderSelectedOption ? renderSelectedOption : null),
    [renderSelectedOption]
  ) as ElementType;

  const icon = useMemo(() => get(rest.data, 'icon', null), []);

  const dataClassName = useMemo(() => get(rest.data, 'className', ''), []);

  const asTitle = useMemo(() => get(rest.data, 'asTitle', undefined), [rest.data]);

  return (
    <components.Option
      {...rest}
      className={classNames(rest.className, dataClassName, {
        asTitle,
      })}
      isDisabled={asTitle || rest.isDisabled}
    >
      {!!renderSelectedOption && rest.isSelected ? (
        <SelectedOptionWrapper>{children}</SelectedOptionWrapper>
      ) : (
        <>
          {!!icon ? <Icon icon={icon} /> : null}
          <span className={classNames('optionContent', classes.optionContent)}>{children}</span>
          {rest.isSelected ? (
            <Icon className="checkIcon" icon={IconmoonFont['checkedCircleFilled-16']} />
          ) : null}
        </>
      )}
    </components.Option>
  );
};

export default Option;
