import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { SimpleOption } from 'vibo-ui/Select';
import { EventContext } from 'components/context/EventContext';
import { EventContactContext } from 'components/context/EventContactContext';

import { useMe } from 'graphql/hooks/user';
import { contactRoleOptions } from '../constants';

import { ContactRoleSelectProps } from '../interfaces';

import useStyles from '../style';

const ContactRoleSelect: FC<ContactRoleSelectProps> = ({ contact }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { canAddGuest, isEventHost, isEventDj } = useContext(EventContext);
  const { handleUpdateContact } = useContext(EventContactContext);

  const { user } = useMe();

  const isMe = contact.email === user?.email;

  const options = useMemo(
    () =>
      contactRoleOptions.map(option => ({
        label: t(option.value),
        ...option,
      })),
    [contactRoleOptions]
  );

  const handleChange = useCallback(option => {
    const { value } = option as SimpleOption;

    handleUpdateContact({
      role: value,
    });
  }, []);

  return (
    <>
      {isEventDj || (isEventHost && !isMe && canAddGuest) ? (
        <Select
          value={contact.role}
          onChange={handleChange}
          components={{
            Option: props => {
              return <Select.Option {...props}>{props.label}</Select.Option>;
            },
          }}
          options={options}
          menuPortalTarget={document.body}
          className={classes.contactRoleSelect}
          styleModificator="asLink"
        />
      ) : (
        <div>{t(contact.role)}</div>
      )}
    </>
  );
};

export default ContactRoleSelect;
