import { FC } from 'react';

import Text from 'components/questions/types/Text';
import Radio from 'components/questions/types/Radio';
import Checkbox from 'components/questions/types/Checkbox';
import PdfAnswer from 'components/questions/types/PdfAnswer';
import PhoneAnswer from 'components/questions/types/PhoneAnswer';
import Description from 'components/questions/types/Description';
import ImageAnswer from 'components/questions/types/ImageAnswer';
import LocationAnswer from 'components/questions/types/LocationAnswer';

import { QuestionType } from 'types/enums';

const questionBodyComponents = {
  [QuestionType.checkbox]: Checkbox,
  [QuestionType.radio]: Radio,
  [QuestionType.text]: Text,
  [QuestionType.header]: Description,
  [QuestionType.select]: Description,
  [QuestionType.imageAnswer]: ImageAnswer,
  [QuestionType.pdfAnswer]: PdfAnswer,
  [QuestionType.locationAnswer]: LocationAnswer,
  [QuestionType.phoneNumberAnswer]: PhoneAnswer,
};

export function getQuestionBody(type: QuestionType): Nullable<FC> {
  return questionBodyComponents[type] || null;
}
