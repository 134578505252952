import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  noTemplates: {
    padding: '50px 0 0 0',

    '& .description': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& > div': {
        maxWidth: '206px',
        textAlign: 'center',
      },
    },

    '& .viboButton': {
      minWidth: '256px',
      margin: '16px 0 0 0',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '150px 0 0 0',
    },
  },
}));

export default useStyles;
