import React, { FC } from 'react';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';

import { ReactComponent as WelcomeViboIcon } from '../../../resources/img/svg/welcomeVibo.svg';

import useAuthStyles from '../../../resources/styles/auth/style';
import useStyles from './style';

const SignInWithLinkContent: FC = ({ children }) => {
  const authClasses = useAuthStyles();
  const classes = useStyles();

  return (
    <div className={classNames('signInWithLink', classes.signInWithLink)}>
      <Icon className={classes.welcomeLogo} icon={WelcomeViboIcon} disableAutoFontSize />
      <div className={classNames('formContainer', authClasses.formContainer)}>{children}</div>
    </div>
  );
};

export default SignInWithLinkContent;
