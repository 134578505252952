export const createYoutubeUrlById = (id: string): string => {
  return `https://www.youtube.com/watch?v=${id}`;
};

export const isLoomLink = (link?: string) => {
  if (!!link) {
    return link.includes('www.loom.com');
  }

  return false;
};
