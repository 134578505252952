import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  djSortPreview: {
    '& .viboImage': {
      ...theme.mixins.getStaticIconSize?.(16),
    },

    '& .djName': {
      color: 'inherit',
    },
  },
}));

export default useStyles;
