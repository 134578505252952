import React, { FC, useContext } from 'react';

import Switch from 'vibo-ui/Switch';
import { AppleMusicAuthContext } from '../../../context/AppleMusicAuthContext';

const AppleMusicSwitcher: FC = () => {
  const {
    appleMusicLoading,
    isAppleMusicConnected,
    connectAppleMusic,
    disconnectAppleMusic,
  } = useContext(AppleMusicAuthContext);

  return (
    <Switch
      onChange={() =>
        isAppleMusicConnected
          ? disconnectAppleMusic && disconnectAppleMusic()
          : connectAppleMusic && connectAppleMusic()
      }
      checked={isAppleMusicConnected}
      disabled={appleMusicLoading}
    />
  );

  // return (
  //   <Switch
  //     checkedChildren={<Icon type="check" />}
  //     unCheckedChildren={<Icon type="close" />}
  //     checked={isAppleMusicConnected}
  //     disabled={appleMusicLoading}
  //     className="borderWhite"
  //     onChange={() =>
  //       isAppleMusicConnected
  //         ? disconnectAppleMusic && disconnectAppleMusic()
  //         : connectAppleMusic && connectAppleMusic()
  //     }
  //   />
  // );
};

export default AppleMusicSwitcher;
