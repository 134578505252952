import React, { forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';

import { DefaultImagePlaceholderProps } from './interfaces';

import useStyles from './style';

const DefaultImagePlaceholder = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DefaultImagePlaceholderProps>
>(({ children }, ref) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={classNames('imagePlaceholder intrinsic-item', classes.imagePlaceholder)}
    >
      {children}
    </div>
  );
});

export default DefaultImagePlaceholder;
