export const SPOTIFY_SCOPES: string[] = [
  'user-read-private',
  'playlist-read-collaborative',
  'playlist-modify-public',
  'playlist-read-private',
  'playlist-modify-private',
  'user-read-email',
];

export const SPOTIFY_AUTH_URL: string = 'https://accounts.spotify.com/authorize';

export const openSpotifyAuthWindow: () => Nullable<Window> = () => {
  const clientId: string = process.env.REACT_APP_SPOTIFY_CLIENT_ID as string;
  const scope: string = encodeURIComponent(SPOTIFY_SCOPES.join(','));

  const redirectUri: string = encodeURIComponent(
    process.env.REACT_APP_SPOTIFY_REDIRECT_URL as string
  );

  const queryString: string = [
    ['response_type', 'code'],
    ['client_id', clientId],
    ['scope', scope],
    ['redirect_uri', redirectUri],
  ]
    .map(v => v.join('='))
    .join('&');

  const link = `${SPOTIFY_AUTH_URL}?${queryString}`;

  return window.open(link);
};

export const getTotalSelectedSongsForSections = (
  sectionIds: string[],
  sections: EventSection[]
): number =>
  sections.reduce((totalSelectedFiles: number, section: EventSection) => {
    return sectionIds.includes(section._id)
      ? totalSelectedFiles + section.songsCount
      : totalSelectedFiles;
  }, 0);
