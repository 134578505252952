import React, { FC } from 'react';

import Brick from '../Brick';

import useStyles from './style';

const YoutubePlayerPlaceholder: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.youtubePlayerPlaceholder}>
      <Brick className={classes.player} />
      <Brick className={classes.links} />
    </div>
  );
};

export default YoutubePlayerPlaceholder;
