import React, { FC } from 'react';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoQuestionsIcon } from '../../../resources/img/svg/emptyStateNoQuestions.svg';

const NoQuestions: FC = ({ children }) => (
  <EmptyState className="noQuestions" size="default" icon={EmptyStateNoQuestionsIcon}>
    {children}
  </EmptyState>
);

export default NoQuestions;
