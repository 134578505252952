import { CSSProperties, ReactNode } from 'react';

export interface ListProps<T extends {}> {
  draggable?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  data?: T[];
  id?: string;
  loading?: boolean;
  enableDragCursor?: boolean;
  layout?: ListLayout;
  draggableItemClassName?: string;
  lockAxis?: 'x' | 'y';
  onDragStartCallback?: () => void;
  onDropCallback?: (newData: T[], dragResponse: DragResponse) => void;
  renderItem?: (item: T, index: number, activeElement?: ActiveElementPayload<T>) => ReactNode;
  dragHandleSelector?: string;
  dragClass?: string;
  dropPlaceholder?: {
    className?: string;
    animationDuration?: number;
    showOnTop?: boolean;
  };
}

export interface DragResponse {
  removedIndex: number;
  addedIndex: number;
}

export interface ActiveElementPayload<T> {
  idx: number | undefined;
  element: T | undefined;
  isDragging: boolean;
}

export enum ListLayout {
  horizontal = 'horizontal',
  vertical = 'vertical',
}
