import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '../EmptyState';

import { ReactComponent as NotificationsBellIcon } from 'resources/img/svg/notificationsBell.svg';

const NoNotifications: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <EmptyState size="xs" icon={NotificationsBellIcon}>
      {t('noNewNotifications')}
      {children}
    </EmptyState>
  );
};

export default NoNotifications;
