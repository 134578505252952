import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'vibo-ui/Button';

import { FooterProps } from './interfaces';

import useStyles from './style';

const Footer: FC<FooterProps> = ({
  footerClassName,
  datePickerRef,
  footerClearText = 'TBD',
  footerOkText = 'Ok',
}) => {
  const classes = useStyles();

  const handleClose = () => datePickerRef.current?.setOpen(false);

  const handleClear = () => {
    datePickerRef.current?.clear?.();
    handleClose();
  };

  return (
    <div className={classNames('footer', classes.footer, footerClassName)}>
      <Button
        onClick={handleClear}
        className="footerClearBtn"
        displayType="bordered"
        shape="round"
        size="lg"
      >
        {footerClearText}
      </Button>
      <Button
        onClick={handleClose}
        className="footerOkBtn"
        displayType="primary"
        shape="round"
        size="lg"
      >
        {footerOkText}
      </Button>
    </div>
  );
};

export default Footer;
