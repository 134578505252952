import React, {
  forwardRef,
  Ref,
  useState,
  ChangeEventHandler,
  useEffect,
  PropsWithChildren,
} from 'react';
import classNames from 'classnames';
import isBoolean from 'lodash/isBoolean';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { CheckboxProps } from './interfaces';
import { MultiSelectChangeEvent } from 'vibo-ui/types/multiSelectOption';

import useInputStyles from 'resources/styles/inputs/style';
import useStyles from './style';

const Checkbox = forwardRef<HTMLInputElement, PropsWithChildren<CheckboxProps>>(
  (
    {
      children,
      className,
      indeterminate,
      style,
      onChange,
      checkedIcon = IconmoonFont['checkedFilled-16'],
      indeterminateIcon = IconmoonFont['indeterminateFilled-16'],
      uncheckedIcon = IconmoonFont['uncheckedOutlined-16'],
      ...rest
    },
    ref: Ref<HTMLInputElement>
  ) => {
    const inputClasses = useInputStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState<boolean>(rest.defaultChecked ?? false);

    const currentStateIcon = indeterminate
      ? indeterminateIcon
      : checked
      ? checkedIcon
      : uncheckedIcon;

    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
      const newValue = e.currentTarget.checked;

      setChecked(newValue);
      onChange?.((e as unknown) as MultiSelectChangeEvent);
    };

    useEffect(() => {
      if (isBoolean(rest.checked)) {
        setChecked(rest.checked);
      }
    }, [rest.checked]);

    return (
      <label
        className={classNames(
          'viboCheckbox',
          {
            [`disabled ${inputClasses.disabled}`]: rest.disabled,
          },
          classes.viboCheckbox,
          inputClasses.withOverlayCheckbox,
          className
        )}
        style={style}
      >
        <Icon
          icon={currentStateIcon}
          className={classNames({
            [`indeterminate ${classes.indeterminate}`]: indeterminate,
            [`checked ${inputClasses.checked}`]: !indeterminate && checked,
            [`unchecked ${inputClasses.unchecked}`]: !indeterminate && !checked,
          })}
        />
        <input
          className="hidden"
          onChange={handleChange}
          ref={ref}
          {...rest}
          checked={checked}
          type="checkbox"
        />
        {children ? <span className={inputClasses.content}>{children}</span> : null}
      </label>
    );
  }
);

export default Checkbox;
