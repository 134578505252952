import React, { FC } from 'react';
import get from 'lodash/get';
import { Field, FieldProps, useFormikContext } from 'formik';

import Textarea from 'vibo-ui/Textarea';

import { FormTextareaProps } from './interfaces';

const FormTextarea: FC<FormTextareaProps> = ({ name, validate, ...rest }) => {
  const { touched } = useFormikContext();

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const { error = '' } = meta;
        const isTouched = get(touched, field.name);

        return <Textarea {...field} errors={[isTouched ? error : '']} {...rest} />;
      }}
    </Field>
  );
};

export default FormTextarea;
