import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  questionIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },

  icon: {
    width: '14px',
    height: '14px',

    '@media screen and (min-width: 1441px)': {
      width: '16px',
      height: '16px',
    },
  },

  infoIcon: {
    color: theme.palette.warning.main,
  },

  unanswred: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
