import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from '../../form/inputs/FormInput';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { FormInputProps } from '../../form/inputs/FormInput/interfaces';

const PasswordInput: FC<FormInputProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <FormInput
      iconPrefix={<Icon icon={IconmoonFont['locked-16']} />}
      placeholder={t('password')}
      name="password"
      type="password"
      autoFocus
      {...rest}
    />
  );
};

export default PasswordInput;
