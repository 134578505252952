import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  sectionSongPreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexBasis: '332px',
    width: '100%',
    height: '28px',
    overflow: 'hidden',
    paddingLeft: '0',
    marginLeft: '6px',
  },

  imageContainer: {
    width: '28px',
    minWidth: '28px',
    height: '28px',
    marginRight: '4px',
    marginLeft: '1px',
  },
}));

export default useStyles;
