import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ContentArea from '../../../../components/common/ContentArea';
import { EventContext } from '../../../../components/context/EventContext';
import ContentBlockHeader from './ContentBlockHeader';

import { PDF_OPTIONS_TEXT } from '../../../../services/constants';

import { PdfSettingOptions } from '../../../../types/enums';

import useStyles from '../style';

const NotesContent: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { event } = useContext(EventContext);

  return (
    <div className={classes.contentBlock} data-spy-id={PdfSettingOptions.djNotes}>
      <ContentBlockHeader
        name={PDF_OPTIONS_TEXT[PdfSettingOptions.djNotes]}
        inputName="withDJNotes"
      />
      <ContentArea className={classes.notesContent} shadowed>
        {!!event?.note ? (
          <span dangerouslySetInnerHTML={{ __html: event?.note ?? '' }} />
        ) : (
          <abbr>{t('thereAreNoObjectYet', { object: t('notes').toLocaleLowerCase() })}</abbr>
        )}
      </ContentArea>
    </div>
  );
};

export default NotesContent;
