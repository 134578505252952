import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CreateButton from '../CreateButton';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { ButtonProps } from 'vibo-ui/Button';

const CreateTemplateButton: FC<ButtonProps> = ({ className }) => {
  const { t } = useTranslation();

  const { openModal } = useModal();

  const openCreateTemplateModal = useCallback(
    () =>
      openModal({
        key: Modals.createTemplate,
      }),
    []
  );

  return (
    <CreateButton
      onClick={openCreateTemplateModal}
      text={t('createNewTemplate')}
      className={className}
    />
  );
};

export default CreateTemplateButton;
