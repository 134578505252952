import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Header from 'components/common/Header';
import Container from 'components/common/Container';

import { SONG_ITEAS_PAGE_MENU_ITEMS } from 'services/constants';
import { getSelectedSongIdeasMenuKeys } from 'services/common/routesHelper';

const SongIdeasHeader: FC = () => {
  const { t } = useTranslation();

  const { push } = useHistory();

  return (
    <Header>
      <Container>
        <Menu
          getSelectedSidebarMenuKeys={getSelectedSongIdeasMenuKeys}
          styleModificator="siderTop"
          mode="horizontal"
        >
          {SONG_ITEAS_PAGE_MENU_ITEMS.map(({ name, route }) => (
            <Menu.Item onClick={() => push(`/songIdeas/${route}`)} key={route}>
              {`${t(name)}`}
            </Menu.Item>
          ))}
        </Menu>
      </Container>
    </Header>
  );
};

export default SongIdeasHeader;
