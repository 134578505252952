import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ProgressColumn: FC = () => {
  const { t } = useTranslation();

  return <div className="label">{t('planningProgress')}</div>;
};

export default ProgressColumn;
