import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon';
import SettingsItem from './SettingsItem';
import SpotifySwitcher from '../../components/user/common/SpotifySwitcher';
import SpotifyAuthProvider from '../../components/context/SpotifyAuthContext';

import { ReactComponent as SpotifyIcon } from '../../resources/img/svg/common/spotify.svg';

import useStyles from './style';

const SpotifyConnect: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={SpotifyIcon} className={classes.spotifyIcon} />
        {t('spotifyConnection')}
      </div>
      <SpotifyAuthProvider autoConnect={false}>
        <SpotifySwitcher />
      </SpotifyAuthProvider>
    </SettingsItem>
  );
};

export default SpotifyConnect;
