import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  templateTimeline: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& .timelineSongsBlock': {
      '& .sectionBlockContent': {
        display: 'none',
      },
    },

    '& .sectionTitle': {
      marginBottom: '4px',
    },

    '& .customSongTab': {
      position: 'relative',
      right: '0',
    },

    '& .customQuestion': {
      position: 'relative',
      right: '0',
    },

    '& .delete': {
      display: 'none',
      marginRight: '10px ',
      position: 'relative',
      zIndex: '10',
    },

    '& .error': {
      textAlign: 'end',
      color: theme.palette.error.main,
      position: 'absolute',
      paddingRight: '25px',
      right: '0',
    },

    '& .errorInput': {
      borderBottom: `1px solid ${theme.palette.error.main} !important`,
    },

    '& .limit': {
      textAlign: 'right',
      paddingRight: '25px',
      color: theme.palette.primary.main,
      display: 'none',
      position: 'absolute',
      right: '0',
    },

    '& .title': {
      fontSize: '16px',
      color: theme.palette.text.secondary,
    },

    '& .subTitle ': {
      fontSize: '16px',
      color: theme.palette.text.secondary,
    },
  },
}));

export default useStyles;
