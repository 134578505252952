import COLORS from '../colors';

import { EventUserType } from '../../types/enums';

export const columnsConfig: { [key in EventsTableColumn]: ColumnProps } = {
  title: {
    columnKey: 'title',
    field: 'title',
  },
  createdBy: {
    columnKey: 'createdBy',
    field: 'createdBy',
  },
  isPublic: {
    columnKey: '',
    field: 'isPublic',
  },
  users: {
    columnKey: 'tab.people',
    field: 'usersCount',
    rolesToShow: [EventUserType.dj, EventUserType.host],
  },
  songs: {
    columnKey: 'songs',
    field: 'playlistSize',
    rolesToShow: [EventUserType.dj, EventUserType.host],
  },
  planning: {
    columnKey: 'planning',
    field: 'progress',
    rolesToShow: [EventUserType.dj, EventUserType.host],
  },
  date: {
    columnKey: 'eventDate',
    field: 'date',
    className: 'dateColumn dateColumn--wide',
  },
  dateTemplate: {
    columnKey: 'dateCreated',
    field: 'createdAt',
    className: 'dateColumn dateColumn--wide',
  },
  type: {
    columnKey: 'dateCreated',
    field: 'type',
    className: 'typeColumn',
  },
  historyDate: {
    columnKey: 'eventDate',
    field: 'date',
    className: 'dateColumn dateColumn--wide',
  },
  lastModified: {
    columnKey: 'lastModified',
    field: 'updatedAt',
    className: 'dateColumn',
  },
  status: {
    columnKey: 'status',
    field: 'status',
    data: [
      { value: undefined, key: 'all', title: 'all' },
      { value: 1, key: 'pending', title: 'pending', color: COLORS.statusYellow },
      { value: 2, key: 'active', title: 'active', color: COLORS.statusGreen },
      { value: 3, key: 'locked', title: 'locked', color: COLORS.statusRed },
    ],
  },
  historyStatus: {
    columnKey: 'status',
    field: 'status',
    data: [
      { value: undefined, key: 'all', title: 'all' },
      { value: 'past', key: 'past', title: 'past', color: COLORS.statusBlack },
      {
        value: 'isDeleted',
        key: 'delete',
        title: 'deleted',
        color: COLORS.statusGrey,
      },
    ],
  },
  djs: {
    columnKey: 'dj_plural',
    field: 'djs',
  },
};

export const cellsFields: { [key in EventsTableColumn]: keyof EventListItem } = {
  users: 'usersCount',
  songs: 'playlistSize',
  planning: 'progress',
  date: 'date',
  title: 'title',
  historyDate: 'date',
  lastModified: 'lastUpdated',
  status: 'status',
  historyStatus: 'status',
  djs: 'djs',
  dateTemplate: 'dateTemplate',
  type: 'type',
  isPublic: 'isPublic',
  createdBy: 'createdBy',
};
