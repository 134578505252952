import { QuestionType } from 'types/enums';

const isTemplateSectionQuestionsResponse = (v: any): v is TemplateSectionQuestionsResponse => {
  return v && v.getTemplateSectionQuestionsV2 !== undefined;
};

const isFavoriteSectionQuestionsResponse = (v: any): v is FavoriteSectionQuestionsResponse => {
  return v && v.getFavoriteSectionQuestionsV2 !== undefined;
};

const isEventSectionQuestionsResponse = (v: any): v is EventSectionQuestionsResponse => {
  return v && v.getEventSectionQuestionsV2 !== undefined;
};

export const getQuestionsData = (data: any): QuestionsData => {
  if (isTemplateSectionQuestionsResponse(data)) {
    return data.getTemplateSectionQuestionsV2;
  }

  if (isFavoriteSectionQuestionsResponse(data)) {
    return data.getFavoriteSectionQuestionsV2;
  }

  if (isEventSectionQuestionsResponse(data)) {
    return data.getEventSectionQuestionsV2;
  }

  return {
    questions: [],
    progress: 0,
  };
};

export const hasQuestions = (section: TimelineSection) => {
  return section?.questionsCount > 0;
};

export const isQuestionMultiType = (type: QuestionType) =>
  type === QuestionType.checkbox || type === QuestionType.radio;
