import React, { cloneElement, ReactElement } from 'react';

import { useMe } from '../../../graphql/hooks/user';

import { UserRole } from '../../../types/enums';

interface WithRoleProps {
  roles: UserRole[];
  preventDefaultRoles?: boolean;
}

const WithRole: React.FC<WithRoleProps> = ({ roles, preventDefaultRoles, children, ...rest }) => {
  const { user } = useMe();

  if (
    !user ||
    ![...roles, preventDefaultRoles ? null : UserRole.admin]
      .filter(Boolean)
      .includes(user.role as UserRole)
  ) {
    return null;
  }

  return !!children ? cloneElement(children as ReactElement, rest) : null;
};

export default WithRole;
