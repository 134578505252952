import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '@rehooks/local-storage';

import Button from 'vibo-ui/Button';
import InfoPanel from '../../layouts/InfoPanel';

import { PRIVACY_POLICY_LINK } from 'services/constants';

const Cookies: FC = () => {
  const { t } = useTranslation();

  const [isCookiesAccepted, setIsCookiesAccepted] = useLocalStorage<boolean>(
    'isCookiesAccepted',
    false
  );

  return isCookiesAccepted ? null : (
    <InfoPanel
      text={
        <span>
          {t('cookiesMessage')}
          <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
            {t('privacyNotice')}
          </a>
        </span>
      }
    >
      <Button
        onClick={() => setIsCookiesAccepted(true)}
        displayType="primary"
        shape="round"
        size="lg"
      >
        {t('accept')}
      </Button>
    </InfoPanel>
  );
};

export default Cookies;
