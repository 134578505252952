export const formatPhoneNumber = (phone: string): string => `+${phone}`;

export const prepareUserPhone = (phone: string, countryData: PhoneCountry) => {
  const phoneCode: string = countryData.dialCode;
  const country: string = countryData.countryCode.toUpperCase();
  const phoneNumber: string = phone.replace(/[^0-9]+/g, '').replace(phoneCode, '');

  return {
    phoneCode,
    country,
    phoneNumber,
  };
};
