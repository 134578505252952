export interface SignUpLayoutProps {
  className?: string;
  onNextClick?: () => void;
  disabled?: boolean;
}

export interface SignUpFormik {
  email: string;
  emailCode: string;
  password: string;
  firstName: string;
  lastName: string;
  receiveInfo: boolean;
  photo: Nullable<string>;
  verifyToken: string;
}

export enum SignUpSteps {
  'checkEmail' = 'checkEmail',
  'signIn' = 'signIn',
  'confirmEmail' = 'confirmEmail',
  'createPassword' = 'createPassword',
  'userName' = 'userName',
  'addPicture' = 'addPicture',
  'plan' = 'plan',
  'invite' = 'invite',
  'dancing' = 'dancing',
}
