import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';

import { CommentPreviewProps } from './interfaces';

import useStyles from './style';

const CommentPreview = <T extends SectionSong>({
  commentPopupRef,
  song,
  className,
}: CommentPreviewProps<T>) => {
  const classes = useStyles();

  const previewRef = useRef<Nullable<HTMLDivElement>>(null);

  const handlePreviewClick = useCallback(e => {
    const toggle = commentPopupRef?.current?.getElementsByClassName(
      'viboToggleDropdown'
    )[0] as Maybe<HTMLDivElement>;

    e.stopPropagation();
    toggle?.click();
  }, []);

  return !!song?.comment ? (
    <div
      onClick={handlePreviewClick}
      title={song.comment}
      className={classNames('commentPreview', classes.commentPreview, className)}
      ref={previewRef}
    >
      {song.comment}
    </div>
  ) : null;
};

export default CommentPreview;
