import React, { FC } from 'react';

import PublicLabel from '../../../../../labels/PublicLabel';

const PublicLabelCell: FC<CellProps<WithPublic>> = ({ data }) =>
  data?.isPublic ? (
    <div className="publicLabelCell">
      <PublicLabel />
    </div>
  ) : null;

export default PublicLabelCell;
