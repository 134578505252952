import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  quillEditor: {
    color: theme.palette.text.primary,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '& .quill': {
      height: '100%',
    },

    '& .quillToolbar': {
      display: 'none',
    },

    '& .ql-editor': {
      paddingBottom: '40px',

      '& h1, h2, h3, h4, h5, h6': {
        fontWeight: '400',
      },

      '& a': {
        color: theme.palette.primary.main,
      },

      '&.ql-blank::before': {
        fontStyle: 'normal',
        color: theme.palette.text.hint,
        left: '12px',
      },
    },

    '& *': {
      fontFamily: 'Roboto',
    },

    '& .ql-container.ql-snow': {
      border: 'none',
      height: '100%',
    },

    '& .ql-tooltip': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
      borderRadius: '4px',
      zIndex: 101,
      color: theme.palette.text.primary,

      '&.ql-flip': {
        transform: 'none',
      },

      '& .ql-action, & .ql-remove, & .ql-preview': {
        color: theme.palette.primary.main,
      },
    },

    '& .ql-toolbar': {
      border: 'none',
      backgroundColor: theme.palette.background.default,
      padding: '10px 25px',
      width: '100%',
      margin: '0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 100,
      position: 'sticky',
      bottom: '0px',

      '&:after': {
        display: 'none',
      },

      '& button': {
        padding: '0 5px',
        color: theme.palette.text.secondary,
        width: 'auto',
        height: '16px',

        '& .ql-stroke': {
          stroke: `${theme.palette.text.secondary}`,
        },

        '&:hover': {
          color: theme.palette.primary.main,

          '& .ql-stroke': {
            stroke: `${theme.palette.primary.main}`,
          },
        },
      },

      '& .ql-active': {
        color: `${theme.palette.primary.main}!important`,

        '& .ql-stroke': {
          stroke: `${theme.palette.primary.main}!important`,
        },
      },

      '& .ql-divider': {
        margin: '0 5px',
        borderRadius: '2px',
        padding: '0',
        width: '1px',
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
}));

export default useStyles;
