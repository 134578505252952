import React from 'react';

import WithEventRole from '../../../user/WithEventRole';
import CreatedByColumn from './columnHeaders/CreatedByColumn';
import TypeColumn from './columnHeaders/TypeColumn';
import EmptyColumn from './columnHeaders/EmptyColumn';
import ProgressColumn from './columnHeaders/ProgressColumn';
import SongsColumn from './columnHeaders/SongsColumn';
import PeopleColumn from './columnHeaders/PeopleColumn';
import DefaultColumn from '../../../layouts/table/columnHeaders/DefaultColumn';
import SortableColumn from './columnHeaders/SortableColumn';
import StatusColumn from './columnHeaders/StatusColumn';
import HistoryStatusColumn from './columnHeaders/HistoryStatusColumn';
import DjsColumn from './columnHeaders/DjsColumn';

import CreatedByCell from './cells/CreatedByCell';
import TypeCell from './cells/TypeCell';
import DefaultCell from '../../../layouts/table/cells/DefaultCell';
import DateCell from './cells/DateCell';
import ProgressCell from './cells/ProgressCell';
import StatusCell from './cells/StatusCell';
import DjsCell from './cells/DjsCell';
import PublicLabelCell from './cells/PublicLabelCell';

import { columnsConfig, cellsFields } from '../../../../services/events/definitions';

import { EventUserType } from '../../../../types/enums';

type TColumnConfig = {
  [key in EventsTableColumn]: React.FC<ColumnProps>;
};

const columns: Partial<TColumnConfig> = {
  users: PeopleColumn,
  songs: SongsColumn,
  planning: ProgressColumn,
  date: SortableColumn,
  dateTemplate: SortableColumn,
  lastModified: SortableColumn,
  status: StatusColumn,
  historyStatus: HistoryStatusColumn,
  djs: DjsColumn,
  type: TypeColumn,
  title: EmptyColumn,
  isPublic: EmptyColumn,
  createdBy: CreatedByColumn,
};

const cells = {
  users: DefaultCell,
  songs: DefaultCell,
  planning: ProgressCell,
  date: DateCell,
  dateTemplate: DateCell,
  historyDate: DateCell,
  lastModified: DateCell,
  status: StatusCell,
  title: StatusCell,
  historyStatus: StatusCell,
  djs: DjsCell,
  type: TypeCell,
  isPublic: PublicLabelCell,
  createdBy: CreatedByCell,
};

export const renderColumnHeader = (
  key: EventsTableColumn,
  user: Maybe<User>
): Nullable<JSX.Element> => {
  const props: ColumnProps = columnsConfig[key];
  const Component: React.FC<ColumnProps> = columns[key] || DefaultColumn;

  if (key === 'djs' && user?.djSettings?.type !== 'company') {
    return null;
  }

  return props ? (
    <Component {...props} key={props.columnKey} />
  ) : (
    <div className={key} key={key}>
      {key}
    </div>
  );
};

export const renderCell = (
  key: EventsTableColumn,
  event: EventListItem,
  user: Maybe<User>
): Nullable<JSX.Element> => {
  const Component = cells[key] || DefaultCell;
  const field = cellsFields[key];
  const roles = columnsConfig[key].rolesToShow || [
    EventUserType.dj,
    EventUserType.host,
    EventUserType.guest,
  ];

  if (user?.djSettings?.type !== 'company' && key === 'djs') {
    return null;
  }

  return (
    <WithEventRole roles={roles} role={event.role || EventUserType.dj}>
      <Component user={user} data={event} key={`${key}_${event._id}`} field={field} />
    </WithEventRole>
  );
};
