import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark }: ViboTheme) => ({
  colorfulSectionWrapper: {
    '& .section': {
      '&.headline .sectionTop': {
        '& .textPreview , & .viboInput, & .viboTextarea': {
          color: isDark ? 'var(--section-dark-color)' : 'var(--section-light-color)',
        },
      },

      '&:not(.selected):not(.headline)': {
        overflow: 'hidden',

        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: '2px',
        },
      },

      '&:not(.selected):not(.headline):not(.dontPlay)': {
        '&:before': {
          backgroundColor: isDark ? 'var(--section-dark-color)' : 'var(--section-light-color)',
        },
      },
    },
  },
}));

export default useStyles;
