import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CreatedByColumn: FC = () => {
  const { t } = useTranslation();

  return <div className="createdByColumn">{t('createdBy')}</div>;
};

export default CreatedByColumn;
