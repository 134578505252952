import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  djsGrid: {
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: 'repeat(4, minmax(202px,1fr))',

    '@media screen and (min-width: 1441px)': {
      gridTemplateColumns: 'repeat(6, minmax(202px,1fr))',
    },
  },

  djCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '238px',
    overflow: 'hidden',
    cursor: 'pointer',
  },

  djPhoto: {
    '& *': {
      borderRadius: '0',
    },

    '& img': {
      objectFit: 'cover',
    },
  },

  djName: {
    height: '36px',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.5px',
    color: theme.palette.text.secondary,
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& span': {
      ...theme.mixins.longText,
      textAlign: 'center',
      maxWidth: '202px',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '22.67px',
    },
  },
}));

export default useStyles;
