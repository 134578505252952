import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Action from '../Action';
import { TimelineSongsContext } from '../../TimelineSongsContext';

import { ReactComponent as MustPlayOutlinedIcon } from 'resources/img/svg/common/mustPlayOutlined.svg';

import { useToggleableUpdateFieldSong } from 'graphql/hooks/blocks';

import { ToggleableSongUpdateField } from 'types/enums';
import { ActionProps } from '../interfaces';

const MustPlay: FC<ActionProps> = ({ songIds, ...rest }) => {
  const { t } = useTranslation();

  const { songsOptions } = useContext(TimelineSongsContext);

  const { updateField } = useToggleableUpdateFieldSong({
    selectedIds: songIds,
    field: ToggleableSongUpdateField.isMustPlay,
    songs: songsOptions.songsList,
  });

  return (
    <Action
      onClick={() => updateField()}
      songIds={songIds}
      icon={MustPlayOutlinedIcon}
      text={t('mustPlay')}
      {...rest}
    />
  );
};

export default MustPlay;
