import React, { FC } from 'react';
import classNames from 'classnames';

import { InputLabelProps } from './interfaces';

import useStyles from './style';

const InputLabel: FC<InputLabelProps> = ({
  className,
  text,
  required,
  disabled,
  children,
  iconPrefix = null,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        'inputLabel',
        classes.inputLabel,
        { [classes.disabled]: disabled },
        className
      )}
    >
      <span className="text">
        {iconPrefix}
        {text}
        {required ? <span className={classes.req}> *</span> : null}
      </span>
      {children}
    </div>
  );
};

export default InputLabel;
