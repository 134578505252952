import { useQueryParam } from 'use-query-params';

export const useNewQuestion = () => {
  const [newQuestionIdx, setNewQuestionIdx] = useQueryParam<string | undefined>('newQuestionIdx');

  const handleAddingNew = (id: string) => !!id && setNewQuestionIdx(id);

  const clearAddingNew = () => setNewQuestionIdx(undefined);

  return {
    newQuestionIdx,
    handleAddingNew,
    clearAddingNew,
  };
};
