import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  selectSectionMenu: {
    '& .rc-tooltip-inner': {
      padding: 0,
    },
  },
}));

export default useStyles;
