import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  containerContent: {
    width: '100%',
    maxWidth: '880px',

    '@media screen and (min-width: 1441px)': {
      maxWidth: '1252px',
    },
  },
}));

export default useStyles;
