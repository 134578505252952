import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import { StopPropagationWrapperProps } from './interfaces';

const StopPropagationWrapper: FC<StopPropagationWrapperProps> = ({ children, className }) => {
  const handlePreventEvent = useCallback(e => e.stopPropagation(), []);

  return (
    <span
      className={classNames('stopPropagationWrapper', className)}
      onKeyPress={handlePreventEvent}
      onClick={handlePreventEvent}
    >
      {children}
    </span>
  );
};

export default StopPropagationWrapper;
