import React, { FC } from 'react';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateGearIcon } from '../../../resources/img/svg/emptyStateGear.svg';

const GearResult: FC = ({ children }) => (
  <EmptyState icon={EmptyStateGearIcon} size="lg">
    {children}
  </EmptyState>
);

export default GearResult;
