import React from 'react';
import {
  AutoSizer,
  InfiniteLoader,
  List,
  ListRowRenderer,
  WindowScroller,
} from 'react-virtualized';
import classNames from 'classnames';

import { usePrapareData } from 'graphql/hooks/virtualizeList';

import { VirtualizedListProps } from './interfaces';

import useStyles from './style';

const VirtualizedList = <T extends {}>({
  totalRowsCount,
  className,
  scrollId,
  loadMore,
  data,
  rowHeights,
  gaps,
  overscanRowCount,
  rowRenderer,
}: VirtualizedListProps<T>) => {
  const classes = useStyles();

  const { rowHeight, scrollElement, preparedContent, rowCount, isRowLoaded } = usePrapareData({
    rowRenderer,
    data,
    scrollId,
    rowHeights,
    gaps,
  });

  const getRow: ListRowRenderer = ({ index, style, key }) => (
    <div key={key} style={style} className="virtualized-row">
      {preparedContent[index]}
    </div>
  );

  return !!scrollElement ? (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={() => loadMore?.()}
      rowCount={totalRowsCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller scrollElement={scrollElement}>
          {({ height = 0, isScrolling, scrollTop }) => {
            return (
              <AutoSizer
                disableHeight
                className={classNames('virtualizedList', classes.virtualizedList, className)}
              >
                {({ width }) => {
                  return (
                    <List
                      ref={registerChild}
                      height={height}
                      width={width}
                      onRowsRendered={onRowsRendered}
                      isScrolling={isScrolling}
                      rowCount={rowCount}
                      rowHeight={rowHeight}
                      scrollTop={scrollTop}
                      rowRenderer={getRow}
                      overscanRowCount={overscanRowCount}
                    />
                  );
                }}
              </AutoSizer>
            );
          }}
        </WindowScroller>
      )}
    </InfiniteLoader>
  ) : (
    <></>
  );
};

export default VirtualizedList;
