export const createMusicKitScript = (): HTMLScriptElement => {
  const script = document.createElement('script');

  script.src = 'https://js-cdn.music.apple.com/musickit/v1/musickit.js';
  script.async = true;

  return script;
};

export const removeMusicKitToken = (): void => {
  const musicTokenKey: Maybe<string> = Object.keys(localStorage).find(key =>
    key.startsWith('music.')
  );

  if (musicTokenKey) {
    const musicToken = localStorage.getItem(musicTokenKey);

    localStorage.removeItem(`${musicToken}.r`);
    localStorage.removeItem(`${musicToken}.s`);
    localStorage.removeItem(musicTokenKey);
  }
};
