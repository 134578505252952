import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  editor: {
    width: '100%',
    borderRadius: '4px',

    '& .ql-editor': {
      minHeight: '100px',
      padding: '12px',
    },

    '& .ql-toolbar': {
      borderRadius: '0 0 4px 4px',
    },
  },
}));

export default useStyles;
