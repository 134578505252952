import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  img: {
    '& svg': {
      color: theme.palette.text.primary,
      width: '50%',
      height: '50%',
    },
  },
}));

export default useStyles;
