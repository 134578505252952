import React, { FC } from 'react';
import classNames from 'classnames';

import Tooltip from 'vibo-ui/Tooltip';

import { getButtonIcon } from 'services/common/iconButton';

import { IconButtonProps } from './interfaces';

import useStyles from './style';

const IconButton: FC<IconButtonProps & TooltipProps> = ({
  onClick,
  type,
  className,
  disabled,
  title,
  tooltipAlign,
  placement = 'bottom',
  ...rest
}) => {
  const classes = useStyles();
  const ButtonIcon = getButtonIcon(type);

  return (
    <Tooltip
      placement={placement}
      overlay={title}
      className={classes.iconButtonTooltip}
      type="primary"
      align={tooltipAlign}
      fillOverlay
    >
      <span
        onClick={!disabled && onClick ? onClick : () => {}}
        className={classNames('iconButton', classes.iconButton, className, {
          [classes.disabledIcon]: disabled,
        })}
        {...rest}
      >
        <ButtonIcon />
      </span>
    </Tooltip>
  );
};

export default IconButton;
