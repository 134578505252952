import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Header from 'components/common/Header';
import Container from 'components/common/Container';

import { useMe } from 'graphql/hooks/user';
import { TEMPLATES_PAGE_MENU_ITEMS } from 'services/constants';
import { getSelectedTemplatesMenuKeys } from 'services/common/routesHelper';

import { TemplatesType } from 'types/enums';

const TemplatesPageHeader: FC = ({ children }) => {
  const { t } = useTranslation();

  const { push } = useHistory();

  const { isChildDj } = useMe();

  return (
    <Header>
      <Container>
        <Menu
          getSelectedSidebarMenuKeys={getSelectedTemplatesMenuKeys}
          styleModificator="siderTop"
          mode="horizontal"
        >
          {TEMPLATES_PAGE_MENU_ITEMS.filter(
            ({ type }) => !(type === TemplatesType.company && !isChildDj)
          ).map(({ name, route }) => (
            <Menu.Item onClick={() => push(`/templates/${route}`)} key={route}>
              {t(name)}
            </Menu.Item>
          ))}
          <Menu.Item onClick={() => push(`/favorite-sections`)} key="/favorite-sections">
            {t('favoriteSections_plural')}
          </Menu.Item>
        </Menu>
        {children}
      </Container>
    </Header>
  );
};

export default TemplatesPageHeader;
