import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  creatorImage: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',

    '& .imageDefaultPlaceholder': {
      width: '100%',
      height: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      width: '32px',
      height: '32px',
    },
  },

  creatorImageTooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
