import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  formLayout: {
    height: 'inherit',
    width: '100%',
  },

  formContainer: {
    position: 'relative',
    width: '392px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[7],
    borderRadius: '4px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '34px 36px 21px',

    '& .goBack': {
      position: 'absolute',
      padding: '0',

      '& svg': {
        width: '20px',
        height: '20px',

        '@media screen and (min-width: 1441px)': {
          width: '24px',
          height: '24px',
        },
      },
    },

    '& form': {
      width: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      width: '492px',
      padding: '48px',
    },
  },

  loginFormInner: {
    width: '96.6px',
    height: '37px',

    '@media screen and (min-width: 1441px)': {
      width: '125.32px',
      height: '48px',
    },
  },

  formLogo: {
    display: 'block',
    margin: '0 auto 41px',
    color: theme.palette.primary.main,

    '@media screen and (min-width: 1441px)': {
      margin: '0 auto 64px',
    },
  },

  linkButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    height: '36px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    fontSize: '20px',
    padding: '8px 0',

    '& > span': {
      width: '100%',
    },

    '&:hover': {
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      padding: '6px 0',
    },
  },

  coloredText: {
    fontSize: '17px',
    lineHeight: '24.09px',
    color: theme.palette.primary.main,
    margin: '0 0 5px 0',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 16px 0',
      fontSize: '20px',
      lineHeight: '28.34px',
    },
  },
}));

export default useStyles;
