import React, { FC } from 'react';
import classNames from 'classnames';

import Image from '../../image/Image';

import { HeadingWithImageProps } from './interfaces';

import useStyles from './style';

const HeadingWithImage: FC<HeadingWithImageProps> = ({ title, photo, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames('titlePhotoPreview', classes.titlePhotoPreview, className)}>
      <h3 className={classNames('title', classes.title)}>{title}</h3>
      <Image
        className={classNames('previewPhoto', classes.photo)}
        src={`${photo?.url}`}
        token={`${photo?.token}`}
        alt={title}
      />
    </div>
  );
};

export default HeadingWithImage;
