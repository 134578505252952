import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songLinksWithMetadata: {
    margin: '0 auto',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
