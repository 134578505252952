import React from 'react';
import classNames from 'classnames';

import Image from '../../../image/Image';
import ImageDefaultPlaceholder from '../../../image/ImageDefaultPlaceholder';

import { ReactComponent as UserIcon } from '../../../../resources/img/svg/common/user.svg';

import { getFullName } from '../../../../services/users/helpers';

import { LazyImageControlProps } from '../../../image/Image/interfaces';

import useStyles from '../style';

interface CroppedUserImageProps extends LazyImageControlProps {
  user: Maybe<UserBase>;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const CroppedUserImage = ({ user, className, onClick, isLazy, ...rest }: CroppedUserImageProps) => {
  const classes = useStyles();
  const alt = user ? getFullName(user) : '';
  const cropped = user?.image?.cropped;

  return (
    <Image
      token={user?._id}
      src={cropped?.small || cropped?.medium || user?.imageUrl || ''}
      alt={alt}
      onClick={onClick}
      className={classNames(classes.img, className)}
      isLazy={isLazy}
      {...rest}
    >
      <ImageDefaultPlaceholder icon={<UserIcon />} />
    </Image>
  );
};

export default CroppedUserImage;
