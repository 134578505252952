import React, { FC } from 'react';
import classNames from 'classnames';

import { InputErrorsProps } from './interfaces';

import useStyles from './style';

const InputErrors: FC<InputErrorsProps> = ({ className, errors }) => {
  const classes = useStyles();

  return errors?.length ? (
    <div className={classNames('inputErrors', classes.inputErrors, className)}>
      {errors.map((error, idx) => (error ? <div key={idx}>{error}</div> : null))}
    </div>
  ) : null;
};

export default InputErrors;
