import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { EventsSortableContext } from '../../../EventsListContext';

import useStyles from '../../style';

const SortableColumn: FC<ColumnProps> = ({ columnKey, className, field }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { sort, setSort } = useContext(EventsSortableContext);

  const getDirection = (): string => {
    if (field === sort?.field) {
      return sort?.direction === 'desc' ? 'asc' : 'desc';
    }

    return 'desc';
  };

  return (
    <div
      className={classNames('hoverable-action', classes.whiteSpace, classes.sortable, className, {
        activeSort: sort?.field === field,
      })}
      onClick={() =>
        setSort({
          field,
          direction: getDirection(),
        })
      }
    >
      <span>{t(columnKey)}</span>
      {sort?.field === field ? (
        <Icon
          icon={
            IconmoonFont[sort?.direction === 'desc' ? 'caretDownFilled-16' : 'caretUpFilled-16']
          }
        />
      ) : null}
    </div>
  );
};

export default SortableColumn;
