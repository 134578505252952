import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import useStyles from './style';

const ModalFooter: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles();

  return <div className={classNames('modalFooter', classes.modalFooter)}>{children}</div>;
};

export default ModalFooter;
