import { EventContactStatus, EventUserType } from '../../../types/enums';

export const contactRoleOptions = [{ value: EventUserType.host }, { value: EventUserType.guest }];

export const contactRoleFilterOptions = [
  { value: '', text: 'all', name: 'all' },
  { value: EventUserType.host, text: 'hosts', name: EventUserType.host },
  { value: EventUserType.guest, text: 'guests', name: EventUserType.guest },
];

export const contactStatusFilterOptions = [
  { value: '', text: 'all', name: 'all' },
  { value: EventContactStatus.contact, text: 'contact', name: EventContactStatus.contact },
  { value: EventContactStatus.joined, text: 'joined', name: EventContactStatus.joined },
];
