import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  poveredBy: {
    borderTop: `1px solid ${theme.palette.background.paper}`,
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: colors.gray,
    padding: '6px 16px',

    '& .viboIcon svg': {
      marginLeft: '5px',
      width: '55px',
      height: '18px',
    },
  },

  suggestionsMenu: {
    backgroundColor: `${theme.palette.background.paper}!important`,

    '& .menuItem': {
      color: theme.palette.text.primary,
      padding: '6px 8px!important',

      '&:hover': {
        color: `${theme.palette.text.primary}!important`,
        backgroundColor: `${theme.palette.primary.lighty}!important`,
      },

      '& .menuItemContent': {
        ...theme.mixins.longText,
        display: 'block',
        maxWidth: '400px',
        fontWeight: 400,

        '@media screen and (min-width: 1441px)': {
          maxWidth: '600px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        padding: '10px 8px!important',
      },
    },

    '& .suggestion': {
      padding: '8px',
      textAlign: 'left',
      cursor: 'pointer',

      '&.suggestion-item--active, &:hover': {
        backgroundColor: 'red',
      },
    },
  },
}));

export default useStyles;
