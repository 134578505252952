import RadioComponent from './Radio';
import Group from './Group';
import { CompoundedComponent } from './interfaces';

const Radio = RadioComponent as CompoundedComponent;
Radio.Group = Group;

export * from './interfaces';
export { Group };
export default Radio;
