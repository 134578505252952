import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import FormInput from '../FormInput';

import { InputProps } from 'vibo-ui/Input';

const EmailInput: FC<InputProps> = ({ value, name, type, ...props }) => {
  const { t } = useTranslation();

  return (
    <FormInput
      name="email"
      type="email"
      placeholder={t('email')}
      iconPrefix={<Icon icon={IconmoonFont['email-16']} />}
      autoFocus
      {...props}
    />
  );
};

export default EmailInput;
