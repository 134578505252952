import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoSongsIcon } from '../../../resources/img/svg/emptyStateNoSongs.svg';

const NoIdeasSearchResults: FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyState size="lg" icon={EmptyStateNoSongsIcon}>
      {t('noResults')}
    </EmptyState>
  );
};

export default NoIdeasSearchResults;
