import React, { CSSProperties, FC, useMemo } from 'react';
import classNames from 'classnames';

import { ColorfulSectionWrapperProps } from './interfaces';

import useStyles from './style';

const ColorfulSectionWrapper: FC<ColorfulSectionWrapperProps> = ({ children, headlineColors }) => {
  const light = useMemo(
    () =>
      headlineColors?.customLightModeHeadlineColor || headlineColors?.defaultLightModeHeadlineColor,
    [headlineColors?.customLightModeHeadlineColor, headlineColors?.defaultLightModeHeadlineColor]
  );
  const dark = useMemo(
    () =>
      headlineColors?.customDarkModeHeadlineColor || headlineColors?.defaultDarkModeHeadlineColor,
    [headlineColors?.customDarkModeHeadlineColor, headlineColors?.defaultDarkModeHeadlineColor]
  );

  const classes = useStyles();

  return (
    <div
      className={classNames('colorfulSectionWrapper', classes.colorfulSectionWrapper)}
      style={
        {
          '--section-light-color': light,
          '--section-dark-color': dark,
        } as CSSProperties
      }
    >
      {children}
    </div>
  );
};

export default ColorfulSectionWrapper;
