import React, { FC, PropsWithChildren, useRef } from 'react';
import { useClickAway } from 'react-use';

import { ClickAwayListenerProps } from './interfaces';

const ClickAwayListener: FC<PropsWithChildren<ClickAwayListenerProps>> = ({
  onClickAway,
  children,
}) => {
  const divRef = useRef<Nullable<HTMLDivElement>>(null);

  useClickAway(divRef, () => {
    onClickAway?.();
  });

  return (
    <div className="clickAwayListener" ref={divRef}>
      {children}
    </div>
  );
};

export default ClickAwayListener;
