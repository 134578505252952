import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  goBack: {
    padding: '4px 7px',

    '& *': {
      pointerEvents: 'all!important',
    },
  },
}));

export default useStyles;
