import React, { FC, createContext, memo, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import ReactGA from 'react-ga4';

import { useMe } from 'graphql/hooks/user';
import { getGaPageTitle } from './constants';

import { GA4LoginSource, TGA4Context } from './interfaces';
import {
  gaInit,
  gaSendLoginData,
  gaSendPageView,
  gaSetUserId,
  gtmInit,
} from 'services/googleAnalytics';

export const GA4Context = createContext<TGA4Context>(undefined!);

export const GA4ContextProvider: FC = memo(({ children }) => {
  const [gaLoginSource, setGaLoginSource] = useState<Nullable<GA4LoginSource>>(null);

  const location = useLocation();

  const { user } = useMe();

  const isGaEnable = !!process.env.REACT_APP_GA_ID;

  const initGaLogin = useCallback((source: GA4LoginSource) => {
    setGaLoginSource(source);
  }, []);

  const handlePageView = async () => {
    const getTitle = Promise.resolve(getGaPageTitle(location.pathname)?.toUpperCase());

    getTitle.then(customPage => {
      if (isGaEnable) {
        gaSendPageView({ pageName: customPage, pagePath: location.pathname });
      }
    });
  };

  useEffect(() => {
    handlePageView();
  }, [location.pathname]);

  useEffect(() => {
    if (!!gaLoginSource && !!user) {
      gaSendLoginData({ user, source: gaLoginSource });

      setGaLoginSource(null);
    }
  }, [gaLoginSource, user]);

  useEffect(() => {
    const gaId = process.env.REACT_APP_GA_ID;
    const gtmId = process.env.REACT_APP_GTM_ID;

    if (!!gaId && !ReactGA.isInitialized) {
      gaInit(gaId);

      if (!!user) {
        gaSetUserId(user._id);
      }
    }

    if (!!gtmId) {
      gtmInit(gtmId);
    }
  }, [ReactGA.isInitialized, user?._id]);

  return <GA4Context.Provider value={{ initGaLogin }}>{children}</GA4Context.Provider>;
});
