import React, { FC } from 'react';

import Brick from '../Brick';

import useStyles from './style';

const PlayerSongPlaceholder: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.playerSongPlaceholder}>
      <div className={classes.info}>
        <Brick className={classes.name} />
        <Brick className={classes.name} />
      </div>
      <Brick className={classes.playBtn} />
      <span className={classes.timer}>00:00 / 00:00</span>
      <Brick className={classes.wave} />
      <Brick className={classes.volume} />
      <Brick className={classes.volumeIcon} />
    </div>
  );
};

export default PlayerSongPlaceholder;
