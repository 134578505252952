import React, { FC, useContext, useEffect } from 'react';
import classNames from 'classnames';

import Wizard from 'vibo-ui/Wizard';
import SignIn from '../../auth/SignIn';
import { WizardContext } from 'vibo-ui/Wizard/WizardStepContext';

import { DeepLinkWizardProps, DeepLinkWizardSteps } from './interfaces';

import useStyles from './style';

const DeepLinkWizard: FC<DeepLinkWizardProps> = ({ className, children }) => {
  const classes = useStyles();

  const { wizardRefProps } = useContext(WizardContext);

  const localstorageSearch = localStorage.getItem('appleLoginSearch');

  useEffect(() => {
    if (!!localstorageSearch) {
      wizardRefProps?.goToNamedStep(DeepLinkWizardSteps.login);
    }
  }, [wizardRefProps?.currentStep]);

  return (
    <Wizard className={classNames('deepLinkWizard', classes.deepLinkWizard, className)}>
      {!!children ? (
        <Wizard.Step stepName={DeepLinkWizardSteps.init}>{children}</Wizard.Step>
      ) : (
        <></>
      )}
      <Wizard.Step stepName={DeepLinkWizardSteps.login}>
        <SignIn />
      </Wizard.Step>
    </Wizard>
  );
};

export default DeepLinkWizard;
