import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import SettingsItem from './SettingsItem';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { PRIVACY_POLICY_LINK } from 'services/constants';

import useStyles from './style';

const ConditionsPrivacyPolicy: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={IconmoonFont['termsConditions-24']} className={classes.generalIcon} />
        <Button href={PRIVACY_POLICY_LINK} target="_blank" displayType="link">
          {t('termsConditionsPrivacyPolicy')}
        </Button>
      </div>
    </SettingsItem>
  );
};

export default ConditionsPrivacyPolicy;
