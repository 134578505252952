import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  playerSongPlaceholder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  playBtn: {
    height: '24px',
    width: '24px',
    margin: '0 8px 0 0',
  },

  info: {
    height: '48px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '30ch',
    margin: '0 auto 0 0',
  },

  name: {
    width: '100%',
    height: '20px',
  },

  timer: {
    fontSize: '13px',
    color: theme.palette.text.secondary,

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
    },
  },

  wave: {
    width: '240px',
    height: '40px',
    margin: '0 20px',

    '@media screen and (min-width: 1441px)': {
      width: '370px',
      margin: '0 24px',
    },
  },

  volume: {
    width: '70px',
    height: '8px',

    '@media screen and (min-width: 1441px)': {
      width: '100px',
    },
  },

  volumeIcon: {
    width: '20px',
    height: '20px',
    margin: '0 16px 0 12px',

    '@media screen and (min-width: 1441px)': {
      margin: '0 20px 0 16px',
    },
  },
}));

export default useStyles;
