import React, { FC, useMemo, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';

import { PrintQuestionTypes } from '../interfaces';
import { PrintPdfQuestionSelectorsProps } from './interfaces';

const PrintPdfQuestionSelectors: FC<PrintPdfQuestionSelectorsProps> = ({ options, fieldName }) => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<CreateEventFileWebForm>();

  const selectedValue = useMemo(() => (values[fieldName] ? options[1] : options[0]), [
    values.timelinePrintQuestions,
    values.printUnansweredQuestions,
  ]);

  const handleChange = useCallback(option => {
    const key =
      option.key === PrintQuestionTypes.onlyAnswers ||
      option.key === PrintQuestionTypes.onlyAnswered
        ? 0
        : 1;

    setFieldValue(fieldName, !!key);
  }, []);

  return (
    <Menu onSelect={handleChange} defaultSelectedKeys={[selectedValue]} styleModificator="asTabs">
      {options.map(option => (
        <Menu.Item eventKey={option} key={option}>
          {t('print')}
          <div className="suffix">{t(option)}</div>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default PrintPdfQuestionSelectors;
