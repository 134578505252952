import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';

import Select, { SimpleOption } from 'vibo-ui/Select';

import { contactStatusFilterOptions } from '../constants';

import useStyles from '../style';

const ContactStatusFilter: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [status = '', setStatus] = useQueryParam<string | undefined>('status');

  const options = useMemo(
    () =>
      contactStatusFilterOptions.map(option => ({
        label: t(option.name),
        ...option,
        className: option.name,
      })),
    [contactStatusFilterOptions]
  );

  const handleChange = useCallback(option => {
    const { value } = option as SimpleOption;

    setStatus(value);
  }, []);

  return (
    <Select
      value={status}
      onChange={handleChange}
      options={options}
      classNames={{
        menu: () => classes.statusMenu,
      }}
      selectedValuePrefix={t('status')}
      styleModificator="asLink"
      withCustomValueContainer
      hideDropdownIndicator
      menuShouldBlockScroll
    />
  );
};

export default ContactStatusFilter;
