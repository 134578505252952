import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  typeCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .eventTypeIcon': {
      margin: '0 6px 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 8px 0 0',
      },
    },
  },
}));

export default useStyles;
