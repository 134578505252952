import React, { FC } from 'react';

import DjPreview from '../../../../../common/DjPreview';

const CreatedByCell: FC<CellProps<{ user: UserPreview }>> = ({ data }) =>
  data?.user ? (
    <div className="createdByCell">
      <DjPreview dj={data.user} />
    </div>
  ) : null;

export default CreatedByCell;
