import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import QlPreview from '../../../../common/QuillEditor/QlPreview';
import { EditingNotesContext, TimelineNotesContext } from '../TimelineNotesContext';

const NotesPreview: FC = () => {
  const { t } = useTranslation();

  const { section } = useContext(TimelineNotesContext);
  const { setIsEditing } = useContext(EditingNotesContext);

  const handleClick = useCallback(() => setIsEditing(true), []);

  return (
    <QlPreview
      text={section.note}
      onClick={handleClick}
      placeholder={`${t('addNotesAbout')} ${section.name}...`}
    />
  );
};

export default NotesPreview;
