import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  moreToolsSectionsMenu: {
    '& .rc-tooltip-inner': {
      padding: 0,
    },

    '& .rc-menu-item-disabled': {
      '& .viboIcon': {
        color: `${theme.palette.text.hint}!important`,
      },
    },
  },
}));

export default useStyles;
