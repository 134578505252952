import * as Yup from 'yup';

export const getErrorIndex = (path?: string): any =>
  path && parseInt(path.replace(/\w+\[(\d+)].+/, '$1'), 10);

export const urlValidator = Yup.string()
  .trim()
  .required('Provide review link')
  .url('Please enter a valid url address');

export const reviewsValidator = Yup.array().of(
  Yup.object().shape({
    url: urlValidator,
  })
);

export const MAX_LINKS_COUNT = 6;
